import React, { useEffect, useState } from "react";
import BaseCard from "../../components/ui/BaseCard";
import Settings from "../../components/Admin/TrendBackTest/Settings";
import Results from "../../components/Admin/TrendBackTest/Results";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
const TrendBacktest = () => {
  const [backTestingSettings, setBackTestingSettings] = useState({
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    price_rank: "9am Price",
    rank: "2",
    back_direction: "Back",
    // price_movement_percentage: "0.1",
    maximum_odds: "5",
    minimum_odds: "2",
    minimum_movement: "0.1",
  });

  const [processing, setProcessing] = useState(false);
  const [resultData, setResultData] = useState({
    results: [],
    stats: {},
  });

  const processResultData = async () => {
    setProcessing(true);
    console.log(backTestingSettings);
    const rankCol =
      backTestingSettings.price_rank === "9am Price"
        ? "price_early_rank"
        : "price_jump_rank";

    const positiveBack = backTestingSettings.back_direction === "Back";

    const { data, error } = await supabase
      .from("trend_backtest")
      .select("*")
      .gte("event_date", backTestingSettings.start_date)
      .lte("event_date", backTestingSettings.end_date)
      .eq(rankCol, parseInt(backTestingSettings.rank))
      .gte("bab", parseFloat(backTestingSettings.minimum_odds))
      .lte("bab", parseFloat(backTestingSettings.maximum_odds))
      .order("market_start_time", { ascending: false });
    if (error) {
      console.log(error);
    }

    if (data) {
      console.log("data", data.length);
      console.log(data);
      const result = data.map((row) => {
        return {
          ...row,
          bet: calculateBetSide(
            row,
            positiveBack,
            parseFloat(backTestingSettings.minimum_movement)
          ),
        };
      });

      const resultData = result.map((row) => {
        return {
          ...row,
          profit: calculateResult(row),
          bet_result: calculateBetResult(row),
        };
      });

      const stats = calculateStats(resultData);
      setResultData({
        results: resultData,
        stats: stats,
      });
    }
    setProcessing(false);
  };

  const calculateBetResult = (row) => {
    if (row.bet === "No Bet") {
      return "No Bet";
    }
    if (row.bet === "Back") {
      if (row.result === "WINNER") {
        return "Win";
      } else {
        return "Loss";
      }
    }
    if (row.bet === "Lay") {
      if (row.result === "WINNER") {
        return "Bang";
      } else {
        return "Win";
      }
    }
  };

  const calculateBetSide = (row, positiveBack, movementPercentage) => {
    const move = Math.abs(row.price_11am - row.price_jump);

    // if (move <= movementPercentage) {
    //   return "No Bet";
    // }
    if (row.price_11am > row.price_jump) {
      return positiveBack ? "Back" : "Lay";
    } else {
      return positiveBack ? "Lay" : "Back";
    }
  };

  const calculateResult = (row) => {
    if (row.bet === "No Bet") {
      return 0;
    }
    if (row.bet === "Back") {
      const price = row.bab;
      if (row.result === "WINNER") {
        return price - 1;
      } else {
        return -1;
      }
    }
    if (row.bet === "Lay") {
      const price = row.bal;
      if (row.result === "WINNER") {
        return -price + 1;
      } else {
        return 1;
      }
    }
  };

  const calculateStats = (resultData) => {
    const totalBets = resultData.filter((row) => row.bet !== "No Bet").length;
    const profit = resultData.reduce((acc, row) => acc + row.profit, 0);
    // Profit where the side is back
    const profitB = resultData.reduce(
      (acc, row) => (row.bet === "Back" ? acc + row.profit : acc),
      0
    );

    // Profit where the side is lay
    const profitL = resultData.reduce(
      (acc, row) => (row.bet === "Lay" ? acc + row.profit : acc),
      0
    );
    const winRate =
      resultData.filter((row) => row.profit > 0).length / totalBets;
    const profitOnTurnover = profit / totalBets;

    return {
      totalBets,
      profit,
      profitB,
      profitL,
      winRate,
      profitOnTurnover,
    };
  };

  // useEffect(() => {
  //   if (processing) {
  //     processResultData();
  //   }
  // }, [processing]);

  return (
    <div className="space-y-6">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-primary-text text-3xl font-cairoli">
            Trend Selection Results
          </h2>
        </div>
      </div>
      <BaseCard>
        <Settings
          settings={backTestingSettings}
          setSettings={setBackTestingSettings}
          setProcessing={setProcessing}
          processing={processing}
          processData={processResultData}
        />
      </BaseCard>
      <BaseCard>
        <Results resultData={resultData} processing={processing} />
      </BaseCard>
    </div>
  );
};

export default TrendBacktest;
