import React from "react";
import { useEffect, useState } from "react";
// import { TBB4AdminSettingsPanel } from "../../components/Admin/IntermediatePackage/TBB4AdminSettingsPanel";
// import { UpcomingRaces } from "../../components/Admin/IntermediatePackage/UpcomingRaces";
// import { UpcomingVenues } from "../../components/Admin/IntermediatePackage/UpcomingVenues";
export default function TBB4AdminDashboard() {
  const [currentPage, setCurrentPage] = useState("races");

  return (
    <div className="h-full bg-lbw-primary py-6">
      {/* <div className="flex flex-wrap">
        <div className="w-full mb-12 xl:mb-0 pb-4 space-y-4">
          <TBB4AdminSettingsPanel />
        </div>
        <div className="w-full mb-12 xl:mb-0 space-y-4">
          <div className="flex flex-wrap justify-center">
            <div className="w-1/2 pr-2">
              <button
                onClick={() => setCurrentPage("races")}
                className="bg-lbw-secondary active:bg-lbw-secondary-lighter text-primary-text text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Upcoming Races
              </button>
            </div>
            <div className="w-1/2 pl-2">
              <button
                onClick={() => setCurrentPage("results")}
                className="bg-lbw-secondary active:bg-lbw-secondary-lighter text-primary-text text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Upcoming Venues
              </button>
            </div>
          </div>
          <div className={currentPage === "races" ? "block" : "hidden"}>
            <UpcomingRaces />
          </div>
          <div className={currentPage === "results" ? "block" : "hidden"}>
            <UpcomingVenues />
          </div>
        </div>
      </div> */}
    </div>
  );
}
