import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "../../ui/table";
import moment from "moment";
import { supabase } from "../../../utils/supabaseClient";

const SelectionsTable = ({ data }) => {
  useEffect(() => {}, [data]);
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Race</TableHead>
          <TableHead>Selection</TableHead>

          <TableHead>Model Winner?</TableHead>
          <TableHead>Win Prob. (Rel Prob)</TableHead>
          <TableHead>Rank</TableHead>
          <TableHead>Side</TableHead>
          <TableHead>Units</TableHead>
          <TableHead>Odds</TableHead>
          <TableHead>Profit</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((race) => (
          <TableRow key={race.id}>
            <TableCell className="font-medium">
              {race.event_name.split(" (")[0]} {race.market_name.split(" ")[0]}
            </TableCell>
            <TableCell>
              {race.runner_number}. {race.runner_name}
            </TableCell>
            <TableCell>{race.ml_winner}</TableCell>
            <TableCell className="text-right">
              {(race.prob_winner * 100).toFixed(2)} - (
              {(race.prob_winner * race.bab * 100).toFixed(2)})
            </TableCell>
            <TableCell>{race.rank}</TableCell>
            <TableCell>{race.side}</TableCell>
            <TableCell>{race.units}</TableCell>
            <TableCell>${race.bab.toFixed(2)}</TableCell>
            <TableCell>{race.profit ? race.profit : ""}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SelectionsTable;
