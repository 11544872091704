import React from "react";
import axios from "axios";
import { supabase, log } from "../../../utils/supabaseClient";
import { HorseIcon, TickIcon, HarnessIcon, GreyhoundIcon } from "../../ui/Icon";

export const StarterPackagePanel = () => {
  const [botStatus, setBotStatus] = React.useState(false);
  const [settingsChanged, setSettingsChanged] = React.useState(false);

  const [botSettings, setBotSettings] = React.useState({
    rank: 0,
    percent: 0,
    max_lay: 1,
    min_odds: 0.0,
    max_odds: 0.0,
    time_before_jump: 0,
    active: false,
    take_profit: 0,
    stop_loss: 0,
    take_profit_plus: 0,
    stop_loss_plus: 0,
    min_runners: 0,
    horse: false,
    harness: false,
    greyhound: false,
    aus: true,
    nz: false,
  });
  const [settingsSavedSuccess, setSettingsSavedSuccess] = React.useState(null);

  const getMasterSettings = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .select("*")
      .eq("id", 2);
    // console.log(data);
    setBotSettings(data[0]);
  };

  const saveSettingsHandler = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .upsert(botSettings)
      .eq("id", 2)
      .select("*");
    console.log(data, error);
    getMasterSettings();
    if (data) {
      log("", 200, "Member Package Settings Updated", { botSettings });
      setSettingsChanged(false);
      setSettingsSavedSuccess(true);
      setTimeout(() => {
        setSettingsSavedSuccess(null);
      }, 5000);
    }
  };

  const botStatusHandler = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .update({ active: !botSettings.active })
      .eq("id", 2)
      .select("*");
    getMasterSettings();
    if (data) {
      let on = botSettings.active ? "Off" : "On";
      log("", 200, `Member Package ${on}`, { botSettings });
    }
  };

  React.useEffect(() => {
    getMasterSettings();
  }, []);

  return (
    <div className="px-4 py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center gap-y-4 pb-4">
        <h2 className="block uppercase w-full text-center text-primary-text text-2xl font-bold">
          Trend Selection Settings
        </h2>
        <button
          onClick={botStatusHandler}
          className={
            botSettings.active === true
              ? "bg-red-600 active:bg-red-600 " +
                "text-sm font-bold uppercase text-primary-text px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-1/2 ease-linear transition-all duration-150"
              : "bg-green-600 active:bg-green-600" +
                "text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          }
        >
          {botSettings.active ? "Stop Bot" : "Start Bot"}
        </button>
      </div>
      <div className="flex flex-row max-sm:flex-col-reverse w-full gap-x-2 py-4 gap-y-5">
        {/* System Settings */}
        <div className="max-sm:w-full md:w-1/2 lg:pr-12">
          <h2 className="block uppercase text-primary-text text-left text-lg pb-6 font-bold">
            System Settings
          </h2>

          <div className="flex flex-row pb-6 gap-x-2">
            <div className="w-1/2">
              <label className={labelStyle}>Min. Runners</label>
              <input
                id="take_profit"
                type="number"
                value={botSettings.min_runners}
                onChange={(e) => {
                  setBotSettings({
                    ...botSettings,
                    min_runners: parseInt(e.target.value),
                  });
                  setSettingsChanged(true);
                }}
                className={inputStyle}
              />
            </div>
            {/* <div className="w-1/3">
              <label className={labelStyle}>Max Lay</label>
              <input
                id="percent"
                type="number"
                value={botSettings.max_lay}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    max_lay: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div> */}
            <div className="w-1/2">
              <label className={labelStyle}>
                {window.screen.width > 768
                  ? "Time Before Jump(seconds)"
                  : "Before Jump"}
              </label>
              <input
                id="percent"
                type="number"
                value={botSettings.time_before_jump}
                onChange={(e) => {
                  setBotSettings({
                    ...botSettings,
                    time_before_jump: parseInt(e.target.value),
                  });
                  setSettingsChanged(true);
                }}
                className={inputStyle}
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 gap-x-2">
            <div className="w-1/2">
              <label className={labelStyle}>Min. Odds</label>
              <input
                id="take_profit"
                type="number"
                value={botSettings.min_odds}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    min_odds: parseFloat(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/2">
              <label className={labelStyle}>Max Odds</label>
              <input
                id="percent"
                type="number"
                value={botSettings.max_odds}
                onChange={(e) => {
                  setBotSettings({
                    ...botSettings,
                    max_odds: parseFloat(e.target.value),
                  });
                  setSettingsChanged(true);
                }}
                className={inputStyle}
              />
            </div>
            {/* <div className="w-1/3"></div> */}
          </div>

          {settingsSavedSuccess === true && (
            <div className="bg-green-400 py-2 w-full flex flex-row text-green-800 my-2 px-10 rounded-md">
              <span>Settings Saved Successfully</span>
            </div>
          )}
          <button onClick={() => saveSettingsHandler()} className={buttonStyle}>
            Save
          </button>
        </div>
        {/* Starter Package Settings */}
        <div className="max-sm:w-full md:w-1/2 lg:pl-12">
          <div className="flex flex-col">
            <h2 className="block uppercase text-primary-text text-left text-lg pb-6 font-bold">
              Members Settings
            </h2>
            <div className="flex flex-row pb-6 gap-x-2">
              <div className="w-1/2">
                <label className={labelStyle}>Take Profit (Units)</label>
                <input
                  id="take_profit"
                  type="number"
                  value={botSettings.take_profit}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      take_profit: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/2">
                <label className={labelStyle}>Stop Loss (Units)</label>
                <input
                  id="stop_loss"
                  type="number"
                  value={botSettings.stop_loss}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      stop_loss: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
            </div>
            <h2 className="block uppercase text-primary-text text-left text-lg pb-6 font-bold">
              Members Plus/Premium Settings
            </h2>
            <div className="flex flex-row pb-6 gap-x-2">
              <div className="w-1/2">
                <label className={labelStyle}>Take Profit (Units)</label>
                <input
                  id="take_profit_plus"
                  type="number"
                  value={botSettings.take_profit_plus}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      take_profit_plus: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/2">
                <label className={labelStyle}>Stop Loss (Units)</label>
                <input
                  id="stop_loss_plus"
                  type="number"
                  value={botSettings.stop_loss_plus}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      stop_loss_plus: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const labelStyle = "block uppercase text-primary-text text-xs font-bold mb-2";
const inputStyle =
  "border-2 border-gray-400 px-3 py-3 placeholder-gray-300 text-primary-text font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150";
const buttonStyle =
  "bg-lbw-accent text-primary-text active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";
