import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { supabase, supabaseKey, getUser } from "../utils/supabaseClient";
import logo from "../assets/lay-back-and-win-logo.svg";
import { useUser } from "../contexts/User.context";

export const RedirectBetfair = () => {
  const [queryParameters] = useSearchParams();
  const [complete, setComplete] = useState(false);

  const navigate = useNavigate();

  const { user } = useUser();

  const handleCode = async (userCode) => {
    console.log("Handle code started");
    const authServerUrl = `https://sb.laybackandwin.au/functions/v1/betfairAuthExchange?code=${userCode}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + supabaseKey,
      },
      url: authServerUrl,
      method: "post",
      crossdomain: true,
    };

    const response = await axios(config);

    const access_token = response.data.access_token;
    const refresh_token = response.data.refresh_token;
    const vendor_client_id =
      response.data.application_subscription.vendorClientId;

    const settings_id = localStorage.getItem("settingsId");

    const { data, error } = await supabase
      .from("user_settings")
      .update({
        betfair_access_token: access_token,
        betfair_refresh_token: refresh_token,
        betfair_vendor_client_id: vendor_client_id,
      })
      .eq("id", settings_id)
      .select("*");

    if (data) {
      // navigate('/tbb4/admin-dashboard');
      console.log(localStorage.getItem("settingsId"));
      localStorage.removeItem("settingsId");
      console.log(data);
      setComplete(true);
    } else if (error) {
      console.log(error);
    }
  };

  const backToDashboard = () => {
    navigate("/tbb4/dashboard/settings");
  };

  useEffect(() => {
    const userCode = queryParameters.get("code");

    if (userCode) {
      console.log(userCode);
      handleCode(userCode);
    }
  }, []);

  useEffect(() => {
    const userCode = queryParameters.get("code");

    if (userCode) {
      console.log(userCode);
      handleCode(userCode);
    }
  }, [user]);

  return (
    <div className=" h-[100vh] bg-lbw-secondary">
      <div className="mx-auto flex justify-center h-[100vh]">
        {complete === true ? (
          <div className="bg-lbw-secondary w-1/2 h-1/2 mt-[5%] rounded-lg text-center align-middle">
            <div className="w-full pt-12 mx-auto">
              <img src={logo} alt="logo" className="w-12 md:w-24 mx-auto" />
            </div>
            <div className="w-full pt-12">
              <p className="text-primary-text text-xl">
                Your Betfair account was successfully verified
              </p>
            </div>
            <div className="w-full pt-12">
              <button
                className="bg-lbw-accent w text-neutral-900 hover:bg-[#c55b43] text-sm font-bold uppercase 
                 px-2 py-3 lg:py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 lg:my-4 w-1/2 
                 ease-linear transition-all duration-150"
                type="button"
                onClick={backToDashboard}
              >
                Back to Settings
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
