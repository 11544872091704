export const darkTheme = {
  colors: {
    primary: "#ff0000",
    secondary: "#00ff00",
    tertiary: "#0000ff",
    background: "#000000",
    text: "#ffffff",
  },
};

export const lightTheme = {
  colors: {
    primary: "#ff0000",
    secondary: "#00ff00",
    tertiary: "#0000ff",
    background: "#ffffff",
    text: "#000000",
  },
};
