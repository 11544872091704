import React, { useEffect } from "react";

const Results = ({ resultData, processing }) => {
  useEffect(() => {}, [resultData]);
  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-row gap-x-6 justify-around">
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">Profit(Units)</div>
            <div className="stat-value">
              {parseInt(resultData.stats?.profit) || 0}
            </div>
          </div>
        </div>
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">B-Profit</div>
            <div className="stat-value">
              {parseInt(resultData.stats?.profitB) || 0}
            </div>
          </div>
        </div>
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">L-Profit</div>
            <div className="stat-value">
              {parseInt(resultData.stats?.profitL) || 0}
            </div>
          </div>
        </div>
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">Total Bets</div>
            <div className="stat-value">
              {parseInt(resultData.stats?.totalBets) || 0}
            </div>
          </div>
        </div>
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">Win Rate</div>
            <div className="stat-value">
              {parseFloat(resultData.stats?.winRate).toPrecision(2) || 0}
            </div>
          </div>
        </div>
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">Profit on Turnover</div>
            <div className="stat-value">
              {parseFloat(resultData.stats?.profitOnTurnover).toPrecision(2) ||
                0}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-x-6 justify-around">
        <div className="overflow-x-auto w-full">
          <table className="table font-bold text-lg">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Venue</th>
                <th>Horse</th>
                <th>Price 9am</th>
                <th>Price 2 Hours</th>
                <th>Price Jump</th>
                <th>BF $</th>
                <th>Side</th>
                <th>Race Result</th>
                <th>Result</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {resultData.results.map((row, index) => (
                <tr
                  key={index}
                  className={`${row.bet === "No Bet" && "text-slate-600"}`}
                >
                  <th>{index + 1}</th>
                  <td>{row.event_date}</td>
                  <td>
                    {row.venue} {row.market_name?.split(" ")[0]}
                  </td>
                  <td>
                    {row.runner_number}. {row.runner_name}
                  </td>
                  <td>{row.price_9am}</td>
                  <td>{row.price_11am}</td>
                  <td>{row.price_jump}</td>
                  <td>{row.bab}</td>
                  <td>{row.bet}</td>
                  <td>{row.result}</td>
                  <td>
                    <div
                      className={`badge badge-lg ${
                        row.bet_result === "Win"
                          ? "badge-success"
                          : row.bet_result === "Loss"
                          ? "badge-warning"
                          : row.bet_result === "Bang"
                          ? "badge-error"
                          : ""
                      } gap-2`}
                    >
                      {row.bet_result}
                    </div>
                  </td>
                  <td>{row.profit?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Results;
