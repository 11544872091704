import React from "react";

const SimulatorSettings = () => {
  const [botStatus, setBotStatus] = React.useState(false);

  const [botSettings, setBotSettings] = React.useState({
    stake_size: 0,
    rank: 0,
    percent: 0,
    max_lay: 1,
    // time_before_jump: 0,
    active: false,
    take_profit: 0,
    stop_loss: 0,
    min_runners: 0,
    horse: false,
    harness: false,
    greyhound: false,
  });
  const [settingsSavedSuccess, setSettingsSavedSuccess] = React.useState(null);
  return (
    <div className="px-4 py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center">
        <h2 className="block uppercase text-primary-text text-md font-bold">
          Back Testing Parameters
        </h2>
      </div>
      <div className="flex flex-row w-full gap-x-2 py-4 gap-y-5">
        {/* System Settings */}
        <div className="w-full ">
          <div className="flex flex-row pb-6 gap-x-12">
            <div className="w-1/4">
              <label className={labelStyle}>Rank</label>
              <input
                id="rank"
                type="number"
                value={botSettings.rank}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    rank: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/4">
              <label className={labelStyle}>Percent</label>
              <input
                id="percent"
                type="number"
                value={botSettings.percent}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    percent: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/4">
              <label className={labelStyle}>Min. Runners</label>
              <input
                id="take_profit"
                type="number"
                value={botSettings.min_runners}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    min_runners: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/4">
              <label className={labelStyle}>Max Lay</label>
              <input
                id="percent"
                type="number"
                value={botSettings.max_lay}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    max_lay: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 gap-x-12">
            <div className="w-1/4">
              <label className={labelStyle}>Daily Take Profit</label>
              <input
                id="rank"
                type="number"
                value={botSettings.rank}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    rank: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/4">
              <label className={labelStyle}>Daily Stop Loss</label>
              <input
                id="percent"
                type="number"
                value={botSettings.percent}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    percent: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/4">
              <label className={labelStyle}>Beginning Date</label>
              <input
                id="start_date"
                type="date"
                value={botSettings.start_date}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    start_date: e.target.value,
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/4">
              <label className={labelStyle}>End Date</label>
              <input
                id="percent"
                type="date"
                value={botSettings.max_lay}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    end_date: e.target.value,
                  })
                }
                className={inputStyle}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between pb-6 gap-x-2">
            <div className="w1/3">
              <div className="relative w-full">
                <div className="flex gap-x-4 pt-2 justify-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={botSettings.horse}
                      onClick={() =>
                        setBotSettings({
                          ...botSettings,
                          horse: !botSettings.horse,
                        })
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                      Thoroughbreds
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="w1/3">
              <div className="relative w-full">
                <div className="flex gap-x-4 pt-2 justify-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={botSettings.harness}
                      onClick={() =>
                        setBotSettings({
                          ...botSettings,
                          harness: !botSettings.harness,
                        })
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                      Harness
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="w1/3">
              <div className="relative w-full">
                <div className="flex gap-x-4 pt-2 justify-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={botSettings.greyhound}
                      onClick={() =>
                        setBotSettings({
                          ...botSettings,
                          greyhound: !botSettings.greyhound,
                        })
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                      Greyhounds
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <button
            // onClick={botStatusHandler}
            className="bg-green-600 active:bg-green-600 text-sm font-bold uppercase text-primary-text px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
          >
            Run Back Test
          </button>
        </div>
      </div>
    </div>
  );
};

const labelStyle = "block uppercase text-primary-text text-xs font-bold mb-2";
const inputStyle =
  "border-2 border-gray-400 px-3 py-3 placeholder-gray-300 text-primary-text font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150";
const buttonStyle =
  "bg-lbw-accent text-primary-text active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";

export default SimulatorSettings;
