import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supabaseClient";
import Premium from "../../components/Admin/BotSettings/Premium";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import BaseCard from "../../components/ui/BaseCard";
import MembersPlus from "../../components/Admin/BotSettings/MembersPlus";
import Members from "../../components/Admin/BotSettings/Members";
import { UpcomingRaces } from "../../components/Admin/BotSettings/UpcomingRaces";

const MasterBotSettings = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSettings = async () => {
    const { data, error } = await supabase.from("bot_settings").select("*");
    if (error) console.log("error", error);
    if (data) setSettings(data);
    setLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className="flex flex-wrap">
      <div className="w-full h-full mb-12 mt-4 xl:mt-14 my-auto xl:mb-0 px-4">
        <BaseCard>
          <div className="flex flex-col justify-center w-full"></div>
          <Tabs defaultValue="members" className="flex flex-col">
            <TabsList className="w-2/3 self-center">
              <TabsTrigger className="w-1/3" value="members">
                Members
              </TabsTrigger>
              <TabsTrigger className="w-1/3" value="members plus">
                Members Plus
              </TabsTrigger>
              <TabsTrigger className="w-1/3" value="premium">
                Premium
              </TabsTrigger>
            </TabsList>
            {loading === true ? (
              <div>Loading...</div>
            ) : (
              <>
                <TabsContent className="w-full" value="members">
                  <Members settings={settings.filter((s) => s.id === 2)[0]} />
                </TabsContent>
                <TabsContent className="w-full" value="members plus">
                  <MembersPlus
                    settings={settings.filter((s) => s.id === 3)[0]}
                  />
                </TabsContent>
                <TabsContent className="w-full" value="premium">
                  <Premium settings={settings.filter((s) => s.id === 1)[0]} />
                </TabsContent>
              </>
            )}
          </Tabs>
        </BaseCard>
        <UpcomingRaces />
      </div>
    </div>
  );
};

export default MasterBotSettings;
