import React from "react";

const SimulationNotes = () => {
  return (
    <div className="px-4 py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-start">
        <h2 className="block uppercase text-primary-text text-xl font-bold pb-6">
          Back Testing Notes
        </h2>
      </div>
      <div className="flex flex-wrap space-y-5">
        <div className="w-full">
          <p className="text-primary-text font-bold text-lg">
            Races to be tested: <span className="font-semi-bold">1,845</span>
          </p>
        </div>
        <div className="w-full">
          <p className="text-primary-text font-bold text-lg">
            Max Lay Match Rate: <span className="font-semi-bold">64.84%</span>
          </p>
        </div>
        <div className="w-full">
          <p className="text-primary-text font-bold text-lg">
            Expected Variance: <span className="font-semi-bold">7.13%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SimulationNotes;
