import React, { useEffect, useState } from "react";
import { Progress } from "../ui/progress";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import { InfoIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

const RacingStrength = () => {
  const [strength, setStrength] = useState(0);
  const [color, setColor] = useState("bg-lbw-accent");

  const calculateRacingStrength = async () => {
    const { data, error } = await supabase
      .from("betfair_market_data")
      .select("*")
      .eq("race_type", "Thoroughbred")
      .eq("country", "AUS")
      .eq("event_date", moment().format("YYYY-MM-DD"));

    if (data.length > 0) {
      let strength = 0;
      data.forEach((item) => {
        if (item.runner_count > 6 && item.runner_count < 10) {
          strength += 0.5;
        }
        if (item.runner_count > 10) {
          strength += 1;
        }
      });
      console.log(strength);
      console.log(data.length);
      const si = Math.floor((Math.floor(strength) / data.length) * 100);
      console.log(si);
      const col =
        si > 75 ? "bg-green-700" : si >= 50 ? "bg-orange-500" : "bg-red-700";
      setColor(col);
      setStrength(si);
    }
    // setStrength(0);
  };

  useEffect(() => {
    calculateRacingStrength();
  }, []);
  return (
    <TooltipProvider>
      <div className="flex flex-col w-full gap-y-2 text-left">
        <span className="text-primary-text font-cairoli flex flex-row items-center gap-x-1">
          Racing Quality
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon size={14} />
            </TooltipTrigger>
            <TooltipContent>
              <p>
                Racing Stregth of the day calculated based on <br /> fields,
                quality of horses and opportunity
              </p>
            </TooltipContent>
          </Tooltip>
        </span>
        <Progress value={strength} color={color} className="text-green-100" />
      </div>
    </TooltipProvider>
  );
};

export default RacingStrength;
