import React from "react";
import axios from "axios";
import moment from "moment";
import { supabase } from "../../../utils/supabaseClient";
import { useUser } from "../../../contexts/User.context";
import { HorseIcon, GreyhoundIcon, HarnessIcon } from "../../ui/Icon";
export const UpcomingRaces = ({ raceType }) => {
  const [marketData, setMarketData] = React.useState([]);
  const [customSelections, setCustomSelections] = React.useState([]);
  const [autoRefresh, setAutoRefresh] = React.useState(false);
  const [excludeDisabled, setExcludeDisabled] = React.useState(false);

  const { user } = useUser();

  const excludeRaceHandler = async (
    id,
    user_id,
    market_id,
    excluded,
    au,
    nz
  ) => {
    console.log(id, excluded);
    setExcludeDisabled(true);
    const ex = excluded;
    const today = moment().format("YYYYMMDD");
    const tableId = parseInt(`${user_id}${market_id.split(".")[1]}${today}`);
    const { data, error } = await supabase
      .from("cl_race_and_venue_exclusion")
      .upsert(
        {
          id: tableId,
          user_id: user.ud_id,
          market_id: market_id,
          excluded: ex,
        },
        { onConflict: "id" }
      );

    console.log(error, data);

    await racingData();
    setExcludeDisabled(false);
  };

  const getSelections = async (id) => {
    const { data, error } = await supabase
      .from("betfair_selection_data")
      .select("*")
      .eq("market_id", id)
      .order("runner_name", { ascending: true });

    data.forEach((item) => {
      item.horse_number = parseInt(item.runner_name.split(".")[0]);
    });
    data.sort((a, b) => a.horse_number - b.horse_number);
    return data;
  };

  const racingData = async () => {
    const s = await supabase
      .from("captains_lounge_full")
      .select("*")
      .eq("auth_id", user.auth_id);

    const now = moment().utc().format();
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gt("market_start_time", now)
      .eq("race_type", raceType)
      .order("market_start_time", { ascending: true });
    const data = rawData.data;
    // console.log(data);

    const raceOverrides = await supabase
      .from("cl_race_and_venue_exclusion")
      .select("*")
      .eq("user_id", user.ud_id);
    // console.log(raceOverrides.data);
    data.forEach(async (item) => {
      item.options = false;
      item.excluded =
        raceOverrides.data.filter((ro) => ro.market_id === item.market_id)
          .length > 0
          ? raceOverrides.data.filter(
              (ro) => ro.market_id === item.market_id
            )[0].excluded
          : false;
      item.runners = await getSelections(item.market_id);
    });
    // console.log(data);
    setMarketData(data);
  };

  const getCustomSelections = () => {
    console.log("selection user", user);
    const data = supabase
      .from("cl_selection_override")
      .select("*")
      .eq("user_id", user.ud_id)
      .then((data) => {
        if (data.data) {
          setCustomSelections(data.data);
        }
      });
  };

  const addRemoveCustomSelection = async (
    selectionId,
    marketId,
    side,
    selection_name,
    units,
    user_id
  ) => {
    const exists = customSelections.filter(
      (cs) => cs.selection_id === selectionId
    );

    if (exists.length > 0) {
      const { data, error } = await supabase
        .from("cl_selection_override")
        .delete()
        .eq("selection_id", selectionId)
        .eq("user_id", user.ud_id);

      getCustomSelections();
    } else {
      const { data, error } = await supabase
        .from("cl_selection_override")
        .insert([
          {
            selection_id: selectionId,
            market_id: marketId,
            side: side,
            selection_name: selection_name,
            units: units ? units : 1,
            user_id: user_id,
          },
        ]);
      getCustomSelections();
    }
  };

  // Executed upon load
  React.useEffect(() => {
    racingData();
    getCustomSelections();
  }, []);

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (autoRefresh) {
        racingData();
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [autoRefresh]);

  //   // Checks to place a bet every 10 seconds
  //   React.useEffect(() => {
  //     const interval = setInterval(() => {
  //       const botStatus = localStorage.getItem("botStatus");
  //       if (botStatus === "true") {
  //         betHandler();
  //       }
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }, []);

  return (
    <div className="py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center">
        <div className="w-full px-4 overflow-x-auto">
          <button
            className={`max-sm:hidden ${
              autoRefresh ? "bg-green-900" : "bg-red-900"
            } text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? "Auto Refresh On" : "Auto Refresh Off"}
          </button>
          {marketData?.length > 0 ? (
            <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden hidden md:block px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Start Time
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Venue
                  </th>
                  <th className="px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Race
                  </th>
                  {/* <th className="hidden md:block px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Time still Jump
                  </th> */}
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Type
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Runner Count
                  </th>
                  <th className="px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Include
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"></th>
                </tr>
              </thead>
              <tbody>
                {marketData.map((item, index) => (
                  <>
                    <tr
                      className={`border-b-2  font-bold ${
                        item.excluded
                          ? "bg-red-200 text-lbw-secondary"
                          : "text-primary-text"
                      }`}
                      key={item.id}
                    >
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {moment(item.market_start_time).format("h:mma")}
                      </td>
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.venue}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {window.screen.width > 768 ? (
                          ""
                        ) : item.race_type === "Thoroughbred" ? (
                          <HorseIcon className="text-xl font-thin mr-2" />
                        ) : item.race_type === "Greyhound" ? (
                          <GreyhoundIcon className="text-xl font-thin mr-2" />
                        ) : (
                          <HarnessIcon className="text-xl font-thin mr-2" />
                        )}
                        {window.screen.width > 768
                          ? `${item.market_name}`
                          : `${item.venue} ${item.market_name.split(" ")[0]}`}
                      </td>
                      {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {moment(item.market_start_time).fromNow()}
                      </td> */}
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.race_type === "Thoroughbred" ? (
                          <HorseIcon className="text-xl font-thin" />
                        ) : item.race_type === "Greyhound" ? (
                          <GreyhoundIcon className="text-xl font-thin" />
                        ) : (
                          <HarnessIcon className="text-xl font-thin" />
                        )}
                      </td>
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.runner_count}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                        <button
                          disabled={excludeDisabled}
                          onClick={() =>
                            excludeRaceHandler(
                              item.id,
                              user.ud_id,
                              item.market_id,
                              !item.excluded
                            )
                          }
                          id={item.id}
                          aria-details={!item.excluded ? "Exclude" : "Include"}
                          className={`${
                            !item.excluded ? "bg-green-900" : "bg-red-900"
                          } text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 max-sm:py-3 max-sm:mb-3  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                        >
                          {!item.excluded ? "Exclude" : "Include"}
                        </button>
                        {window.screen.width < 768 ? (
                          !item.excluded ? (
                            <button
                              onClick={() => {
                                if (item.options) {
                                  item.options = false;
                                } else {
                                  item.options = true;
                                }
                                setMarketData([...marketData]);
                              }}
                              id={item.id}
                              aria-details={
                                item.tbb4_excluded ? "Include" : "Exclude"
                              }
                              className={`${
                                customSelections.filter(
                                  (cs) => cs.market_id === item.market_id
                                ).length > 0
                                  ? "bg-orange-400"
                                  : "bg-blue-400"
                              }  text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 max-sm:py-3 max-sm:mb-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                            >
                              Runners
                            </button>
                          ) : (
                            <></>
                          )
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {/* Button on click sets option to true or false */}
                        {!item.excluded ? (
                          <button
                            onClick={() => {
                              if (item.options) {
                                item.options = false;
                              } else {
                                item.options = true;
                              }
                              setMarketData([...marketData]);
                            }}
                            id={item.id}
                            aria-details={
                              !item.excluded ? "Include" : "Exclude"
                            }
                            className={`${
                              customSelections.filter(
                                (cs) => cs.market_id === item.market_id
                              ).length > 0
                                ? "bg-orange-400"
                                : "bg-blue-400"
                            }  text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          >
                            Runners
                          </button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                    {item.options ? (
                      <tr className="">
                        <td colSpan={7}>
                          <div className="max-sm:overflow-x-auto">
                            <table className="mt-2 items-center w-fit lg:w-full bg-lbw-secondary border-collapse">
                              <thead>
                                <tr className="border-b-2  font-bold">
                                  <th className="max-sm:px-2 px-6 bg-lbw-secondary text-primary-text align-middle py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Selection
                                  </th>
                                  <th className="max-sm:px-2 px-6 bg-lbw-secondary text-primary-text align-middle  py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"></th>
                                  <th className="max-sm:px-2 px-6 bg-lbw-secondary text-primary-text align-middle  py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Back
                                  </th>
                                  <th className="max-sm:px-2 px-6 bg-lbw-secondary text-primary-text align-middle  py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Lay
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.runners.map((selection) => (
                                  <tr
                                    id={selection.selection_id}
                                    className="text-primary-text border-b-2  font-bold"
                                  >
                                    <td className="border-t-0 flex max-sm:flex-col max-sm:px-1 lg:px-6 align-middle border-l-0 border-r-0 text-md whitespace-nowrap p-4">
                                      <img
                                        className="w-8 h-8 mr-2"
                                        src={`http://content-cache.betfair.com/feeds_images/Horses/SilkColours/${selection.colors_file_name}`}
                                        alt={selection.runner_name}
                                      />
                                      {selection.runner_name}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
                                      <>
                                        <label className="block uppercase text-right text-primary-text text-xs font-bold mb-2">
                                          Units
                                        </label>
                                        <input
                                          id={`unit-${selection.selection_id}`}
                                          placeholder={
                                            customSelections.filter(
                                              (cs) =>
                                                cs.selection_id ===
                                                selection.selection_id
                                            ).length > 0
                                              ? customSelections.filter(
                                                  (cs) =>
                                                    cs.selection_id ===
                                                    selection.selection_id
                                                )[0].units
                                              : window.screen.width < 768
                                              ? ""
                                              : 1
                                          }
                                          type="number"
                                          step="0.1"
                                          className="md:border-2 max-sm:border-0 text-center float-right w-20  max-sm:w-10 border-gray-400 px-3 py-3 placeholder-gray-300 text-primary-text font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        />
                                      </>
                                    </td>
                                    <td className="border-t-0 max-sm:px-1 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      <button
                                        onClick={() => {
                                          console.log(
                                            "BACK",
                                            selection.selection_id,
                                            selection.runner_name,
                                            item.market_id,
                                            document.getElementById(
                                              `unit-${selection.selection_id}`
                                            ).value,
                                            user.ud_id
                                          );
                                          addRemoveCustomSelection(
                                            selection.selection_id,
                                            item.market_id,
                                            "BACK",
                                            selection.runner_name,
                                            document.getElementById(
                                              `unit-${selection.selection_id}`
                                            ).value,
                                            user.ud_id
                                          );
                                        }}
                                        className={`${
                                          customSelections.filter(
                                            (cs) =>
                                              cs.selection_id ===
                                              selection.selection_id
                                          ).length > 0 &&
                                          customSelections.filter(
                                            (cs) =>
                                              cs.selection_id ===
                                              selection.selection_id
                                          )[0].side === "BACK"
                                            ? "bg-green-400"
                                            : ""
                                        }  hover:bg-green-400 border border-green-400 text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                                      >
                                        Back
                                      </button>
                                    </td>
                                    <td className="border-t-0 max-sm:px-1 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      <button
                                        onClick={() => {
                                          console.log(
                                            "LAY",
                                            selection.selection_id,
                                            selection.runner_name,
                                            item.market_id,
                                            document.getElementById(
                                              `unit-${selection.selection_id}`
                                            ).value,
                                            user.ud_id
                                          );
                                          addRemoveCustomSelection(
                                            selection.selection_id,
                                            item.market_id,
                                            "LAY",
                                            selection.runner_name,
                                            document.getElementById(
                                              `unit-${selection.selection_id}`
                                            ).value,
                                            user.ud_id
                                          );
                                        }}
                                        className={`${
                                          customSelections.filter(
                                            (cs) =>
                                              cs.selection_id ===
                                              selection.selection_id
                                          ).length > 0 &&
                                          customSelections.filter(
                                            (cs) =>
                                              cs.selection_id ===
                                              selection.selection_id
                                          )[0].side === "LAY"
                                            ? "bg-red-400"
                                            : ""
                                        } hover:bg-red-400 border border-red-400 text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                                      >
                                        Lay
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
