import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabaseClient";
const BotDetailsCard = ({ bot }) => {
  const [botInfo, setBotInfo] = useState({
    bot_active: false,
    bot_name: "",
    betfair_strategy_reference: "",
    stake_size: 0,
    max_lay: 0,
    take_profit: 0,
    stop_loss: 0,
    rank: 0,
    percent: 0,
    min_runners: 0,
    horse: false,
    harness: false,
    greyhound: false,
    in_play_enabled: true,
  });

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from("development_bots")
      .delete()
      .eq("bot_id", bot.bot_id);
    if (error) {
      console.log(error);
      alert("Issue Deleting. Speak to Zach");
    } else {
      console.log(data);
      window.location.reload();
    }
  };

  const handleSave = async () => {
    const { data, error } = await supabase
      .from("development_bots")
      .update(botInfo)
      .eq("bot_id", bot.bot_id);
    if (error) {
      console.log(error);
      alert("Issue Saving. Speak to Zach");
    } else {
      console.log(data);
      window.location.reload();
    }
  };

  useEffect(() => {
    setBotInfo(bot);
  }, [bot]);

  return (
    <div className="bg-lbw-secondary py-6 px-2 rounded-xl">
      <div className="flex flex-wrap justify-evenly text-center lg:mb-4">
        <div className="w-full lg:w-3/12 my-auto px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="relative my inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={botInfo.bot_active}
                onClick={() =>
                  setBotInfo({
                    ...botInfo,
                    bot_active: botInfo.bot_active ? false : true,
                  })
                }
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                Bot On/Off
              </span>
            </label>
          </div>
        </div>

        <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-primary-text text-xs font-bold mb-2"
              data-tooltip-target="stake-size"
            >
              Bot Name
            </label>
            <input
              type="text"
              value={botInfo.bot_name}
              onChange={(e) =>
                setBotInfo({ ...botInfo, bot_name: e.target.value })
              }
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-lg font-bold shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Strategy Reference
            </label>
            <input
              type="text"
              value={botInfo.betfair_strategy_reference}
              onChange={(e) => {
                setBotInfo({
                  ...botInfo,
                  betfair_strategy_reference: e.target.value,
                });
              }}
              readOnly
              disabled
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-lg shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly text-center lg:mb-4">
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Stake Size
            </label>
            <input
              value={botInfo.stake_size}
              onChange={(e) => {
                setBotInfo({ ...botInfo, stake_size: e.target.value });
              }}
              type="number"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Max Lay
            </label>
            <input
              value={botInfo.max_lay}
              onChange={(e) => {
                setBotInfo({ ...botInfo, max_lay: e.target.value });
              }}
              type="number"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Take Profit
            </label>
            <input
              value={botInfo.take_profit}
              onChange={(e) => {
                setBotInfo({ ...botInfo, take_profit: e.target.value });
              }}
              type="number"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Stop Loss
            </label>
            <input
              value={botInfo.stop_loss}
              onChange={(e) => {
                setBotInfo({ ...botInfo, stop_loss: e.target.value });
              }}
              type="text"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly text-center lg:mb-4">
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-primary-text text-xs font-bold mb-2"
              data-tooltip-target="stake-size"
            >
              Rank
            </label>
            <input
              value={botInfo.rank}
              onChange={(e) => {
                setBotInfo({ ...botInfo, rank: e.target.value });
              }}
              type="number"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Percent
            </label>
            <input
              value={botInfo.percent}
              onChange={(e) => {
                setBotInfo({ ...botInfo, percent: e.target.value });
              }}
              type="number"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Min. Runners
            </label>
            <input
              value={botInfo.min_runners}
              onChange={(e) => {
                setBotInfo({ ...botInfo, min_runners: e.target.value });
              }}
              type="number"
              className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
            />
          </div>
        </div>

        <div className="w-full lg:w-2/12 px-4 my-auto lg:mb-4">
          {/* <div className="relative w-full mb-3">
            <label className="relative my inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={botInfo.in_play_enabled}
                onClick={() =>
                  setBotInfo({
                    ...botInfo,
                    in_play_enabled: botInfo.in_play_enabled ? false : true,
                  })
                }
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                Enable In Play
              </span>
            </label>
          </div> */}
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly text-center lg:mb-4">
        <div className="w-full lg:w-3/12 my-auto px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="relative my inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={botInfo.horse}
                onClick={() =>
                  setBotInfo({
                    ...botInfo,
                    horse: botInfo.horse ? false : true,
                  })
                }
                className="sr-only peer"
                readOnly
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                Thoroughbreds
              </span>
            </label>
          </div>
        </div>
        <div className="w-full lg:w-3/12 my-auto px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="relative my inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={botInfo.harness}
                onClick={() =>
                  setBotInfo({
                    ...botInfo,
                    harness: botInfo.harness ? false : true,
                  })
                }
                className="sr-only peer"
                readOnly
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                Harness
              </span>
            </label>
          </div>
        </div>
        <div className="w-full lg:w-3/12 my-auto px-4 mb-12 lg:mb-4">
          <div className="relative w-full mb-3">
            <label className="relative my inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={botInfo.greyhound}
                onClick={() =>
                  setBotInfo({
                    ...botInfo,
                    greyhound: botInfo.greyhound ? false : true,
                  })
                }
                className="sr-only peer"
                readOnly
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              <span className="ml-3 uppercase text-primary-text text-xs font-bold">
                Greyhounds
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap-reverse">
        <div className="w-full lg:w-1/2 lg:py-0 py-2 text-center">
          <button
            className="bg-red-700 py-4 px-2 rounded-lg w-full lg:w-1/2 text-lg font-bold text-primary-text"
            onClick={handleDelete}
          >
            Delete Bot
          </button>
        </div>
        <div className="w-full lg:w-1/2 lg:py-0 py-2 text-center">
          <button
            className="bg-lbw-accent py-4 px-2 rounded-lg w-full lg:w-1/2 text-lg font-bold text-primary-text"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default BotDetailsCard;
