import React, { useEffect } from "react";
import BaseCard from "../../ui/BaseCard";
import { supabase } from "../../../utils/supabaseClient";
import playIcon from "../../../assets/icons/play.svg";
import pauseIcon from "../../../assets/icons/pause.svg";
import { useUser } from "../../../contexts/User.context";
import { GreenNotification } from "../../ui/Notifications";
const QuickSettings = () => {
  const [modal, setModal] = React.useState(false);

  const { user, updateUserSettings } = useUser();

  useEffect(() => {}, []);

  return (
    <div className="flex flex-col lg:flex-row gap-y-4  justify-self-end min-w-0 break-words w-full shadow-lg rounded-lg bg-lbw-secondary border-0 max-sm:py-4 py-2 px-4">
      <div className="w-full lg:w-6/12 mt-auto">
        {modal ? (
          <GreenNotification message="Your settings have been updated successfully" />
        ) : (
          <></>
        )}
        <button
          onClick={() =>
            updateUserSettings({
              tbb4_bot: user.tbb4_bot === true ? false : true,
            })
          }
          className={`${
            user.tbb4_bot
              ? "bg-[#fc4a4a] hover:bg-[#da4747]"
              : "bg-[#23a03e] hover:bg-[#5fe248]"
          } text-neutral-900 text-sm font-bold uppercase 
      px-6 py-3 rounded shadow hover:shadow-lg outline-none w-1/2 focus:outline-none mr-1 mb-1 
      ease-linear transition-all duration-150`}
          type="button"
        >
          {/* <img
            src={user.tbb4_bot ? pauseIcon : playIcon}
            alt="botStatus"
            className="w-6 h-6 inline-block mr-2"
          /> */}
          {user.tbb4_bot ? "Pause Automation" : "Start Automation"}
        </button>
      </div>
      <div className=" flex-col relative flex w-full lg:w-3/12 gap-x-2">
        {user.tbb4_package === "3" ? (
          <>
            <div className="mt-auto">
              <label className="absolute left-2 uppercase text-primary-text text-opacity-70 text-xs font-bold mb-2">
                Stake
              </label>
            </div>
            <input
              placeholder={user.tbb4_stake_size}
              onBlur={(e) => {
                setTimeout(() => {
                  setModal(true);
                  updateUserSettings({ tbb4_stake_size: e.target.value });
                }, 500);

                setTimeout(() => {
                  setModal(false);
                }, 5000);
              }}
              type="number"
              className="w-full lg:w-1/2 border-0 px-1 pb-3 pt-5 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-center ease-linear transition-all duration-150"
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="relative flex-col flex w-full xl:w-3/12 gap-x-2">
        <div className="mt-auto">
          <label className="absolute left-2 uppercase text-primary-text text-opacity-70 text-xs font-bold mb-2">
            Stop Loss
          </label>
        </div>
        <input
          placeholder={user.tbb4_stop_loss}
          onBlur={(e) => {
            setTimeout(() => {
              setModal(true);
              updateUserSettings({ tbb4_stop_loss: e.target.value });
            }, 500);
            setTimeout(() => {
              setModal(false);
            }, 5000);
          }}
          type="number"
          className="w-full lg:w-1/2 border-0 px-1 pb-3 pt-5 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-center ease-linear transition-all duration-150"
        />
      </div>
      <div className="relative flex-col flex w-full xl:w-3/12 gap-x-2">
        <div className="mt-auto">
          <label className="absolute left-2 uppercase text-primary-text text-opacity-70 text-xs font-bold mb-2">
            Take Profit
          </label>
        </div>
        <input
          placeholder={user.tbb4_take_profit}
          // onChange={(e) => setTargetProfit(e.target.value)}
          onBlur={(e) => {
            setTimeout(() => {
              setModal(true);
              updateUserSettings({ tbb4_take_profit: e.target.value });
            }, 500);
            setTimeout(() => {
              setModal(false);
            }, 5000);
          }}
          type="number"
          className="w-full lg:w-1/2 border-0 px-3 pb-3 pt-5 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-center ease-linear transition-all duration-150"
        />
      </div>
    </div>
  );
};

export default QuickSettings;
