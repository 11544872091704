import axios from "axios";

export async function refreshToken(refreshToken) {
  let url = `${process.env.REACT_APP_LBW_BACKEND}/refresh_token?refresh_token=${refreshToken}`;

  var config = {
    method: "get",
    url: url,
  };

  let data = await axios(config);
  return data.data.data;
}

export async function sessionTokenLocal() {
  let url = "https://identitysso-cert.betfair.com/api/certlogin";
  let payload = `username=${process.env.username}&password=${process.env.password}`;

  let headers = {
    "X-Application": process.env.app_key,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  var config = {
    method: "post",
    data: payload,
    headers: headers,
    url: url,
  };

  let data = await axios(config);
  return data;
}

export async function sessionToken() {
  let url = `https://api.laybackandwin.au/session_token`;

  var config = {
    method: "get",
    url: url,
  };

  let data = await axios(config);
  return data.data;
}

export async function betfairAccountRequest(
  accessToken,
  apiEndpoint,
  betfairData
) {
  const url = "https://sb.laybackandwin.au/functions/v1/betfairAccountRequest";

  const headers = {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml6c3BjdG55eW1kZ3Jlb3htb2VnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM2MTg1OTAsImV4cCI6MjAwOTE5NDU5MH0.QPi1YUJc8WolXyoMGJWuzDa9x2jGAd794mElafHWecM",
    "Content-Type": "application/json",
  };
  const data = {
    accessToken: accessToken,
    requestMethod: apiEndpoint,
    betfairData: betfairData,
  };

  var config = {
    method: "post",
    url: url,
    headers: headers,
    data: data,
    crossdomain: true,
  };

  let d = await axios(config);

  return d;
}

export async function accountRequest(apiEndpoint, accessToken) {
  let url = `https://api.laybackandwin.au/${apiEndpoint}`;

  let payload = {
    access_token: accessToken,
  };

  var config = {
    method: "get",
    url: url,
    data: payload,
    crossdomain: true,
  };

  let d = await axios(config);

  return d;
}

export async function lbwApiRequest(
  apiMethod,
  apiEndpoint,
  data,
  devTest = false
) {
  let url = null;
  devTest === false
    ? (url = `https://api.laybackandwin.au/${apiEndpoint}`)
    : (url = `http://127.0.0.1:8000/${apiEndpoint}`);

  var config = {
    method: apiMethod,
    url: url,
    data: data,
  };

  let d = await axios(config);

  return d.data;
}
