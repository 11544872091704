import React from "react";

const initialState = {
  maxLay: 0,
};

// initialize context
const SimulatorContext = React.createContext(initialState);
SimulatorContext.displayName = "SimulatorContext";

// useDesigner to use context
export const useSimulator = () => {
  const context = React.useContext(SimulatorContext);

  return context;
};

export default SimulatorContext;
