import React, { useState } from "react";
import { TBB4UserSettings } from "../../components/Members/Settings/TBB4UserSettings";
import Profile from "../../components/Members/Settings/Profile";
import BotSettings from "../../components/Members/Settings/BotSettings";
import { SidebarNav } from "../../components/ui/sidebar-nav";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import { useNavigate } from "react-router-dom";
import ResetPassword from "../../components/Members/Settings/ResetPassword";
import BetfairAccount from "../../components/Members/Settings/BetfairAccount";
export const UserSettings = () => {
  const [page, setPage] = useState("Profile");

  const sidebarNavItems = [
    {
      title: "Profile",
      href: "/customer/profile",
    },
    {
      title: "Manage Subscription",
      href: "/customer/profile",
    },
    {
      title: "Change Password",
      href: "/customer/orders",
    },
    {
      title: "Automation Settings",
      href: "/customer/orders",
    },
    {
      title: "Betfair Account",
      href: "/customer/orders",
    },
  ];
  return (
    <div className="flex flex-row w-full px-10 max-sm:px-2">
      <div className="flex flex-col w-full space-y-8 pt-12 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 md:w-1/6">
          <SidebarNav items={sidebarNavItems} setPage={setPage} page={page} />
        </aside>
        <div className="flex-1 md:w-2/3">
          {page === "Profile" ? <Profile /> : <></>}
          {page === "Manage Subscription" ? <ManageSubscription /> : <></>}
          {page === "Automation Settings" ? <BotSettings /> : <></>}
          {page === "Change Password" ? <ResetPassword /> : <></>}
          {page === "Betfair Account" ? <BetfairAccount /> : <></>}
        </div>
      </div>
    </div>
  );
};

const ManageSubscription = () => {
  const navigate = useNavigate();
  return (
    <div className="mr-6 text-primary-text">
      <div className="flex flex-col">
        <h3 className="font-bold">Manage Subscription</h3>
        <span className="text-sm text-jd-grey">
          Manage your subscription and payment details{" "}
        </span>
      </div>
      <Separator className="my-4 w-1/2" />
      <a
        target="_blank"
        href="https://pay.laybackandwin.com.au/p/login/4gw4gIe3k2ucbM47ss"
      >
        <Button variant="default" className="w-1/4">
          Manage Subscription
        </Button>
      </a>
    </div>
  );
};
