import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "../../ui/table";
import {
  SortAscIcon,
  SortDescIcon,
  SortAsc,
  Check,
  CheckIcon,
  CheckCircle,
} from "lucide-react";

import moment from "moment";
import { Separator } from "../../ui/separator";
import { Button } from "../../ui/button";
import InfoToolTip from "../../ui/InfoToolTip";

const AllRaces = ({ data, venues }) => {
  const [selectedRace, setSelectedRace] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortHeader, setSortHeader] = useState("");
  const races = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const sortRaces = (header) => {
    const sortD = sortDirection === "asc" ? "desc" : "asc";
    setSortHeader(header);
    const sorted = selectedRace.sort((a, b) => {
      if (sortD === "asc") {
        return a[header] - b[header];
      } else {
        return b[header] - a[header];
      }
    });
    setSelectedRace(sorted);
    setSortDirection(sortD);
  };

  return (
    <div className="flex flex-col gap-y-10">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Venue</TableHead>
            {races.map((i) => (
              <TableHead className="text-center">R{i}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {venues.map((venue, index) => (
            <TableRow className="" key={index}>
              <TableCell className="font-bold">{venue}</TableCell>
              {races.map((i) => (
                <TableCell
                  onClick={() =>
                    setSelectedRace(
                      data
                        .filter(
                          (r) =>
                            r.meeting_track_name === venue && r.raceno === i
                        )
                        .sort((a, b) => a.pf_horse_number - b.pf_horse_number)
                    )
                  }
                  className={`text-center ${
                    moment(
                      data.filter(
                        (r) => r.meeting_track_name === venue && r.raceno === i
                      )[0]?.start_time
                    ) < moment()
                      ? "text-gray-500"
                      : ""
                  } cursor-pointer text-center`}
                >
                  <div
                    className={`flex flex-row justify-center rounded-md py-2 ${
                      data.filter(
                        (r) => r.meeting_track_name === venue && r.raceno === i
                      )[0]?.reliable && "bg-green-600 bg-opacity-40"
                    }`}
                  >
                    {data.filter(
                      (r) => r.meeting_track_name === venue && r.raceno === i
                    ).length > 0
                      ? moment(
                          data.filter(
                            (r) =>
                              r.meeting_track_name === venue && r.raceno === i
                          )[0]?.start_time
                        ).format("hh:mma")
                      : ""}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedRace && (
        <div className="flex flex-col gap-y-6">
          <Separator />
          <div className="flex flex-row justify-between">
            <h2 className="text-primary-text font-bold text-2xl">
              {selectedRace[0].meeting_track_name} R{selectedRace[0].raceno}{" "}
            </h2>
            <Button onClick={() => setSelectedRace(null)}>Close</Button>
          </div>

          <Table className="bg-lbw-secondary-lighter rounded-lg">
            <TableHeader>
              <TableRow>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => sortRaces("pf_horse_number")}
                >
                  <span className="flex flex-row gap-x-2 items-center">
                    Horse
                    {sortHeader === "pf_horse_number" &&
                    sortDirection === "asc" ? (
                      <SortAscIcon size={16} />
                    ) : (
                      <SortDescIcon size={16} />
                    )}
                  </span>
                </TableHead>
                <TableHead>Bet</TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => sortRaces("lbw_rank")}
                >
                  <span className="flex flex-row gap-x-2 items-center">
                    <InfoToolTip>
                      Overall score calculated based on the main ratings, a
                      confidence factor that the horse will win
                    </InfoToolTip>
                    LBW Score
                    {sortHeader === "lbw_rank" && sortDirection === "asc" ? (
                      <SortAscIcon size={16} />
                    ) : (
                      <SortDescIcon size={16} />
                    )}
                  </span>
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => sortRaces("neural_rank")}
                >
                  <span className="flex flex-row gap-x-2 items-center">
                    <InfoToolTip>
                      Overall rank that is directly related to the LBW Price
                    </InfoToolTip>
                    LBW Rank
                    {sortHeader === "neural_rank" && sortDirection === "asc" ? (
                      <SortAscIcon size={16} />
                    ) : (
                      <SortDescIcon size={16} />
                    )}
                  </span>
                </TableHead>

                <TableHead
                  className="cursor-pointer"
                  onClick={() => sortRaces("l600_rank")}
                >
                  <span className="flex flex-row gap-x-2 items-center">
                    <InfoToolTip>
                      Rating over the last 600m. Weight and Race Time Adjusted
                    </InfoToolTip>
                    L600 Rank
                    {sortHeader === "l600_rank" && sortDirection === "asc" ? (
                      <SortAscIcon size={16} />
                    ) : (
                      <SortDescIcon size={16} />
                    )}
                  </span>
                </TableHead>

                <TableHead
                  className="cursor-pointer"
                  onClick={() => sortRaces("neural_rank")}
                >
                  <span className="flex flex-row gap-x-2 items-center">
                    <InfoToolTip>
                      Weight class rank. A rating of a horse taking into account
                      the weight carried and class of race.
                    </InfoToolTip>
                    WCR
                    {sortHeader === "neural_rank" && sortDirection === "asc" ? (
                      <SortAscIcon size={16} />
                    ) : (
                      <SortDescIcon size={16} />
                    )}
                  </span>
                </TableHead>
                <TableHead>
                  <span className="flex flex-row gap-x-2 items-center">
                    <InfoToolTip>
                      Time adjusted weight class rank. A rating of a horse
                      taking into account the weight carried and benchmarks for
                      that track and distance
                    </InfoToolTip>
                    TA.WCR
                  </span>
                </TableHead>
                <TableHead>Settle Position</TableHead>
                {/* <TableHead>Class Dif%</TableHead> */}
                <TableHead>
                  <span className="flex flex-row gap-x-2 items-center">
                    <InfoToolTip>
                      The LBW Assessed price that we think it should be priced
                      at
                    </InfoToolTip>
                    LBW Price
                  </span>
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => sortRaces("price_9am")}
                >
                  <span className="flex flex-row gap-x-2 items-center">
                    Odds
                    {sortHeader === "price_9am" && sortDirection === "asc" ? (
                      <SortAscIcon size={16} />
                    ) : (
                      <SortDescIcon size={16} />
                    )}
                  </span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedRace.map((sel, index) => (
                <TableRow key={index} className="text-center">
                  <TableCell className="flex flex-row items-center">
                    <img
                      className="w-8 h-8 mr-2 bg-transparent"
                      src={`https://content.betfair.com/feeds_images/Horses/SilkColours/${sel.colors_file_name}`}
                      alt={sel.runner_name}
                    />
                    {sel.pf_horse_number}. {sel.pf_horsename}{" "}
                    {sel.reliable === true && (
                      <CheckCircle className="ml-2" size={16} />
                    )}
                    {sel.lb_result && (
                      <span
                        className={`ml-1 ${
                          sel.lb_result === "WINNER"
                            ? "bg-green-600"
                            : sel.lb_result === "PLACE"
                            ? "bg-orange-400"
                            : sel.lb_result === "LOSER"
                            ? "bg-red-600"
                            : ""
                        } p-1 rounded-md text-xs font-bold`}
                      >
                        {sel.lb_result}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {sel.lbw_rank > 90 &&
                    sel.neural_rank === 1 &&
                    sel.price_latest > sel.neural_price ? (
                      <span className="bg-green-600 text-primary-text p-1 rounded-md">
                        Back
                      </span>
                    ) : sel.lbw_rank < 70 &&
                      sel.neural_rank !== 1 &&
                      sel.favourite === true &&
                      sel.price_latest < sel.neural_price ? (
                      <span className="bg-orange-600 text-primary-text p-1 rounded-md">
                        Lay
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>{sel.lbw_rank ? sel.lbw_rank : ""}</TableCell>
                  <TableCell
                    className={`bg-opacity-90 ${
                      sel.neural_rank === 1
                        ? "bg-green-300"
                        : sel.neural_rank === 2
                        ? "bg-gray-300"
                        : sel.neural_rank === 3
                        ? "bg-orange-300"
                        : ""
                    }`}
                  >
                    {sel.neural_rank ? sel.neural_rank : ""}
                  </TableCell>

                  <TableCell
                    className={`bg-opacity-90 ${
                      sel.l600_rank === 1
                        ? "bg-green-300"
                        : sel.l600_rank === 2
                        ? "bg-gray-300"
                        : sel.l600_rank === 3
                        ? "bg-orange-300"
                        : ""
                    }`}
                  >
                    {sel.l600_rank && sel.l600_rank < 25 ? sel.l600_rank : ""}
                  </TableCell>

                  <TableCell
                    className={`bg-opacity-90 ${
                      sel.weight_class_ranl === 1
                        ? "bg-green-300"
                        : sel.weight_class_ranl === 2
                        ? "bg-gray-300"
                        : sel.weight_class_ranl === 3
                        ? "bg-orange-300"
                        : ""
                    }`}
                  >
                    {sel.weight_class_ranl && sel.weight_class_ranl < 25
                      ? sel.weight_class_ranl
                      : ""}
                  </TableCell>
                  <TableCell
                    className={`bg-opacity-90 ${
                      sel.time_adjusted_weight_class_rank === 1
                        ? "bg-green-300"
                        : sel.time_adjusted_weight_class_rank === 2
                        ? "bg-gray-300"
                        : sel.time_adjusted_weight_class_rank === 3
                        ? "bg-orange-300"
                        : ""
                    }`}
                  >
                    {sel.time_adjusted_weight_class_rank
                      ? sel.time_adjusted_weight_class_rank
                      : ""}
                  </TableCell>
                  {/* <TableCell>
                    {sel.clsdiff ? sel.clsdiff.toFixed(2) : ""}
                  </TableCell> */}
                  <TableCell>
                    {sel.predicted_settle_position &&
                    sel.predicted_settle_position < 25
                      ? sel.predicted_settle_position
                      : ""}
                  </TableCell>
                  <TableCell>
                    {sel.neural_price ? `$${sel.neural_price.toFixed(2)}` : ""}
                  </TableCell>
                  <TableCell>
                    {sel.price_latest ? `$${sel.price_latest.toFixed(2)}` : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AllRaces;
