import React, { useEffect, useState } from "react";
import { CaptainsLoungeBotSettings } from "../../components/Members/CaptainsLounge/CaptainsLoungeBotSettings";
import { UpcomingRaces } from "../../components/Members/CaptainsLounge/UpcomingRaces";
import { UpcomingVenues } from "../../components/Members/CaptainsLounge/UpcomingVenues";
import { useUser } from "../../contexts/User.context";
import { supabase } from "../../utils/supabaseClient";
import { CaptainsLoungeProvider } from "../../contexts/CaptainsLounge.context";
import { useCaptainsLounge } from "../../contexts/CaptainsLounge.context";

const CaptainsLounge = () => {
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    localStorage.getItem("cl_tab")
      ? setCurrentPage(localStorage.getItem("cl_tab"))
      : setCurrentPage("Thoroughbred");
  }, []);

  return (
    <div className="h-full bg-lbw-primary py-6 px-10 max-sm:px-2">
      <div className="flex flex-wrap">
        {/* <div className="w-full mb-12 xl:mb-0 pb-4 space-y-4">
          <CaptainsLoungeBotSettings raceType="Thoroughbred" />
        </div> */}

        <div className="w-full mb-12 xl:mb-0 space-y-4">
          <div className="flex flex-row gap-x-3 justify-center">
            <div className="w-1/3">
              <button
                onClick={() => {
                  setCurrentPage("Thoroughbred");
                  localStorage.setItem("cl_tab", "Thoroughbred");
                }}
                className={
                  "bg-lbw-secondary active:bg-lbw-secondary-lighter text-primary-text text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" +
                  (currentPage === "Thoroughbred"
                    ? " bg-lbw-secondary-lighter border-2 border-white"
                    : "")
                }
              >
                {window.screen.width > 768 ? "Thoroughbreds" : "Horses"}
                {/* <br />
                {strategies?.length > 0 ? (
                  <span className="text-xs text-green-300">
                    {" "}
                    {strategies?.filter(
                      (strat) => strat.race_type === "Thoroughbred"
                    )[0].active === true
                      ? "On"
                      : "Off"}
                  </span>
                ) : (
                  <span className="text-xs text-green-300">Off</span>
                )} */}
              </button>
            </div>
            <div className="w-1/3">
              <button
                onClick={() => {
                  setCurrentPage("Greyhound");
                  localStorage.setItem("cl_tab", "Greyhound");
                }}
                className={
                  "bg-lbw-secondary active:bg-lbw-secondary-lighter text-primary-text text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" +
                  (currentPage === "Greyhound"
                    ? " bg-lbw-secondary-lighter border-2 border-white"
                    : "")
                }
              >
                {window.screen.width > 768 ? "Greyhounds" : "Dogs"}
                {/* <br />
                {strategies?.length > 0 ? (
                  <span className="text-xs text-green-300">
                    {" "}
                    {strategies?.filter(
                      (strat) => strat.race_type === "Greyhound"
                    )[0].active === true
                      ? "On"
                      : "Off"}
                  </span>
                ) : (
                  <span className="text-xs text-green-300">Off</span>
                )} */}
              </button>
            </div>
            <div className="w-1/3">
              <button
                onClick={() => {
                  setCurrentPage("Harness");
                  localStorage.setItem("cl_tab", "Harness");
                }}
                className={
                  "bg-lbw-secondary active:bg-lbw-secondary-lighter text-primary-text text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" +
                  (currentPage === "Harness"
                    ? " bg-lbw-secondary-lighter border-2 border-white"
                    : "")
                }
              >
                Harness
                <br />
                {/* {strategies?.length > 0 ? (
                  <span className="text-xs text-green-300">
                    {" "}
                    {strategies?.filter(
                      (strat) => strat.race_type === "Harness"
                    )[0].active === true
                      ? "On"
                      : "Off"}
                  </span>
                ) : (
                  <span className="text-xs text-green-300">Off</span>
                )} */}
              </button>
            </div>
          </div>
          <div className={currentPage === "Thoroughbred" ? "block" : "hidden"}>
            <CaptainsLoungeBotSettings raceType="Thoroughbred" />
            <UpcomingRaces raceType="Thoroughbred" />
          </div>
          <div className={currentPage === "Greyhound" ? "block" : "hidden"}>
            <CaptainsLoungeBotSettings raceType="Greyhound" />
            <UpcomingRaces raceType="Greyhound" />
          </div>
          <div className={currentPage === "Harness" ? "block" : "hidden"}>
            <CaptainsLoungeBotSettings raceType="Harness" />
            <UpcomingRaces raceType="Harness" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptainsLounge;
