import React from "react";

const Settings = ({
  settings,
  setSettings,
  setProcessing,
  processing,
  processData,
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row gap-x-6">
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">Price Rank</span>
          </div>
          <select
            className="select select-bordered"
            value={settings.rank}
            onChange={(e) => {
              setSettings({ ...settings, rank: e.target.value });
            }}
          >
            <option disabled selected>
              Pick one
            </option>
            <option>1</option>
            <option>2</option>

            <option>3</option>
          </select>
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">Rank Based on:</span>
          </div>
          <select
            className="select select-bordered"
            value={settings.price_rank}
            onChange={(e) => {
              setSettings({ ...settings, price_rank: e.target.value });
            }}
          >
            <option disabled selected>
              Pick one
            </option>
            <option value="9am Price">9am Price</option>
            <option value="Jump Price">Jump Price</option>
          </select>
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">
              If there is support for runner:
            </span>
          </div>
          <select
            className="select select-bordered"
            value={settings.back_direction}
            onChange={(e) => {
              setSettings({ ...settings, back_direction: e.target.value });
            }}
          >
            <option disabled selected>
              Pick one
            </option>
            <option value="Back">Back</option>
            <option value="Lay">Lay</option>
          </select>
        </label>
      </div>
      <div className="flex flex-row gap-x-6">
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">
              Min Odds (Ladbrokes)
            </span>
          </div>
          <input
            type="number"
            placeholder="2.20"
            className="input input-bordered w-full max-w-xs"
            value={settings.minimum_odds}
            onChange={(e) => {
              setSettings({ ...settings, minimum_odds: e.target.value });
            }}
          />
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">
              Max Odds (Ladbrokes)
            </span>
          </div>
          <input
            type="number"
            placeholder="6.50"
            className="input input-bordered w-full max-w-xs"
            value={settings.maximum_odds}
            onChange={(e) => {
              setSettings({ ...settings, maximum_odds: e.target.value });
            }}
          />
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">
              Minimum Movement to place bet
            </span>
          </div>
          <input
            type="number"
            placeholder="0.20"
            className="input input-bordered w-full max-w-xs"
            value={settings.minimum_movement}
            onChange={(e) => {
              setSettings({ ...settings, minimum_movement: e.target.value });
            }}
          />
        </label>
      </div>
      <div className="flex flex-row gap-x-6">
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">Start Date</span>
          </div>
          <input
            type="date"
            placeholder="2.20"
            value={settings.start_date}
            className="input input-bordered w-full max-w-xs"
            onChange={(e) => {
              setSettings({ ...settings, start_date: e.target.value });
            }}
          />
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text font-bold text-lg">End Date</span>
          </div>
          <input
            type="date"
            placeholder="6.50"
            className="input input-bordered w-full max-w-xs"
            value={settings.end_date}
            onChange={(e) => {
              setSettings({ ...settings, end_date: e.target.value });
            }}
          />
        </label>
      </div>
      <div className="flex flex-row gap-x-6">
        <button onClick={async () => await processData()} className="btn">
          {!processing && "Run Backtest"}
          {processing && (
            <>
              <span className="loading loading-spinner"></span>
              Thinking...
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Settings;
