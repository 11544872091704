import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const AdminRoute = ({ admin }) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("admin", admin);
    if (admin === false) {
      navigate("/dashboard", { replace: true });
    }
  }, [admin]);
  if (admin === undefined) return <></>;
  return <Outlet />;
};
