import React from "react";

const ResultsStats = () => {
  return (
    <div className="flex flex-wrap space-y-4">
      <>
        <div className="w-full flex flex-row gap-y-4">
          <h2 className="w-full text-3xl text-primary-text font-cairoli">
            Totals
          </h2>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>2039</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Orders</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>82.45%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Match Rate</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>678</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Backs</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>1456</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lays</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>294</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>P&L (Units)</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>32%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>POT</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>193</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Back Profit</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>101</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lay Profit</p>
            </div>
          </div>
        </div>
      </>
      {/* Divider */}
      <hr className="w-full border-neutral-300" />
      <>
        <div className="w-full flex flex-row gap-y-4">
          <h2 className="w-full text-3xl text-[#0D85F2] font-cairoli">
            Thoroughbred
          </h2>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>2039</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Orders</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>82.45%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Match Rate</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>678</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Backs</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>1456</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lays</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>294</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>P&L (Units)</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>32%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>POT</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>193</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Back Profit</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>101</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lay Profit</p>
            </div>
          </div>
        </div>
      </>
      <hr className="w-full border-neutral-300" />
      <>
        <div className="w-full flex flex-row gap-y-4">
          <h2 className="w-full text-3xl text-[#16A34A] font-cairoli">
            Greyhounds
          </h2>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>2039</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Orders</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>82.45%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Match Rate</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>678</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Backs</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>1456</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lays</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>294</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>P&L (Units)</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>32%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>POT</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>193</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Back Profit</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>101</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lay Profit</p>
            </div>
          </div>
        </div>
      </>
      <hr className="w-full border-neutral-300" />
      <>
        <div className="w-full flex flex-row gap-y-4">
          <h2 className="w-full text-3xl text-[#16A34A] font-cairoli">
            Harness
          </h2>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>2039</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Orders</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>82.45%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Match Rate</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>678</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Backs</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>1456</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lays</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row gap-y-4 text-center">
          <div className=" w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>294</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>P&L (Units)</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>32%</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>POT</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>193</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Back Profit</p>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4 space-y-4">
            <div className="w-full text-3xl text-primary-text font-cairoli">
              <p>101</p>
            </div>
            <div className="w-full text-md text-secondary-text font-cairoli">
              <p>Lay Profit</p>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ResultsStats;
