import { cn } from "./lib/utils";
import { Button } from "./button";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export function SidebarNav({ className, items, setPage, page, ...props }) {
  return (
    <nav
      className={cn(
        "flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1",
        className
      )}
      {...props}
    >
      {items.map((item) => (
        <Button
          key={item.title}
          onClick={() => setPage(item.title)}
          variant={"ghost"}
          className={cn(
            "justify-start text-primary-text",
            page === item.title && "bg-lbw-secondary-lighter"
          )}
        >
          {item.title}
        </Button>
      ))}
    </nav>
  );
}
