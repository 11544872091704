import React, { useEffect, useState } from "react";
import BaseCard from "../../ui/BaseCard";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectContent,
} from "../../../components/ui/select";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/text-area";
import { Button } from "../../ui/button";

const SendNotification = () => {
  const [sendTo, setSendTo] = useState("All");

  return (
    <BaseCard>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row">
          <h2 className="text-white">Send App Notification</h2>
        </div>
        <div className="flex flex-row">
          <div className="w-1/4 gap-y-2 text-white">
            <Label>Send to</Label>
            <Select defaultValue={sendTo} value={sendTo}>
              <SelectTrigger>
                <SelectValue>Send to</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem>All</SelectItem>
                  <SelectItem>Members</SelectItem>
                  <SelectItem>Members Plus</SelectItem>
                  <SelectItem>Premium</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="w-1/4 gap-y-2 text-white">
            <Label>Title</Label>
            <Input type="text" placeholder="Title" />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="w-1/4 gap-y-2 text-white">
            <Label>Message</Label>
            <Textarea placeholder="Type your message here." />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="w-1/4 gap-y-2 text-white">
            <Button>Send Notification</Button>
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default SendNotification;
