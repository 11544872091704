import React from "react";
import { Separator } from "../../ui/separator";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { supabase } from "../../../utils/supabaseClient";

const ResetPassword = () => {
  const [passwords, setPasswords] = React.useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handlePasswordChange = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: passwords.newPassword,
    });

    if (error) {
      alert(error.message);
      return;
    }

    alert("Password changed successfully");
  };

  return (
    <div className="mr-6 text-primary-text">
      <div className="flex flex-col">
        <h3 className="font-bold">Set a new Password</h3>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col lg:w-1/2 w-full space-y-4">
        <Label className="text-sm">New Password</Label>
        <Input
          onChange={(e) =>
            setPasswords({ ...passwords, newPassword: e.target.value })
          }
          type="text"
          placeholder="New Password"
        />
        <Label className="text-sm">Confirm Password</Label>
        <Input
          onChange={(e) =>
            setPasswords({ ...passwords, confirmPassword: e.target.value })
          }
          type="text"
          placeholder="Last Name"
        />
        <Button
          variant="default"
          onClick={handlePasswordChange}
          className="w-1/4"
        >
          Set New Password
        </Button>
      </div>
    </div>
  );
};

export default ResetPassword;
