import React, { useEffect } from "react";
import { getUser } from "../utils/supabaseClient";
import { supabase } from "../utils/supabaseClient";
const initialState = {
  user: null,
};

// initialize context
export const UserContext = React.createContext(initialState);
UserContext.displayName = "UserContext";

// useDesigner to use context
export const useUser = () => {
  const context = React.useContext(UserContext);

  return context;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);

  const storeUserDetails = (user) => {
    setUser(user);
  };

  const updateUserSettings = async (d) => {
    const { data: updatedData, error } = await supabase
      .from("user_settings")
      .update(d)
      .eq("id", user.us_id)
      .select("*");
    console.log(updatedData, error);
    if (updatedData) {
      refreshUser();
      return true;
    }
    if (error) {
      alert("Error updating settings");
      return false;
    }
  };

  const refreshUser = async () => {
    const u = await getUser();
    // console.log("refreshUser", user);
    setUser(u);
  };

  React.useEffect(() => {
    refreshUser();
  }, []);
  // useEffect(() => {
  //   // console.log(user);
  // }, [user]);
  return (
    <UserContext.Provider
      value={{ user, storeUserDetails, refreshUser, updateUserSettings }}
    >
      {children}
    </UserContext.Provider>
  );
};
