import React, { useState } from "react";
import PuntingFormData from "../../components/Admin/PuntingForm/PuntingFormData";
import AllRaces from "../../components/Admin/PuntingForm/AllRaces";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import { Tabs, TabsTrigger, TabsList } from "../../components/ui/tabs";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectContent,
} from "../../components/ui/select";
import axios from "axios";
import { Button } from "../../components/ui/button";
import { SidebarNav } from "../../components/ui/sidebar-nav";
import SendNotification from "../../components/Admin/AdminControls/SendNotification";

const sidebarNavItems = [
  {
    title: "Refresh Results",
    href: "/customer/profile",
  },
  {
    title: "Send App Notification",
    href: "/customer/profile",
  },
  {
    title: "Invoke Functions",
    href: "/customer/profile",
  },
];

const AdminControls = () => {
  const [page, setPage] = useState("Refresh Results");
  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-full space-y-8 pt-12 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 md:w-1/6">
          <SidebarNav items={sidebarNavItems} setPage={setPage} page={page} />
        </aside>
        <div className="flex-1 md:w-2/3">
          {page === "Send App Notification" ? <SendNotification /> : <></>}
          {/* {page === "Manage Subscription" ? <ManageSubscription /> : <></>} */}
          {/* {page === "Automation Settings" ? <BotSettings /> : <></>} */}
          {/* {page === "Change Password" ? <ResetPassword /> : <></>} */}
          {/* {page === "Betfair Account" ? <BetfairAccount /> : <></>}  */}
        </div>
      </div>
    </div>
  );
};

export default AdminControls;
