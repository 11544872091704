import React from "react";
import moment from "moment";
import { supabase } from "../utils/supabaseClient";

const initialState = {
  dates: null,
};

// initialize context
export const RaceResultContext = React.createContext(initialState);
RaceResultContext.displayName = "UserContext";

// useDesigner to use context
export const useRaceResults = () => {
  const context = React.useContext(RaceResultContext);

  return context;
};

export const RaceResultsProvider = ({ children }) => {
  const [dates, setDates] = React.useState([]);
  const [venues, setVenues] = React.useState([]);
  const [results, setResults] = React.useState([]);
  const [raceList, setRaceList] = React.useState([]);
  const [filteredRaceList, setFilteredRaceList] = React.useState([]);

  const fetchDates = async () => {
    let dates = [];
    for (let i = 0; i < 7; i++) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      let label = "";
      if (i === 0) {
        label = "Today";
      } else if (i === 1) {
        label = "Yesterday";
      } else {
        label = moment(date).format("DD MMM");
      }
      dates.push({
        active: i === 0 ? true : false,
        label: label,
        date: moment(date).format("YYYY-MM-DD"),
      });
    }
    setDates(dates);
    return dates;
  };

  const changeDate = (index) => {
    let newDates = [...dates];
    newDates.forEach((date) => {
      date.active = false;
    });
    newDates[index].active = true;
    console.log(newDates[index]);
    setDates(newDates);
  };

  const getData = async (dateData) => {
    // console.log(dateData);
    const activeDate = dateData.filter((date) => date.active === true)[0];
    console.log(activeDate.date);
    const { data, error } = await supabase
      .from("race_volume_results")
      .select("*")
      .eq("event_date", activeDate.date);

    if (error) {
      console.log(error);
    } else {
      setResults(data);
      return data;
    }
  };

  const initResults = async () => {
    let datess = await fetchDates();
    let d = await getData(datess);
    let venues = [];
    let racesCheck = [];
    let raceData = [];
    d.forEach(async (result) => {
      if (!venues.includes(result.venue)) {
        venues.push(result.venue);
        if (!racesCheck.includes(d.market_id)) {
          raceData.push(...d, {
            active: false,
            inView: false,
          });
          racesCheck.push(d.market_id);
        }
      }
    });
    setVenues(venues);
    setRaceList(raceData);
  };

  const reloadData = async () => {
    let d = await getData(dates);

    let venues = [];
    let racesCheck = [];
    let raceData = [];
    d.forEach(async (result) => {
      if (!venues.includes(result.venue)) {
        venues.push(result.venue);
        if (!racesCheck.includes(d.market_id)) {
          raceData.push(...d, {
            active: false,
            inView: false,
          });
          racesCheck.push(d.market_id);
        }
      }
    });
    setVenues(venues);
    setRaceList(raceData);
  };

  const changeVenue = (venueName) => {
    let newRaceList = [...raceList];
    let filteredRaces = newRaceList.filter((race) => race.venue === venueName);
    filteredRaces.forEach((race) => {
      race.inView = true;
    });
    console.log(filteredRaces);
    setFilteredRaceList(filteredRaces);
  };

  React.useEffect(() => {
    initResults();
  }, []);
  React.useEffect(() => {
    console.log("date");
    reloadData();
  }, [dates]);

  return (
    <RaceResultContext.Provider
      value={{
        dates,
        venues,
        raceList,
        filteredRaceList,
        changeDate,
        changeVenue,
      }}
    >
      {children}
    </RaceResultContext.Provider>
  );
};
