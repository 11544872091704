export const GreenNotification = ({ message, ...props }) => {
  return (
    <div
      className={
        `bg-[#3aff75ac] fixed top-0 md:top-[65px] right-0 mr-2 mt-2 opacity-80 max-w-[220px] text-neutral-900 text-sm text-center font-bold uppercase 
         px-6 py-3 rounded shadow outline-none focus:outline-none mb-1 w-full 
         ease-linear duration-500 ` + props.className
      }
      type="button"
    >
      {message}
    </div>
  );
};

export const RedNotification = ({ message }) => {
  return (
    <div
      className="bg-[#ff3a3aac] text-neutral-900 text-sm text-center font-bold uppercase 
             px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full 
             ease-linear duration-500"
      type="button"
    >
      {message}
    </div>
  );
};

export const YellowNotification = ({ message }) => {
  return (
    <div
      className="bg-[#fffa3aac] text-neutral-900 text-sm text-center font-bold uppercase 
                 px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full 
                 ease-linear duration-500"
      type="button"
    >
      {message}
    </div>
  );
};
