import React, { useState } from "react";
import BaseCard from "../../components/ui/BaseCard";
import UserTable from "../../components/Admin/UserTable";
import { AddUser } from "../../components/Admin/AddUser";
import { Input } from "../../components/ui/input";

const UserManagement = () => {
  const [search, setSearch] = useState("");
  return (
    <div className="flex flex-wrap space-y-4 pt-4 lg:pt-12">
      <div className="w-full">
        <BaseCard>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            className="w-1/4"
          />
          <UserTable search={search} />
        </BaseCard>
      </div>
      <div className="w-full">
        <BaseCard>
          <AddUser />
        </BaseCard>
      </div>
    </div>
  );
};

export default UserManagement;
