import React, { useEffect, useState } from "react";
import { CaptainsLoungeBotSettings } from "../../components/Members/CaptainsLounge/CaptainsLoungeBotSettings";
import { UpcomingRaces } from "../../components/Members/CaptainsLounge/UpcomingRaces";
import { UpcomingVenues } from "../../components/Members/CaptainsLounge/UpcomingVenues";
import { useUser } from "../../contexts/User.context";
import { supabase } from "../../utils/supabaseClient";
import { CaptainsLoungeProvider } from "../../contexts/CaptainsLounge.context";
import { useCaptainsLounge } from "../../contexts/CaptainsLounge.context";
import BaseCard from "../../components/ui/BaseCard";
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectContent,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useToast } from "../../components/ui/use-toast";

const BettingSystems = () => {
  const [systems, setSystems] = useState([{ name: "One", id: 999 }]);
  const [userSystems, setUserSystems] = useState([
    { name: "One", id: 999, race_type: "Thoroughbred", auth_id: "123" },
  ]);

  const { user } = useUser();
  const { toast } = useToast();

  const getSystems = async () => {
    const { data, error } = await supabase.from("betting_systems").select("*");
    if (error) {
      console.log(error);
    } else {
      setSystems(data);
    }
  };

  const getUserSystems = async () => {
    const { data, error } = await supabase
      .from("user_betting_system")
      .select("*")
      .eq("auth_id", user.auth_id);
    if (error) {
      console.log(error);
      return;
    }
    setUserSystems(data);
  };

  const updateSystem = async (system_id) => {
    const { data, error } = await supabase
      .from("user_betting_system")
      .update({ system_id })
      .eq("auth_id", user.auth_id)
      .eq("race_type", "Thoroughbred");
    if (error) {
      console.log(error);
      return;
    }
    toast({
      title: "System Updated",
      description: "Your system has been updated",
    });
    console.log(data);
    getUserSystems();
  };

  useEffect(() => {
    getUserSystems();
    getSystems();
  }, []);

  return (
    <div className="h-full bg-lbw-primary py-6 px-10 max-sm:px-2">
      <div className="flex flex-wrap">
        <div className="w-full mb-12 xl:mb-0 space-y-4">
          <BaseCard>
            <div className="flex flex-col py-6 px-6 space-y-4">
              <h2 className="text-primary-text text-2xl font-cairoli">
                Betting System Selection
              </h2>
              <div className="flex flex-col space-y-4 pt-6">
                <p className="text-primary-text font-cairoli">
                  <span className="font-bold underline underline-offset-2">
                    Tommy
                  </span>
                  : The machine learning model that works off of previous
                  history to determine selections
                </p>
                <p className="text-primary-text font-cairoli">
                  <span className="font-bold underline underline-offset-2">
                    Trend
                  </span>
                  : Works off of selections from weighted ratings and then uses
                  live market data to determine selections
                </p>
                <p className="text-primary-text font-cairoli">
                  <span className="font-bold underline underline-offset-2">
                    Rank and Volume
                  </span>
                  : Input selection criteria that is based around the rank and
                  volume
                </p>
              </div>
              <div className="flex flex-row pt-10">
                <div className="w-1/3 flex flex-col gap-y-4">
                  <h3 className="text-xl text-primary-text font-cairoli">
                    Thoroughbred
                  </h3>
                  <div>
                    <Select
                      value={
                        userSystems.filter(
                          (s) => s.race_type === "Thoroughbred"
                        )[0].system_id
                      }
                      onValueChange={(e) => {
                        updateSystem(e);
                      }}
                    >
                      <SelectTrigger className="w-1/2 text-primary-text">
                        <SelectValue
                          className="text-primary-text"
                          placeholder="Select a system"
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>System</SelectLabel>
                          {systems.map((system) => (
                            <SelectItem value={system.id}>
                              {system.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="w-1/3 flex flex-col gap-y-4">
                  <h3 className="text-xl text-primary-text font-cairoli">
                    Greyhound
                  </h3>
                  <div>
                    <Select disabled>
                      <SelectTrigger className="w-1/2 text-primary-text">
                        <SelectValue
                          className="text-primary-text"
                          placeholder="Select a system"
                          // defaultValue={
                          //   userSystems.filter(
                          //     (s) => s.race_type === "Greyhound"
                          //   )[0].id
                          // }
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>System</SelectLabel>
                          {systems.map((system) => (
                            <SelectItem value={system.id}>
                              {system.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="w-1/3 flex flex-col gap-y-4">
                  <h3 className="text-xl text-primary-text font-cairoli">
                    Harness
                  </h3>
                  <div>
                    <Select disabled>
                      <SelectTrigger className="w-1/2 text-primary-text">
                        <SelectValue
                          className="text-primary-text"
                          placeholder="Select a system"
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>System</SelectLabel>
                          {systems.map((system) => (
                            <SelectItem value={system.name}>
                              {system.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </BaseCard>
        </div>
      </div>
    </div>
  );
};

export default BettingSystems;
