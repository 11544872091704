import React, { useEffect } from "react";
import { Input } from "../../ui/input";
import { Separator } from "../../ui/separator";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { useState } from "react";
import { getUser, supabase } from "../../../utils/supabaseClient";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../ui/tabs";
import { useUser } from "../../../contexts/User.context";

const BotSettings = () => {
  const [newSettings, setNewSettings] = React.useState({
    tbb4_bot: false,
    tbb4_stake_size: 10,
    tbb4_stop_loss: 0,
    tbb4_target_profit: 0,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { user, updateUserSettings } = useUser();

  const onSaveHandler = () => {
    updateUserSettings(newSettings);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(null);
    }, 2000);
  };

  useEffect(() => {
    setNewSettings({
      tbb4_bot: user.tbb4_bot,
      tbb4_stake_size: user.tbb4_stake_size,
      tbb4_stop_loss: user.tbb4_stop_loss,
      tbb4_take_profit: user.tbb4_take_profit,
    });
  }, [user]);

  return (
    <div className="mr-6 text-primary-text">
      <div className="flex flex-col">
        <h3 className="font-bold">Automation Settings</h3>
        <span className="text-sm text-jd-grey">
          Edit your betting automation settings here{" "}
        </span>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col lg:w-1/2 w-full space-y-4">
        <Label className="text-sm">Activated</Label>
        <Tabs
          defaultValue={`${newSettings.tbb4_bot}`}
          value={`${newSettings.tbb4_bot}`}
          onValueChange={(e) =>
            setNewSettings({ ...newSettings, tbb4_bot: e === "true" })
          }
          className="w-[400px]"
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              className="data-[state=active]:bg-green-500"
              value="true"
            >
              On
            </TabsTrigger>
            <TabsTrigger
              className="data-[state=active]:bg-red-500"
              value="false"
            >
              Off
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="flex flex-row space-x-4 max-sm:flex-col max-sm:space-x-0 max-sm:space-y-4">
          <div className="flex flex-col">
            <Label className="text-sm">Unit Size</Label>
            <Input
              className="w-full"
              value={`$ ${
                newSettings.tbb4_stake_size ? newSettings.tbb4_stake_size : 0
              }`}
              onChange={(e) => {
                setNewSettings({
                  ...newSettings,
                  tbb4_stake_size: parseInt(e.target.value.split("$")[1]),
                });
              }}
              type="text"
            />
          </div>
          <div className="flex flex-col">
            <Label className="text-sm">Daily Profit Target</Label>
            <Input
              className="w-full"
              value={`$ ${
                newSettings.tbb4_take_profit ? newSettings.tbb4_take_profit : 0
              }`}
              onChange={(e) => {
                setNewSettings({
                  ...newSettings,
                  tbb4_take_profit: parseInt(e.target.value.split("$")[1]),
                });
              }}
              type="text"
            />
          </div>
          <div className="flex flex-col">
            {" "}
            <Label className="text-sm">Max Daily Loss</Label>
            <Input
              className="w-full"
              value={`$ ${
                newSettings.tbb4_stop_loss ? newSettings.tbb4_stop_loss : 0
              }`}
              onChange={(e) => {
                setNewSettings({
                  ...newSettings,
                  tbb4_stop_loss: parseInt(e.target.value.split("$")[1]),
                });
              }}
              type="text"
            />
          </div>
        </div>

        {success && (
          <span className="text-green-500">
            Profile was updated successfully
          </span>
        )}
        <Button variant="default" onClick={onSaveHandler} className="w-1/4">
          Update Settings
        </Button>
      </div>
    </div>
  );
};

export default BotSettings;
