import React, { useEffect } from "react";
import BaseCard from "../../components/ui/BaseCard";
import SelectionsTable from "../../components/Admin/ML/SelectionsTable";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import GHSelectionsTable from "../../components/Admin/ML/GHSelectionsTable";

const GHMLSelections = () => {
  const [horses, setHorses] = React.useState([]);

  const getHorseSelections = async () => {
    const { data, error } = await supabase
      .from("ml_greyhound_selections")
      .select("*")
      .eq("event_date", moment().format("YYYY-MM-DD"))
      .order("market_start_time", { ascending: false });
    if (error) console.log("error", error);
    else setHorses(data);
  };

  useEffect(() => {
    getHorseSelections();

    // Subscribe to new horse selections
    const horseUpdates = supabase
      .channel("ml_greyhound_selections")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "ml_greyhound_selections" },
        (payload) => {
          console.log("Change received!", payload);
          setHorses((prev) => [payload.new, ...prev]);
        }
      )
      .subscribe();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-primary-text text-3xl font-cairoli">
            Machine Learning Greyhound Selections
          </h2>
        </div>
      </div>
      <BaseCard>
        <div className="flex max-sm:flex-col md:flex-row py-4 gap-x-2">
          <div className="w-full flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            <GHSelectionsTable data={horses} />
          </div>
          {/* <div className="w-full flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            <SelectionsTable data={dogs} />
          </div> */}
        </div>
        <hr className="border-lbw-accent" />
        <div className="py-0"></div>
      </BaseCard>
    </div>
  );
};

export default GHMLSelections;
