export const HorseIcon = ({ className }) => (
  <i class={`${className} font-sb horse-icon`} />
);

export const TickIcon = ({ className }) => (
  <i class={`${className} font-sb tick-icon`} />
);

export const GreyhoundIcon = ({ className }) => (
  <i class={`${className} font-sb greyhound-icon`} />
);

export const HarnessIcon = ({ className }) => (
  <i class={`${className} font-sb harness-icon`} />
);
export const TrophyIcon = ({ className }) => (
  <i class={`${className} font-sb trophy-icon`} />
);

export const ThumbsUpIcon = ({ className }) => (
  <i class={`${className} font-sb thumbs-up-icon`} />
);

export const ThumbsDownIcon = ({ className }) => (
  <i class={`${className} font-sb thumbs-down-icon`} />
);

export const SecondPlaceIcon = ({ className }) => (
  <i class={`${className} font-sb second-place-icon`} />
);
