import React from "react";
import { supabase, supabaseKey } from "../../utils/supabaseClient";
import moment from "moment";
export const AddUser = () => {
  const [subTypes, setSubTypes] = React.useState([]); // ["Free", "Monthly", "Yearly"]
  const [newUser, setNewUser] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    betfair_refresh_token: null,
    betfair_vendor_client_id: null,
    tbb4_stake_size: 1,
    tbb4_take_profit: 1,
    tbb4_stop_loss: 1,
    tbb4_bot: false,
    tbb4_package_id: 1,
  });

  const createUser = async () => {
    const { data: authData, error } = await supabase.auth.signUp({
      email: newUser.email,
      password: newUser.password,
    });

    if (error) {
      console.log(error);
    }

    if (authData) {
      const newUserData = await supabase
        .from("user_details")
        .insert([
          {
            auth_id: authData.user.id,
            first_name: newUser.firstName,
            last_name: newUser.lastName,
            email: newUser.email,
          },
        ])
        .select("*");

      if (newUserData.error) {
        console.log(newUserData.error);
      }

      if (newUserData.data) {
        const newSettingsData = await supabase
          .from("user_settings")
          .insert([
            {
              betfair_refresh_token: newUser.betfair_refresh_token,
              betfair_vendor_client_id: newUser.betfair_vendor_client_id,
              tbb4_stake_size: newUser.tbb4_stake_size,
              tbb4_take_profit: newUser.tbb4_take_profit,
              tbb4_stop_loss: newUser.tbb4_stop_loss,
              tbb4_bot: newUser.tbb4_bot,
            },
          ])
          .select("*");

        if (newSettingsData.error) {
          console.log(newSettingsData.error);
        }

        if (newSettingsData.data) {
          const newSubscriptionData = await supabase
            .from("user_subscription")
            .insert([
              {
                tbb4: true,
                tbb3: true,
                expires:
                  newUser.tbb4_package_id === 4 || "4"
                    ? "2030-01-01"
                    : moment().add(1, "month").format("YYYY-MM-DD"),
                tbb4_package: newUser.tbb4_package_id,
                captains_lounge:
                  newUser.tbb4_package_id === 4 || 3 ? true : false,
              },
            ])
            .select("*");

          if (newSubscriptionData.error) {
            console.log(newSubscriptionData.error);
          }

          if (newSubscriptionData.data) {
            const updateUser = await supabase
              .from("user_details")
              .update({
                settings_id: newSettingsData.data[0].id,
                subscription_id: newSubscriptionData.data[0].id,
              })
              .eq("id", newUserData.data[0].id)
              .select("*");

            if (updateUser.error) {
              console.log(updateUser.error);
            }

            if (updateUser.data) {
              alert("User created successfully");
            }
          }
        }
      }
    }
  };

  const getSubscriptions = async () => {
    const { data, error } = await supabase.from("tbb4_packages").select("*");
    if (error) console.log("error", error);
    else setSubTypes(data);
  };

  React.useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-lbw-secondary border-0">
      <div className="rounded-t mb-0 px-6 py-6">
        <div className="flex justify-between">
          <h6 className="text-primary-text text-xl font-bold">Add new User</h6>
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-2 pt-0">
        <h6 className="text-primary-text text-lg mt-3 mb-6 font-bold uppercase lg:mt-4">
          Basic Information
        </h6>
        <div className="flex flex-wrap lg:mb-4">
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-primary-text text-xs font-bold mb-2"
                data-tooltip-target="stake-size"
              >
                First Name
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setNewUser({ ...newUser, firstName: e.target.value })
                }
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-primary-text text-xs font-bold mb-2">
                Last Name
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setNewUser({ ...newUser, lastName: e.target.value })
                }
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-primary-text text-xs font-bold mb-2">
                Email
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap lg:mb-4">
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-primary-text text-xs font-bold mb-2"
                data-tooltip-target="stake-size"
              >
                Betfair Refresh Token
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setNewUser({
                    ...newUser,
                    betfair_refresh_token: e.target.value,
                  })
                }
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-primary-text text-xs font-bold mb-2">
                Betfair Vendor Client ID
              </label>
              <input
                type="text"
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-primary-text text-xs font-bold mb-2">
                Subscription Type
              </label>
              <select
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
                onChange={(e) =>
                  setNewUser({ ...newUser, tbb4_package_id: e.target.value })
                }
              >
                {subTypes.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap lg:mb-4">
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-primary-text text-xs font-bold mb-2"
                data-tooltip-target="stake-size"
              >
                Password
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setNewUser({
                    ...newUser,
                    password: e.target.value,
                  })
                }
                className="border-0 w-full px-3 py-3 placeholder-slate-300 text-primary-text bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-primary-text text-xs font-bold mb-2">
                Betfair Vendor Client ID
              </label>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4 mb-12 lg:mb-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-primary-text text-xs font-bold mb-2">
                Subscription Type
              </label>
            </div>
          </div>
        </div>
        <hr className="mt-6 border-b-1 border-slate-300" />

        <div className="flex pt-4 justify-center">
          <div className="w-full lg:w-4/12 px-4">
            <button
              onClick={() => createUser()}
              className="bg-lbw-accent text-neutral-900 hover:bg-yellow-500 text-sm font-bold uppercase 
              px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
              ease-linear transition-all duration-150"
              type="button"
            >
              Add User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
