import React, { useState } from "react";
import { useUser } from "../../../contexts/User.context";
import { supabase } from "../../../utils/supabaseClient";
import {
  Table,
  TableHeader,
  TableFooter,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "../../ui/table";

const ResultsTable = ({ results }) => {
  const [filteredResults, setFilteredResults] = useState([]);

  const { user } = useUser();

  const reduceResultsToFirstFive = () => {
    const filtered = results.filter((r) => r.profit !== 0).reverse();
    const reduced = filtered.slice(0, 5);
    setFilteredResults(reduced);
  };

  React.useEffect(() => {
    if (results) {
      reduceResultsToFirstFive();
    }
  }, [results]);
  return (
    <>
      <div className="py-2  space-y-4 rounded-md">
        <div className="flex flex-wrap w-full">
          <h2 className="text-primary-text text-lg text-left font-cairoli font-bold pb-2">
            Recent Results
          </h2>
          <div className="w-full rounded-lg">
            {filteredResults?.length > 0 &&
            filteredResults[0].selection_name !== undefined ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Race</TableHead>
                    <TableHead>Selection</TableHead>
                    <TableHead>Side</TableHead>
                    <TableHead>Profit</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredResults.map((r) => (
                    <TableRow key={r.id}>
                      <TableCell>{r.race_name}</TableCell>
                      <TableCell>{r.selection_name}</TableCell>
                      <TableCell>{r.side}</TableCell>
                      <TableCell
                        className={`${
                          r.profit_with_comm > 0
                            ? "text-green-500"
                            : !r.profit_with_comm
                            ? ""
                            : "text-red-500"
                        }`}
                      >
                        {r.profit
                          ? `$${r.profit_with_comm.toFixed(2)}`
                          : "PENDING"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Race</TableHead>
                    <TableHead>Selection</TableHead>
                    <TableHead>Side</TableHead>
                    <TableHead>Profit</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultsTable;
