import React, { useEffect } from "react";
import { supabase } from "../../../utils/supabaseClient";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";

const MembersPlus = ({ settings }) => {
  const [newSettings, setNewSettings] = React.useState({
    active: false,
    take_profit: 0,
    stop_loss: 0,
    aus: true,
    nz: false,
    max_odds: 15,
    min_odds: 2,
    max_rel_prob: 1.3,
    min_rel_prob: 0.6,
  });

  const activateBot = async (e) => {
    const { data, error } = await supabase
      .from("bot_settings")
      .update({ settings: { ...newSettings, active: e === "true" } })
      .eq("id", settings.id);
    setNewSettings({ ...newSettings, active: e === "true" });
  };

  const saveSettings = async (s) => {
    console.log(s);
    const { data, error } = await supabase
      .from("bot_settings")
      .update({ settings: newSettings })
      .eq("id", settings.id);

    if (error) alert(error);
  };

  useEffect(() => {
    setNewSettings(settings.settings);
  }, []);
  return (
    <div className="flex flex-col w-full px-10 py-5 space-y-6">
      <div className="flex flex-row">
        <h2 className="text-primary-text text-3xl">
          {settings.name ? settings.name : ""}
        </h2>
      </div>
      <div className="flex flex-row justify-center">
        <div className="w-1/2">
          <Tabs
            defaultValue={newSettings.active === true ? "true" : "false"}
            value={newSettings.active === true ? "true" : "false"}
            onValueChange={(e) => activateBot(e)}
            className="w-full bg-lbw-secondary"
          >
            <TabsList className="grid w-full bg-lbw-primary grid-cols-2">
              <TabsTrigger
                className="data-[state=active]:bg-green-500"
                value="true"
              >
                On
              </TabsTrigger>
              <TabsTrigger
                className="data-[state=active]:bg-red-500"
                value="false"
              >
                Off
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-1/4 flex flex-col space-y-2">
          <Label>Take Profit</Label>
          <Input
            type="number"
            value={parseFloat(newSettings.take_profit)}
            onChange={(e) =>
              setNewSettings({
                ...newSettings,
                take_profit: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <div className="w-1/4 flex flex-col space-y-2">
          <Label>Stop Loss</Label>
          <Input
            type="number"
            value={parseFloat(newSettings.stop_loss)}
            onChange={(e) =>
              setNewSettings({
                ...newSettings,
                stop_loss: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <div className="w-1/4 flex flex-col space-y-2">
          <Label>Min Odds</Label>
          <Input
            type="number"
            value={parseFloat(newSettings.min_odds)}
            onChange={(e) =>
              setNewSettings({
                ...newSettings,
                min_odds: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <div className="w-1/4 flex flex-col space-y-2">
          <Label>Max Odds</Label>
          <Input
            type="number"
            value={parseFloat(newSettings.max_odds)}
            onChange={(e) =>
              setNewSettings({
                ...newSettings,
                max_odds: parseFloat(e.target.value),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-1/4 flex flex-col space-y-2">
          <Label>Min Rel. Prob</Label>
          <Input
            type="number"
            value={parseFloat(newSettings.min_rel_prob)}
            onChange={(e) =>
              setNewSettings({
                ...newSettings,
                min_rel_prob: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <div className="w-1/4 flex flex-col space-y-2">
          <Label>Max Rel. Prob</Label>
          <Input
            type="number"
            value={parseFloat(newSettings.max_rel_prob)}
            onChange={(e) =>
              setNewSettings({
                ...newSettings,
                max_rel_prob: parseFloat(e.target.value),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4 justify-center">
        <Button
          className="w-1/4"
          variant="default"
          onClick={() => saveSettings("test")}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default MembersPlus;
