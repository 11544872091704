import React, { useState, useEffect } from "react";
import { GiMoneyStack } from "react-icons/gi";
import { BsCheck2All } from "react-icons/bs";
import { useUser } from "../../../contexts/User.context";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
  TrophyIcon,
  SecondPlaceIcon,
} from "../../ui/Icon";
import BaseCard from "../../ui/BaseCard";
import { ArrowBigDown, ArrowBigUp, Check, DollarSignIcon } from "lucide-react";
const DailyMetrics = ({ results }) => {
  const [metrics, setMetrics] = useState({
    backs: 0,
    lays: 0,
    profit: 0,
    matchRate: 0,
  });
  const { user } = useUser();
  const handleResults = () => {
    const backs = results?.filter((item) => item.side === "BACK").length
      ? results.filter((item) => item.side === "BACK").length
      : 0;
    const lays = results?.filter((item) => item.side === "LAY").length
      ? results.filter((item) => item.side === "LAY").length
      : 0;
    const profit = results?.reduce((a, b) => a + b.profit_with_comm, 0);
    const nullCount =
      results?.filter((item) => item.profit === null).length ?? 0;
    let matchRate =
      ((results?.length - nullCount) / results?.length) * 100
        ? ((results?.length - nullCount) / results?.length) * 100
        : 0;
    if (isNaN(matchRate)) {
      matchRate = 0;
    }

    setMetrics({
      backs: backs ? backs : 0,
      lays: lays ? lays : 0,
      profit: profit ? profit : 0,
      matchRate: matchRate ? matchRate : 0,
    });
  };

  useEffect(() => {
    handleResults();
  }, [results]);
  return (
    <div className="flex flex-wrap ">
      <BaseCard>
        <div className="w-full flex max-sm:flex-wrap lg:flex-row lg:gap-x-6 gap-y-4 text-center">
          <div className="flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4 ">
            <MetricCard
              title="Profit"
              icon={<DollarSignIcon size={18} className="text-primary-text" />}
              value={metrics.profit}
              dollar
            />
          </div>
          <div className="flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4">
            <MetricCard
              title="Match Rate"
              icon={<Check size={20} className="text-primary-text" />}
              value={metrics.matchRate}
              percent
              matchRate
            />
          </div>
          <div className="flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4">
            <MetricCard
              title="Backs"
              icon={<ArrowBigUp size={18} className="text-primary-text" />}
              value={metrics.backs}
            />
          </div>
          <div className=" flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4">
            <MetricCard
              title="Lays"
              icon={<ArrowBigDown size={18} className=" text-primary-text" />}
              value={metrics.lays}
            />
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

const MetricCard = ({
  title,
  icon,
  value,
  goal,
  progress,
  dollar,
  percent,
  matchRate,
}) => {
  const { user } = useUser();
  const valueActual = value ? value : 0;

  return (
    <div className="flex flex-col w-full gap-y-1">
      <div className="w-full  flex flex-row my-auto items-center">
        <p className=" text-primary-text text-left font-black font-cairoli">
          {/* {icon} */}
        </p>
        <p className=" text-primary-text text-left font-black font-cairoli text-md">
          {title}
        </p>
      </div>
      <div className="flex flex-row gap-x-12 items-center">
        {/* <div className="w-1/4 max-sm:hidden">{icon}</div> */}
        <div className="max-sm:w-full w-3/4">
          <p className=" text-primary-text text-left font-cairoli text-md">
            {dollar ? "$" : ""}
            {dollar || percent ? valueActual.toFixed(2) : valueActual}
            {percent ? "%" : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DailyMetrics;
