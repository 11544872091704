import React, { useState, useEffect } from "react";
import { GiMoneyStack } from "react-icons/gi";
import { BsCheck2All } from "react-icons/bs";
import { useUser } from "../../../contexts/User.context";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
  TrophyIcon,
  SecondPlaceIcon,
} from "../../ui/Icon";
import BaseCard from "../../ui/BaseCard";
import { ArrowBigDown, ArrowBigUp, Check, DollarSignIcon } from "lucide-react";
import RacingStrength from "../../charts/RacingStrength";
import RacingStrengthRemaining from "../../charts/RacingStrengthRemaining";
const RacingStats = ({ results }) => {
  return (
    <div className="flex flex-wrap ">
      <BaseCard>
        <div className="w-full flex max-sm:flex-wrap lg:flex-row lg:gap-x-6 gap-y-4 text-center">
          <div className="flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4 ">
            <RacingStrength />
          </div>
          <div className="flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4 ">
            <RacingStrengthRemaining />
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default RacingStats;
