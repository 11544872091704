import React, { useState, useEffect } from "react";
import DateFilter from "../../components/Admin/RaceResults/DateFilter";
import { RaceResultsProvider } from "../../contexts/RaceResults.context";
import VenueFilter from "../../components/Admin/RaceResults/VenueFilter";
import RaceResultTable from "../../components/Admin/RaceResults/RaceResultTable";
const RaceResults = () => {
  const [date, setDate] = useState(null);
  return (
    <RaceResultsProvider>
      <div className="h-full bg-lbw-primary py-6">
        <h2 className="text-primary-text text-2xl font-cairoli pb-2">
          All Race Results
        </h2>
        <div className="flex flex-row gap-x-4">
          <div className="w-full mb-12 xl:mb-0 pb-4 space-y-4">
            <DateFilter />
          </div>
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="w-full mb-12 xl:mb-0 pb-4 space-y-4">
            <VenueFilter />
          </div>
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="w-full mb-12 xl:mb-0 pb-4 space-y-4">
            <RaceResultTable />
          </div>
        </div>
      </div>
    </RaceResultsProvider>
  );
};

export default RaceResults;
