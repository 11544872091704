import React from "react";
import { Input } from "../../ui/input";
import { Separator } from "../../ui/separator";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { useState } from "react";
import { getUser, supabase } from "../../../utils/supabaseClient";

const Profile = () => {
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const updateUser = async () => {
    const { data, error } = await supabase
      .from("user_details")
      .update(user)
      .eq("id", user.id)
      .select();
    if (error) {
      console.log(error);
      setError(error);
    }
    if (data) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  };

  React.useEffect(() => {
    const fetchUser = async () => {
      const u = await getUser();
      console.log(u);
      setUser({
        id: u.ud_id,
        email: u.email,
        first_name: u.first_name,
        last_name: u.last_name,
      });
    };
    fetchUser();
  }, []);
  return (
    <div className="mr-6 text-primary-text">
      <div className="flex flex-col">
        <h3 className="font-bold">Profile</h3>
        <span className="text-sm text-jd-grey">
          Edit your profile information here{" "}
        </span>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col lg:w-1/2 w-full space-y-4">
        <Label className="text-sm">Email</Label>
        <Input
          value={user.email}
          type="text"
          disabled={true}
          placeholder="Email"
        />
        <Label className="text-sm">First Name</Label>
        <Input
          onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          value={user.first_name}
          type="text"
          placeholder="First Name"
        />
        <Label className="text-sm">Last Name</Label>
        <Input
          onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          value={user.last_name}
          type="text"
          placeholder="Last Name"
        />
        {/* <Label className="text-sm">Company</Label>
        <Input type="text" placeholder="Company" /> */}
        {success && (
          <span className="text-green-500">
            Profile was updated successfully
          </span>
        )}
        <Button variant="default" onClick={updateUser} className="w-1/4">
          Update Profile
        </Button>
      </div>
    </div>
  );
};

export default Profile;
