import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabaseClient";
import moment from "moment";
// import stringify from "csv-stringify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { HorseIcon, HarnessIcon, GreyhoundIcon } from "../../ui/Icon";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "../../ui/table";
import CountdownText from "../../ui/CountdownText";

const LbTable = ({ date }) => {
  const [botResults, setBotResults] = useState([]);
  const [profitLoss, setProfitLoss] = useState(0);
  //   const [date, setDate] = useState(moment.format("YYYY-MM-DD"));

  const getLadbrokesOdds = async () => {
    const { data, error } = await supabase
      .from("member_selections_ladbrokes_prices")
      .select("*")
      .eq("meeting_date", date);
    // console.log(data);
    if (error) {
      console.error(error);
    }
    if (data) {
      // add a decision column to data based on 3 price points
      console.log(data);
      data.forEach((item) => {
        try {
          if (
            item.price_11am >= item.price_jump &&
            item.price_11am - item.price_jump > 0.11 &&
            item.price_jump < 6
          ) {
            item.decision = "LAY";
            item.multiplier =
              item.price_11am / item.price_jump - 1 >= 0.15 ? "2" : "1";
            item.movement = (item.price_11am / item.price_jump - 1) * 100;
            item.betResult =
              item.result === "WINNER"
                ? item.multiplier * item.price_jump - item.multiplier
                : item.result === "LOSER"
                ? item.multiplier * -1
                : 0;
          } else if (item.price_jump < 6) {
            item.decision = "BACK";
            item.multiplier =
              item.price_jump / item.price_11am - 1 >= 0.15 ? "2" : "1";
            item.movement = (item.price_jump / item.price_11am - 1) * 100;
            item.betResult =
              item.result === "WINNER"
                ? item.multiplier * item.price_jump * -1 +
                  parseInt(item.multiplier)
                : item.result === "LOSER"
                ? item.multiplier
                : 0;
          } else {
            item.decision = "NO BET";
          }
        } catch (err) {
          item.decision = "NO BET";
        }
      });

      // setProfitLoss(profit);
      setBotResults(data.reverse());
    }
  };

  const updateSelection = async (id, marketId, decision) => {
    // Update item in bot results that matches marketid and id
    const item = botResults.find(
      (item) => item.selection_id === id && item.market_id === marketId
    );

    if (item) {
      item.side = decision;
    }

    //update item in botResults
    setBotResults([...botResults]);

    const { data, error } = await supabase
      .from("starter_package_selection_overrides")
      .update({ side: decision })
      .eq("selection_id", id)
      .eq("market_id", marketId);
    if (error) {
      console.error(error);
    }
    if (data) {
      console.log(data);
    }
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const d = botResults.map((item) => ({
      date: moment(item.meeting_date).format("ddd DD MMM"),
      race: `${item.venue_name} - R${item.race_number}`,
      selection: `${item.runner_number}. ${item.runner_name}`,
      price_open: item.price_open,
      price_9am: item.price_9am,
      price_2: item.price_11am,
      price_jump: item.price_jump,
      decision: item.decision,
      betResult: item.betResult,
    }));
    const ws = XLSX.utils.json_to_sheet(d);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `Members Trend Data - ${moment(date).format(
      "DD MMM YYYY"
    )}${fileExtension}`;
    FileSaver.saveAs(data, fileName);
  };

  const refreshData = () => {
    getLadbrokesOdds();
  };

  useEffect(() => {
    getLadbrokesOdds();
  }, [date]);

  useEffect(() => {
    getLadbrokesOdds();
  }, []);

  const tableHeaders = [
    "#",
    "Date",
    "Race",
    "Horse",
    "Price Movement",
    "Change Bet",
    "Latest LB Price",
    "Flucs",
    "Decision",
    "Result",
  ];

  return (
    <div className=" bg-lbw-secondary space-y-4 rounded-md">
      {botResults?.length > 0 ? (
        <div className="py-4 flex flex-wrap w-full justify-center">
          <div
            className="w-full flex max-sm:flex-col-reverse max-sm:gap-y-4 flex-row
           px-4 py-4"
          >
            <div className="max-sm:w-full w-1/6">
              <button
                className="bg-lbw-accent float-right mt-auto text-primary-text max-sm:w-full  active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={refreshData}
              >
                Refresh Results
              </button>
            </div>
            <div className="max-sm:w-full w-1/6"></div>
            <div className="max-sm:w-full w-4/6 float-right">
              <button
                className="bg-lbw-accent max-sm:w-full  float-right mt-auto text-primary-text active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={exportToCSV}
              >
                Download Excel
              </button>
            </div>
          </div>
          <div className="w-full px-4 overflow-x-auto">
            {/* <button
              className="bg-green-900 text-primary-text active:bg-green-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
            >
              Export to CSV
            </button> */}
            <Table>
              <TableHeader>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableHead>{header}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {botResults.map((item, index) => (
                  <TableRow
                    className={`border-b-2 text-primary-text font-bold`}
                    key={item.id}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <>
                        {moment(item.start_time).format("hh:mma")}
                        {moment(item.start_time)
                          .add(2, "minutes")
                          .diff(moment(), "minutes") > 0 && (
                          <div
                            className={`my-auto px-2 text-md rounded-md py-1 flex flex-row ${
                              item.redTime
                                ? "bg-red-700 text-primary-text"
                                : "bg-lbw-secondary-lighter text-primary-text"
                            }`}
                          >
                            <div className="flex flex-row whitespace-nowrap">
                              <CountdownText
                                jumpTime={item.start_time}
                                className={"text-primary-text"}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    </TableCell>
                    <TableCell>
                      {item.venue_name} - R{item.race_number}
                    </TableCell>
                    <TableCell>
                      {item.runner_number}. {item.runner_name}
                    </TableCell>
                    <TableCell>
                      <span>${item.price_open?.toFixed(2)} </span>
                      <i class="fa-solid fa-arrow-right"></i>
                      <span
                        className={`${
                          item.price_open > item.price_9am
                            ? "text-green-400"
                            : item.price_open < item.price_9am
                            ? "text-red-400"
                            : "text-primary-text"
                        }`}
                      >
                        {" "}
                        ${item.price_9am?.toFixed(2)}{" "}
                      </span>
                      <i class="fa-solid fa-arrow-right"></i>

                      <span
                        className={`${
                          item.price_9am > item.price_11am
                            ? "text-green-400"
                            : item.price_9am < item.price_11am
                            ? "text-red-400"
                            : "text-primary-text"
                        }`}
                      >
                        {" "}
                        ${item.price_11am?.toFixed(2)}{" "}
                      </span>
                      <i class="fa-solid fa-arrow-right"></i>
                      <span
                        className={`${
                          item.price_11am > item.price_jump
                            ? "text-green-400"
                            : item.price_11am < item.price_jump
                            ? "text-red-400"
                            : "text-primary-text"
                        }`}
                      >
                        {" "}
                        ${item.price_jump}
                      </span>
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-col gap-y-2">
                        <div className="flex flex-row gap-x-2">
                          <DecisionButton
                            current={item.side}
                            decision="BACK"
                            id={item.selection_id}
                            marketId={item.market_id}
                            updateSelection={updateSelection}
                            startTime={item.start_time}
                          />
                          <DecisionButton
                            current={item.side}
                            decision="LAY"
                            id={item.selection_id}
                            marketId={item.market_id}
                            updateSelection={updateSelection}
                            startTime={item.start_time}
                          />
                        </div>
                        <div className="flex flex-row gap-x-2">
                          <DecisionButton
                            current={item.side}
                            decision="TREND"
                            id={item.selection_id}
                            marketId={item.market_id}
                            updateSelection={updateSelection}
                            startTime={item.start_time}
                          />
                          <DecisionButton
                            current={item.side}
                            decision="NO BET"
                            id={item.selection_id}
                            marketId={item.market_id}
                            updateSelection={updateSelection}
                            startTime={item.start_time}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className={`${
                        item.price_latest > item.price_11am
                          ? "text-red-400"
                          : item.price_latest < item.price_11am
                          ? "text-green-400"
                          : ""
                      }`}
                    >
                      ${item.price_latest?.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <FlucChart
                        flucs={item.flucs}
                        lp={parseFloat(item.price_jump)}
                      />
                    </TableCell>
                    <TableCell
                      className={`${
                        item.decision === "LAY"
                          ? "text-red-400"
                          : item.decision === "BACK"
                          ? "text-green-400"
                          : ""
                      }`}
                    >
                      {item.price_jump &&
                        `${item.decision} ${
                          item.multiplier ? `${item.multiplier}U` : ""
                        }`}
                    </TableCell>
                    <TableCell
                      className={`${
                        parseFloat(item.betResult) > 0
                          ? "text-green-400"
                          : parseFloat(item.betResult) < 0
                          ? "text-red-400"
                          : ""
                      }`}
                    >
                      {item.betResult &&
                        `${parseFloat(item.betResult).toFixed(2)} Units`}
                    </TableCell>
                  </TableRow>
                ))}
                <tr className="border-b-2 text-primary-text font-bold">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total Profit</td>
                  <td>{profitLoss.toFixed(2)}</td>
                </tr>
              </TableBody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const FlucChart = ({ flucs, lp }) => {
  const [flucData, setFlucData] = useState([]);
  const [support, setSupport] = useState(false);

  useEffect(() => {
    let d = flucs.split(",").map((item, index) => ({
      c: index,
      fluc: parseFloat(item),
    }));
    // Add lp to flucs at end
    if (lp) d.push({ c: d.length, fluc: lp });
    console.log(d);
    setFlucData(d);
    const lastFluc = d[d.length - 1].fluc;
    const firstFluc = d[0].fluc;
    if (lastFluc < firstFluc) {
      setSupport(true);
    } else {
      setSupport(false);
    }
  }, [flucs]);

  return (
    <LineChart
      width={120}
      height={30}
      data={flucData}
      margin={{
        top: 2,
        right: 2,
        left: 2,
        bottom: 2,
      }}
    >
      {/* <CartesianGrid vertical={false} strokeDasharray={2} /> */}
      <XAxis hide dataKey="c" type="category" />
      <YAxis hide type="number" name="fluc" domain={["dataMin", "dataMax"]} />
      <Tooltip />

      {/* <Legend iconSize={0} /> */}
      <Line
        type="monotone"
        name="Flucs"
        dataKey="fluc"
        stroke={support ? "#25da3b" : "#da2525"}
        dot={true}
        activeDot={{ r: 2 }}
      />
      {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
    </LineChart>
  );
};

const DecisionButton = ({
  current,
  decision,
  id,
  marketId,
  updateSelection,
  startTime,
}) => {
  const disabled =
    moment().diff(moment(startTime), "minutes") < 2 ? false : true;

  return (
    <button
      disabled={disabled}
      className={`${
        current === "BACK" && decision === "BACK" && disabled === false
          ? "bg-blue-400"
          : current === "LAY" && decision === "LAY" && disabled === false
          ? "bg-red-400"
          : current === "TREND" && decision === "TREND" && disabled === false
          ? "bg-orange-400"
          : current === "NO BET" && decision === "NO BET" && disabled === false
          ? "bg-gray-400"
          : "bg-transparent"
      } ${
        decision === "BACK" && disabled === false
          ? "border-blue-400 hover:bg-blue-600 active:bg-blue-800"
          : decision === "LAY" && disabled === false
          ? "border-red-500 hover:bg-red-600 active:bg-red-800"
          : decision === "TREND" && disabled === false
          ? "border-orange-400 hover:bg-orange-600 active:bg-orange-800"
          : decision === "NO BET" && disabled === false
          ? "border-gray-400 hover:bg-gray-600 active:bg-gray-800"
          : ""
      } ${
        disabled === true ? "text-gray-700" : "text-white"
      } border text-sm font-bold uppercase px-3 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150`}
      onClick={() => updateSelection(id, marketId, decision)}
    >
      {decision}
    </button>
  );
};

export default LbTable;
