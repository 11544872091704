import { cn } from "./lib/utils";

const Textarea = ({ className, ...props }) => {
  return (
    <textarea
      className={cn(
        "flex min-h-[80px] w-full rounded-md focus:border-0 active:border-0 bg-lbw-secondary-lighter px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    />
  );
};
Textarea.displayName = "Textarea";

export { Textarea };
