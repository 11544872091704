import React, { useEffect } from "react";
import moment from "moment";
import { supabase } from "../utils/supabaseClient";
import { useUser } from "./User.context";
const initialState = {
  strategies: [],
  clUser: null,
};

// initialize context
export const CLContext = React.createContext(initialState);
CLContext.displayName = "CLContext";

// useDesigner to use context
export const useCaptainsLounge = () => {
  const context = React.useContext(CLContext);

  return context;
};

export const CaptainsLoungeProvider = ({ children }) => {
  const [strategies, setStrategies] = React.useState([]);
  const [clUser, setCLUser] = React.useState(null);

  const { user } = useUser();

  const fetchStrategies = async () => {
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .select("*")
      .eq("user_id", user.auth_id);
    if (data) {
      console.log(data);
      setStrategies(data);
      return data;
    }
    if (error) {
      return error;
    }
  };

  const getCLUser = async () => {
    const { data, error } = await supabase
      .from("captains_lounge_full")
      .select("*")
      .eq("auth_id", user.auth_id);
    if (data) {
      setCLUser(data[0]);
      return data[0];
    }
    if (error) {
      return error;
    }
  };

  useEffect(() => {
    if (user) {
      fetchStrategies();
      getCLUser();
    }
  }, []);

  useEffect(() => {
    if (strategies) {
      console.log("strategies", strategies);
    }
  }, [strategies]);

  if (!user) {
    return null;
  }
  return (
    <CLContext.Provider value={{ strategies, clUser, fetchStrategies }}>
      {children}
    </CLContext.Provider>
  );
};
