import React, { useEffect, useState } from "react";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import DevBotResultsTable from "../../components/Admin/DevelopmentBots/DevBotResultsTable";

const DevBotResults = () => {
  const [botList, setBotList] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [relativeDate, setRelativeDate] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getBotList = async () => {
    const { data, error } = await supabase
      .from("development_bots")
      .select("*")
      .order("bot_active", { ascending: false })
      .order("created_at", { ascending: true });
    console.log(data);
    if (error) {
      console.log(error);
    } else {
      setBotList(data);
    }
  };

  const defaultDates = () => {
    let today = new Date();
    setStartDate(moment(today).format("YYYY-MM-DD"));
    setEndDate(moment(today).format("YYYY-MM-DD"));
  };

  const handleBotChange = (e) => {
    setSelectedBot(e.target.value);
  };

  const handleDateChange = (e) => {
    if (e.target.id === "start") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleRelativeDateChange = (e) => {
    let val = parseInt(e.target.value);
    setRelativeDate(val);
    console.log(val);
    if (val === 1) {
      defaultDates();
    }
    if (val === 2) {
      console.log("yesterday");
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setStartDate(moment(yesterday).format("YYYY-MM-DD"));
      setEndDate(moment(yesterday).format("YYYY-MM-DD"));
    } else {
      let today = new Date();
      let fromDate = moment(today)
        .subtract(val - 1, "days")
        .format("YYYY-MM-DD");
      setStartDate(fromDate);
      setEndDate(moment(today).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    getBotList();
    defaultDates();
  }, []);

  return (
    <div className="bg-lbw-primary pt-[8%]">
      <BaseCard>
        <div className="flex max-sm:flex-wrap md:flex-row py-4 gap-x-2">
          <div className="w-full lg:w-3/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Select Bot
            </label>
            <select
              id="bot-name"
              onChange={handleBotChange}
              className=" bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            >
              <option value={12345}>Select Bot</option>
              {botList.map((bot) => (
                <option value={bot.bot_id}>{bot.bot_name}</option>
              ))}
            </select>
          </div>
          <div className="w-full lg:w-3/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Relative Time Range
            </label>
            <select
              onChange={handleRelativeDateChange}
              value={relativeDate}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            >
              <option value={1}>Today</option>
              <option value={2}>Yesterday</option>
              <option value={7}>Last 7 Days</option>
              <option value={14}>Last 2 Weeks</option>
              <option value={30}>Last Month</option>
            </select>
          </div>
          <div className="w-full lg:w-3/12 flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Start Date
            </label>
            <input
              type="date"
              id="start"
              value={startDate}
              onChange={handleDateChange}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            />
          </div>
          <div className="w-full lg:w-3/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              End Date
            </label>
            <input
              type="date"
              id="end"
              value={endDate}
              onChange={handleDateChange}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="py-4">
          <DevBotResultsTable
            bot_id={selectedBot}
            dates={[startDate, endDate]}
          />
        </div>
      </BaseCard>
    </div>
  );
};

export default DevBotResults;
