import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/lay-back-and-win-logo.svg";
import logoNew from "../../assets/Logo Light Blue.png";
import { supabase } from "../../utils/supabaseClient";
import { useUser } from "../../contexts/User.context";
import { getUser } from "../../utils/supabaseClient";
export const Login = () => {
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [session, setSession] = useState(null);

  const { storeUserDetails } = useUser();
  const navigate = useNavigate();
  // s
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      setIncorrectPassword(true);
      setErrorCode(error.message);
    } else {
      const user = await getUser();
      setIncorrectPassword(false);
      storeUserDetails(user).then(() => {
        navigate("/dashboard", { replace: true });
      });
    }
  };

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => setSession(session));

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (session) {
    navigate("/tbb4/dashboard", { replace: true });
  }

  return (
    <section className="relative w-full h-full py-40 min-h-screen">
      <div className="absolute top-0 w-full h-full bg-lbw-primary bg-no-repeat bg-full"></div>
      <div className="container mx-auto px-4 h-full bg-lbw-primary">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-lbw-secondary border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-secondary-text text-center mb-3 font-bold pt-8">
                  <img src={logoNew} alt="logo" className="mx-auto" />
                </div>
                {incorrectPassword ? (
                  <div className="py-2 mb-4 rounded-lg text-center bg-red-300 w-full">
                    <p>Incorrect Password and/or username was entered</p>
                  </div>
                ) : (
                  <></>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-lbw-accent text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-neutral-100 text-primary-text bg-neutral-400 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="joe@email.com"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-lbw-accent text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="border-0 px-3 py-3 placeholder-neutral-200 text-primary-text bg-neutral-500 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                  {/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-lbw-secondary ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-secondary-text">
                        Remember me
                      </span>
                    </label>
                  </div> */}

                  <div className="text-center mt-6">
                    <button
                      className="bg-lbw-secondary-lighter text-lbw-accent active:bg-lbw-secondary text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <div
                  onClick={() => navigate("/login/forgot-password")}
                  className="text-lbw-accent cursor-pointer"
                >
                  <small>Forgot password?</small>
                </div>
              </div>
              <div className="w-1/2 text-right">
                <Link
                  to="https://laybackandwin.com.au"
                  className="text-lbw-accent"
                >
                  <small>Signup for Account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
