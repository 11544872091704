import React, { useEffect, useState } from "react";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import UserResultsTable from "../../components/Members/UserResults/UserResultsTable";
import { useUser } from "../../contexts/User.context";
import {
  HorseIcon,
  HarnessIcon,
  GreyhoundIcon,
  TickIcon,
} from "../../components/ui/Icon";
const UserResults = () => {
  const [selectedBot, setSelectedBot] = useState(null);
  const [relativeDate, setRelativeDate] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [horse, setHorse] = useState(true);
  const [harness, setHarness] = useState(true);
  const [greyhound, setGreygound] = useState(true);

  const [loading, setLoading] = useState(true);

  const { user } = useUser();

  const defaultDates = () => {
    let today = new Date();
    setStartDate(moment(today).format("YYYY-MM-DD"));
    setEndDate(moment(today).format("YYYY-MM-DD"));
  };

  const handleBotChange = (e) => {
    setSelectedBot(e.target.value);
  };

  const handleDateChange = (e) => {
    if (e.target.id === "start") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleRelativeDateChange = (e) => {
    let val = parseInt(e.target.value);
    setRelativeDate(val);
    // console.log(val);
    if (val === 1) {
      defaultDates();
    }
    if (val === 2) {
      // console.log("yesterday");
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setStartDate(moment(yesterday).format("YYYY-MM-DD"));
      setEndDate(moment(yesterday).format("YYYY-MM-DD"));
    } else {
      let today = new Date();
      let fromDate = moment(today)
        .subtract(val - 1, "days")
        .format("YYYY-MM-DD");
      setStartDate(fromDate);
      setEndDate(moment(today).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    defaultDates();
  }, []);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="space-y-6 px-10 max-sm:px-2">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-primary-text text-3xl font-cairoli">Results</h2>
        </div>
      </div>
      <BaseCard>
        <div className="flex max-sm:flex-wrap md:flex-row py-4 gap-x-2">
          <div className="w-full lg:w-3/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Relative Time Range
            </label>
            <select
              onChange={handleRelativeDateChange}
              value={relativeDate}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            >
              <option value={1}>Today</option>
              <option value={2}>Yesterday</option>
              <option value={7}>Last 7 Days</option>
              <option value={14}>Last 2 Weeks</option>
              <option value={30}>Last Month</option>
            </select>
          </div>
          <div className="w-full lg:w-3/12 flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Start Date
            </label>
            <input
              type="date"
              id="start"
              value={startDate}
              onChange={handleDateChange}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            />
          </div>
          <div className="w-full lg:w-3/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              End Date
            </label>
            <input
              type="date"
              id="end"
              value={endDate}
              onChange={handleDateChange}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div className="flex flex-row pt-4">
          <h2 className="font-bold font-cairoli uppercase text-primary-text">
            Race Type Filter
          </h2>
        </div>
        <div className="flex flex-row pb-6 gap-x-10">
          <div className="w1/3">
            <div className="relative w-full">
              <div className="flex gap-x-4 pt-2 justify-center">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={horse}
                    onClick={() => setHorse(!horse)}
                    className="sr-only peer"
                  />
                  <div className="w-6 h-6 font-thin bg-transparent border-2 rounded-full peer-checked:border-0 peer-checked:bg-white"></div>
                  <TickIcon className=" -ml-[2px] hidden peer-checked:text-lbw-accent peer-checked:block absolute font-thin text-[27px] " />
                  <HorseIcon className="ml-3 text-5xl text-primary-text" />
                </label>
              </div>
            </div>
          </div>
          <div className="w1/3">
            <div className="relative w-full">
              <div className="flex gap-x-4 pt-2 justify-center">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={harness}
                    onClick={() => setHarness(!harness)}
                    className="sr-only peer"
                  />
                  <div className="w-6 h-6 font-thin bg-transparent border-2 rounded-full peer-checked:border-0 peer-checked:bg-white"></div>
                  <TickIcon className=" -ml-[2px] hidden peer-checked:text-lbw-accent peer-checked:block absolute font-thin text-[27px] " />
                  <HarnessIcon className="ml-3 text-5xl font-light text-primary-text" />
                </label>
              </div>
            </div>
          </div>
          <div className="w1/3">
            <div className="relative w-full">
              <div className="flex gap-x-4 pt-2 justify-center">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={greyhound}
                    onClick={() => setGreygound(!greyhound)}
                    className="sr-only peer"
                  />
                  <div className="w-6 h-6 font-thin bg-transparent border-2 rounded-full peer-checked:border-0 peer-checked:bg-white"></div>
                  <TickIcon className=" -ml-[2px] hidden peer-checked:text-lbw-accent peer-checked:block absolute font-thin text-[27px] " />
                  <GreyhoundIcon className="ml-3 text-5xl font-light text-primary-text" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-lbw-accent" />
        <div className="py-0">
          <UserResultsTable
            dates={[startDate, endDate]}
            user={user}
            greyhound={greyhound}
            horse={horse}
            harness={harness}
          />
        </div>
      </BaseCard>
    </div>
  );
};

export default UserResults;
