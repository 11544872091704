import React, { useState } from "react";
import PuntingFormData from "../../components/Admin/PuntingForm/PuntingFormData";
import AllRaces from "../../components/Admin/PuntingForm/AllRaces";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import { Tabs, TabsTrigger, TabsList } from "../../components/ui/tabs";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectContent,
} from "../../components/ui/select";
import axios from "axios";
import { Button } from "../../components/ui/button";
import { RefreshCwIcon } from "lucide-react";

const PuntingForm = () => {
  const [baseDate, setBaseDate] = useState(0);
  const [baseData, setBaseData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPageTab, setSelectedPageTab] = useState("top");
  const [selectedTab, setSelectedTab] = useState("botd");
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [hideRun, setHideRun] = useState(false);

  const getBaseData = async () => {
    const { data, error } = await supabase
      .from("punting_form_lb_data")
      .select("*")
      .gte(
        "meeting_datestamp",
        moment().add(baseDate, "days").format("YYYY-MM-DD")
      )
      .lte(
        "meeting_datestamp",
        moment()
          .add(baseDate + 1, "days")
          .format("YYYY-MM-DD")
      );

    // .eq("neural_rank", 1);

    if (error) {
      console.log(error);
    } else {
      for (let i = 0; i < data.length; i++) {
        const backUnits =
          Math.ceil(data[i].price_jump / data[i].neural_price) > 6
            ? 6
            : Math.ceil(data[i].price_jump / data[i].neural_price);
        const layUnits =
          Math.ceil(data[i].neural_price / data[i].price_jump) > 6
            ? 6
            : Math.ceil(data[i].neural_price / data[i].price_jump);
        data[i].backSingle = data[i].result
          ? data[i].result === "WINNER"
            ? data[i].price_jump * 1 - 1
            : -1 * 1
          : 0;
        data[i].backProfit = data[i].result
          ? data[i].result === "WINNER"
            ? data[i].price_jump * backUnits - backUnits
            : -1 * backUnits
          : 0;

        data[i].layProfit = data[i].result
          ? data[i].result !== "WINNER"
            ? layUnits
            : data[i].price_jump * -layUnits + layUnits
          : 0;
        data[i].laySingle = data[i].result
          ? data[i].result !== "WINNER"
            ? 1
            : data[i].price_jump * -1 + 1
          : 0;
      }
      const uniqueStartTimes = [
        ...new Set(data.map((item) => item.start_time)),
      ];

      // Create new key called lbw_score_rank that is based on highest lbw_score to lowest grouped by uniquestartimes
      for (let i = 0; i < uniqueStartTimes.length; i++) {
        const uniqueStartTimesData = data.filter(
          (data) => data.start_time === uniqueStartTimes[i]
        );
        const sortedData = uniqueStartTimesData.sort(
          (a, b) => b.lbw_rank - a.lbw_rank
        );
        for (let j = 0; j < sortedData.length; j++) {
          sortedData[j].lbw_score_rank = j + 1;
          // add horse count
          sortedData[j].horse_count = sortedData.length;
        }
      }

      setBaseData(data);
      setFilteredData(data);
      // Filter venues for unique values
      const uniqueVenues = [
        ...new Set(data.map((item) => item.meeting_track_name)),
      ];
      setVenues(uniqueVenues);
    }
  };

  const filterAlreadyRun = () => {
    if (hideRun) {
      setFilteredData(baseData.filter((data) => data.result === null));
    } else {
      setFilteredData(baseData);
    }
    setHideRun(!hideRun);
  };

  React.useEffect(() => {
    getBaseData();
  }, []);

  React.useEffect(() => {
    if (selectedTab === "ber") {
      setFilteredData(baseData.filter((data) => data.neural_rank === 1));
      setTabData(baseData.filter((data) => data.neural_rank === 1));
    } else if (selectedTab === "botd") {
      const botdData = baseData.filter(
        (data) =>
          data.lbw_rank > 95 &&
          (data.neural_rank === 1 || data.l600_rank === 1) &&
          (data.weight_class_ranl === 1 ||
            data.time_adjusted_weight_class_rank === 1) &&
          data.l600_rank !== null &&
          data.price_latest < 20 &&
          data.horse_count > 7 &&
          data.reliable === true &&
          data.price_latest > data.neural_price
      );

      setFilteredData(botdData);
      setTabData(botdData);
    } else if (selectedTab === "lotd") {
      const lotdData = baseData.filter(
        (data) =>
          data.neural_rank > 3 &&
          data.l600_rank > 3 &&
          data.l600_rank < 25 &&
          data.l600_rank !== null &&
          data.weight_class_ranl > 3 &&
          data.time_adjusted_weight_class_rank > 3 &&
          data.lbw_rank < 70 &&
          data.favourite === true
      );
      setFilteredData(lotdData);
      setTabData(lotdData);
    } else if (selectedTab === "votd") {
      const votdData = baseData.filter(
        (data) =>
          data.neural_rank <= 3 &&
          data.lbw_rank > 90 &&
          data.l600_rank !== null &&
          data.l600_rank <= 3 &&
          data.weight_class_ranl <= 3 &&
          data.time_adjusted_weight_class_rank <= 3 &&
          data.price_latest > 8 &&
          data.price_latest < 40
      );
      setFilteredData(votdData);
      setTabData(votdData);
    }
  }, [selectedTab, baseData]);

  React.useEffect(() => {
    getBaseData();
  }, [baseDate]);

  React.useEffect(() => {
    if (selectedVenue) {
      setFilteredData(
        tabData.filter((data) => data.meeting_track_name === selectedVenue)
      );
    }
  }, [selectedVenue]);

  return (
    <div className="py-20 gap-y-4 flex flex-col font-black px-10 max-sm:px-2">
      <div className="flex flex-col gap-y-4 pb-10">
        <div className="flex flex-row gap-x-4 w-full">
          <div className="flex w-1/3 flex-row">
            <Tabs
              defaultValue="0"
              onValueChange={(e) => {
                setBaseDate(parseInt(e));
              }}
              className="w-full bg-lbw-secondary-lighter"
            >
              <TabsList className="grid w-full bg-lbw-secondary-lighter grid-cols-3">
                <TabsTrigger
                  // className="data-[state=active]:bg-green-500"
                  value="-1"
                >
                  Yesterday
                </TabsTrigger>
                <TabsTrigger
                  // className="data-[state=active]:bg-red-500"
                  value="0"
                >
                  Today
                </TabsTrigger>
                <TabsTrigger
                  // className="data-[state=active]:bg-red-500"
                  value="1"
                >
                  Tomorrow
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          <div className="flex w-2/3 flex-row">
            <Tabs
              defaultValue="top"
              onValueChange={(e) => {
                setSelectedPageTab(e);
              }}
              className="w-full bg-lbw-secondary-lighter"
            >
              <TabsList className="grid w-full  grid-cols-2">
                <TabsTrigger
                  // className="data-[state=active]:bg-green-500"
                  value="top"
                >
                  Top Selections
                </TabsTrigger>
                <TabsTrigger
                  // className="data-[state=active]:bg-red-500"
                  value="all"
                >
                  All Races
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>
      </div>

      {selectedPageTab === "top" ? (
        <>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row">
              <Tabs
                defaultValue="botd"
                onValueChange={(e) => {
                  setSelectedTab(e);
                }}
                className="w-full "
              >
                <TabsList className="grid w-full  grid-cols-4 rounded-xl">
                  <TabsTrigger
                    // className="data-[state=active]:bg-red-500"
                    value="botd"
                  >
                    Backs of The Day
                  </TabsTrigger>
                  <TabsTrigger
                    // className="data-[state=active]:bg-red-500"
                    value="lotd"
                  >
                    Lays of The Day
                  </TabsTrigger>
                  <TabsTrigger
                    // className="data-[state=active]:bg-green-500"
                    value="votd"
                  >
                    Value Bets
                  </TabsTrigger>
                  <TabsTrigger
                    // className="data-[state=active]:bg-green-500"
                    value="ber"
                  >
                    Best Of Each Race
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            <div className="flex flex-row justify-end text-primary-text gap-x-4">
              <div className="">
                <Button
                  variant="secondary"
                  className="gap-x-4"
                  onClick={() => getBaseData()}
                >
                  <RefreshCwIcon size={24} />
                  Refresh
                </Button>
              </div>
            </div>
          </div>

          <BaseCard>
            <PuntingFormData pfData={filteredData} tab={selectedTab} />
          </BaseCard>
        </>
      ) : (
        <BaseCard>
          <AllRaces data={baseData} venues={venues} />
        </BaseCard>
      )}
    </div>
  );
};

export default PuntingForm;
