import { supabase } from "./supabaseClient";
import moment from "moment";

export const updateUser = (user) => {
  // console.log(user);
};

// This will get the users upcoming races that matches there subsccription type
export const getUsersUpcomingRaces = async (user) => {
  let tbb4_setting_id = 1;
  // Assign package setting id
  if (user.tbb4_package === 1) {
    tbb4_setting_id = 2;
  } else if (user.tbb4_package === 2) {
    tbb4_setting_id = 1;
  }

  // Find what races are available
  // dependant on the package settings
  if (user.tbb4_package === 5) {
    const { data: excludedRaces, error: excludedRacesError } = await supabase
      .from("excluded_races_and_venues")
      .select("market_id");
    let excludedRacesArray = excludedRaces.map((item) => item.market_id);

    const now = moment().utc().format();
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gt("market_start_time", now)
      .eq("race_type", "Thoroughbred")
      .not("market_id", "in", `(${excludedRacesArray.toString()})`)
      .order("market_start_time", { ascending: true })
      .limit(4);
    const data = rawData.data;

    let updatedMarketData = data.map((item) => {
      let now = moment().utc();
      let raceTime = moment(item.market_start_time).utc();
      let diff = raceTime.diff(now, "seconds");
      let minDiff = raceTime.diff(now, "minutes");
      if (diff < 60) {
        return {
          ...item,
          timeTilJump: `${diff}s`,
          redTime: true,
          diff: diff,
        };
      } else if (diff > 60 && diff < 300) {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: true,
          diff: diff,
        };
      } else {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: false,
          diff: diff,
        };
      }
    });
    return updatedMarketData;
  } else if (user.tbb4_package !== 3 && user.tbb4_package !== 4) {
    const s = await supabase
      .from("tbb4_master_settings")
      .select("*")
      .eq("id", tbb4_setting_id);
    const botSettings = s.data[0];
    // Filter race types
    let raceTypes = [
      botSettings.horse ? "Thoroughbred" : null,
      botSettings.greyhound ? "Greyhound" : null,
      botSettings.harness ? "Harness" : null,
    ].filter((rt) => rt !== null);

    const countries = [
      botSettings.aus === true ? "AUS" : null,
      botSettings.nz === true ? "NZ" : null,
    ];

    // Get Racing data
    const now = moment().utc().format();
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gt("market_start_time", now)
      .in("race_type", raceTypes)
      .in("country", countries)
      .eq("starter_package_include", true)
      .eq("tbb4_excluded", false)
      .order("market_start_time", { ascending: true })
      .limit(4);
    const data = rawData.data;

    let updatedMarketData = data.map((item) => {
      let now = moment().utc();
      let raceTime = moment(item.market_start_time).utc();
      let diff = raceTime.diff(now, "seconds");
      let minDiff = raceTime.diff(now, "minutes");
      if (diff < 60) {
        return {
          ...item,
          timeTilJump: `${diff}s`,
          redTime: true,
          diff: diff,
        };
      } else if (diff > 60 && diff < 300) {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: true,
          diff: diff,
        };
      } else {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: false,
          diff: diff,
        };
      }
    });

    // console.log(data);
    return updatedMarketData;
  } else {
    const twoDaysAgo = moment().subtract(2, "days").utc().format();
    const now = moment().utc().format();
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .select("*")
      .eq("user_id", user.auth_id);

    const strats = data;
    const bettingSystem = await supabase
      .from("user_betting_system")
      .select("*")
      .eq("auth_id", user.auth_id);

    const trend = bettingSystem.data[0].system_id === 2;
    var trendRaces = [];
    if (trend === true) {
      const trendR = await supabase
        .from("betfair_market_data")
        .select("*")
        .eq("starter_package_include", true)
        .eq("tbb4_excluded", false)
        .gte("market_start_time", now)
        .order("market_start_time", { ascending: true })
        .limit(4);
      trendRaces = trendR.data;
    }
    // console.log(settings);

    let raceTypes = [
      strats.filter(
        (s) =>
          s.race_type === "Thoroughbred" && s.active === true && trend === false
      ).length !== 0
        ? "Thoroughbred"
        : null,
      strats.filter((s) => s.race_type === "Greyhound" && s.active === true)
        .length !== 0
        ? "Greyhound"
        : null,
      strats.filter((s) => s.race_type === "Harness" && s.active === true)
        .length !== 0
        ? "Harness"
        : null,
    ].filter((rt) => rt !== null);
    // console.log("racetypes",raceTypes);
    let countries = [
      strats.filter((s) => s.aus === true && s.active === true).length !== 0
        ? "AUS"
        : null,
      strats.filter((s) => s.nz === true && s.active === true).length !== 0
        ? "NZ"
        : null,
    ].filter((rt) => rt !== null);
    // console.log("countries",countries);

    const overrides = await supabase
      .from("cl_race_and_venue_exclusion")
      .select("*")
      .eq("user_id", user.ud_id)
      .gte("created_at", twoDaysAgo);

    // console.log(overrides.data)
    const excludedRaces = overrides.data
      .filter((item) => item.excluded === true)
      .map((item) => item.market_id);
    // console.log(excludedRaces);
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gte("market_start_time", now)
      .in("race_type", raceTypes)
      .not(
        "market_id",
        "in",
        `(${excludedRaces.map((item) => `${item}`).join(",")})`
      )
      .in("country", countries)
      .order("market_start_time", { ascending: true })
      .limit(5);
    // console.log(rawData);
    // add them and sort and only do 5
    const sortedRaces = [...trendRaces, ...rawData.data]
      .sort((a, b) => {
        return moment(a.market_start_time).diff(moment(b.market_start_time));
      })
      .slice(0, 4);

    const races = rawData.data;

    // console.log(races);
    let updatedMarketData = sortedRaces.map((item) => {
      let now = moment().utc();
      let raceTime = moment(item.market_start_time).utc();
      let diff = raceTime.diff(now, "seconds");
      let minDiff = raceTime.diff(now, "minutes");
      if (diff < 60) {
        return {
          ...item,
          timeTilJump: `${diff}s`,
          redTime: true,
          diff: diff,
        };
      } else if (diff > 60 && diff < 300) {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: true,
          diff: diff,
        };
      } else {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: false,
          diff: diff,
        };
      }
    });

    return updatedMarketData;
  }
};
