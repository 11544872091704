import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableFooter,
} from "../../ui/table";
import { Switch } from "../../ui/switch";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogClose,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Tabs, TabsTrigger, TabsList } from "../../ui/tabs";
import moment from "moment";
import axios from "axios";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { invokeFunction } from "../../../utils/supabaseClient";

const PuntingFormData = ({ pfData, tab, hideAlreadyRun }) => {
  const [total, setTotal] = useState(0);
  const [totalSingle, setTotalSingle] = useState(0);

  let sum = 0;
  let sumSingle = 0;
  useEffect(() => {
    pfData.map((data) => {
      tab !== "lotd" ? (sum += data.backProfit) : (sum += data.layProfit);
      tab !== "lotd"
        ? (sumSingle += data.backSingle)
        : (sumSingle += data.laySingle);
    });
    setTotalSingle(sumSingle);
    setTotal(sum);
  }, [pfData]);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Jump Time</TableHead>
          <TableHead>Race</TableHead>
          <TableHead>Horse</TableHead>
          <TableHead>LBW Score</TableHead>
          <TableHead>LBW Ranking</TableHead>
          <TableHead>LBW Price</TableHead>
          {/* <TableHead>WC </TableHead>
          <TableHead>TA WC</TableHead>
          <TableHead>Cls Diff</TableHead> */}
          <TableHead>Price</TableHead>
          <TableHead>Result</TableHead>
          <TableHead>Units(Beta)</TableHead>
          <TableHead></TableHead>
          {/* <TableHead>Weight/Class Rank</TableHead> 
           <TableHead>Time Adjusted Weight/Class</TableHead> 
          <TableHead>Class Difference</TableHead> */}
        </TableRow>
      </TableHeader>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={8}>Total</TableCell>
          <TableCell className="text-right">
            {total.toFixed(2)}({totalSingle.toFixed(2)})
          </TableCell>
        </TableRow>
      </TableFooter>
      <TableBody>
        {pfData.map((data, index) => (
          <TableRow key={index}>
            <TableCell>
              {moment(data.start_time).format("hh:mma")} <br />{" "}
            </TableCell>
            <TableCell>
              {data.meeting_track_name} - R{data.raceno}
            </TableCell>
            <TableCell className="flex flex-row items-center">
              <img
                className="w-8 h-8 mr-2 bg-transparent"
                src={`https://content.betfair.com/feeds_images/Horses/SilkColours/${data.colors_file_name}`}
                alt={data.pf_horsename}
              />
              {data.pf_horse_number}. {data.pf_horsename}{" "}
              {tab === "ber" &&
                data.lbw_rank > 85 &&
                data.neural_rank === 1 &&
                data.price_latest > data.neural_price && (
                  <span className="bg-green-600 text-primary-text p-1 ml-1 rounded-md">
                    Back
                  </span>
                )}
            </TableCell>
            <TableCell>{data.lbw_rank}</TableCell>
            <TableCell>{data.neural_rank}</TableCell>
            <TableCell>${data.neural_price.toFixed(2)}</TableCell>
            {/* <TableCell>{data.weight_class_ranl}</TableCell>
            <TableCell>{data.time_adjusted_weight_class}</TableCell>
            <TableCell>{data.clsdiff}</TableCell> */}
            <TableCell>
              <span className="flex-col flex">
                <span>
                  {data.price_latest
                    ? `W:$${data.price_latest.toFixed(2)}`
                    : ""}
                </span>
                <span>
                  {data.place_latest
                    ? `P:$${data.place_latest.toFixed(2)}`
                    : ""}
                </span>
              </span>
              {/* <LiveOdds data={data} /> */}
            </TableCell>

            <TableCell>
              {data.lb_result && (
                <span
                  className={`ml-1 ${
                    data.lb_result === "WINNER"
                      ? "bg-green-600"
                      : data.lb_result === "PLACE"
                      ? "bg-orange-400"
                      : data.lb_result === "LOSER"
                      ? "bg-red-600"
                      : ""
                  } p-1 rounded-md text-xs font-bold`}
                >
                  {data.lb_result
                    ? data.lb_result
                    : data.result
                    ? data.result
                    : ""}
                </span>
              )}
            </TableCell>

            <TableCell>
              {tab === "lotd"
                ? Math.ceil(data.neural_price / data.price_latest) > 6
                  ? 6
                  : Math.ceil(data.neural_price / data.price_latest)
                : Math.ceil(data.price_latest / data.neural_price) > 6
                ? 6
                : Math.ceil(data.price_latest / data.neural_price)}
            </TableCell>
            <TableCell>
              {moment(data.start_time) > moment().subtract(2, "minute") ? (
                <PlaceOrderDialog data={data} />
              ) : (
                <></>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const LiveOdds = ({ data }) => {
  const [odds, setOdds] = useState(0);
  const getOdds = async (event_id, runner_number) => {
    try {
      const data = await invokeFunction("ladbrokes_odds", {
        event_id: event_id,
      });
      // console.log(data);
      const selections = data.data.runners;
      // console.log(selections);
      const horse = selections.find(
        (runner) => runner.runner_number === runner_number
      );
      setOdds(horse.odds.fixed_win);
      return horse.odds.fixed_win;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOdds(data.event_id, data.pf_horse_number);
  }, []);

  return <>${data.price_jump ? data.price_jump.toFixed(2) : odds.toFixed(2)}</>;
};

const PlaceOrderDialog = ({ data, side }) => {
  const [orderType, setOrderType] = useState(side ? side : "back");
  const [betfairSp, setBetfairSp] = useState(true);
  const [price, setPrice] = useState(
    data.price_jump
      ? data.price_jump * 1.1
      : data.price_11am
      ? data.price_11am * 1.1
      : data.price_9am
      ? data.price_9am * 1.1
      : 1
  );
  const [lbPrice, setLbPrice] = useState(
    data.price_jump
      ? data.price_jump
      : data.price_11am
      ? data.price_11am
      : data.price_9am
      ? data.price_9am
      : 0
  );
  const [stake, setStake] = useState(1);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Place Order</Button>
      </DialogTrigger>
      <DialogContent className="bg-lbw-secondary border-lbw-secondary-lighter text-primary-text">
        <div className="flex flex-col space-y-6 pr-10">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <img
                className="w-8 h-8 mr-2 bg-transparent"
                src={`https://content.betfair.com/feeds_images/Horses/SilkColours/${data.colors_file_name}`}
                alt={data.pf_horsename}
              />
              {data.pf_horse_number}. {data.pf_horsename}{" "}
            </div>
            <div>${lbPrice.toFixed(2)}</div>
          </div>
          <div>
            <Tabs
              value={orderType}
              onValueChange={(e) => setOrderType(e)}
              className="w-full bg-lbw-secondary"
            >
              <TabsList className="grid w-full bg-lbw-primary grid-cols-2">
                <TabsTrigger
                  className="data-[state=active]:bg-green-500"
                  value="back"
                >
                  Back
                </TabsTrigger>
                <TabsTrigger
                  className="data-[state=active]:bg-red-500"
                  value="lay"
                >
                  Lay
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          <div className="flex flex-row gap-x-2 justify-between">
            <div className="flex flex-col gap-y-2">
              <Label className="pl-2">Price</Label>
              <Input
                className=""
                type="number"
                // value={price}
                onChange={(e) => setPrice(parseFloat(e.target.value))}
                placeholder={`${price.toFixed(2)}`}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label className="pl-2">Stake</Label>
              <div className="flex flex-col gap-x-4 items-center">
                <Input
                  className=""
                  value={stake}
                  onChange={(e) => setStake(parseFloat(e.target.value))}
                  type="number"
                  placeholder="Stake"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <Label>
              Liability:{" "}
              {orderType === "back"
                ? stake.toFixed(2)
                : !isNaN(stake) && !isNaN(price)
                ? (stake * price - stake).toFixed(2)
                : ""}
            </Label>
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <Switch
              checked={betfairSp}
              onCheckedChange={() => setBetfairSp(!betfairSp)}
              id="airplane-mode"
            />
            <Label htmlFor="airplane-mode">Betfair SP on closed</Label>
          </div>
          <div className="flex flex-col gap-y-2">
            <DialogClose asChild>
              <Button type="button" disabled variant="default">
                Place Order
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PuntingFormData;
