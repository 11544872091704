import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabaseClient";
import moment from "moment";
// import stringify from "csv-stringify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { HorseIcon, HarnessIcon, GreyhoundIcon } from "../../ui/Icon";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "../../ui/table";

const UserResultsTable = ({ dates, user, horse, greyhound, harness }) => {
  const [botResults, setBotResults] = useState([]);
  const [filterUnmatched, setFilterUnmatched] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [matchedCount, setMatchedCount] = useState(0);
  const [layCount, setLayCount] = useState(0);
  const [backCount, setBackCount] = useState(0);
  const [winCount, setWinCount] = useState(0);
  const [layProfit, setLayProfit] = useState(0);
  const [backProfit, setBackProfit] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);

  const getBotResults = async () => {
    const { data, error } = await supabase
      .from("order_results_details")
      .select("*")
      .eq("user_id", user.ud_id)
      .gte(
        "order_placed_time",
        moment(dates[0]).format("YYYY-MM-DDT00:00:00.000+10:00:00")
      )
      .lte(
        "order_placed_time",
        moment(dates[1]).format("YYYY-MM-DDT23:59:59.000+10:00:00")
      )
      .order("order_placed_time", { ascending: false })
      .limit(10000);
    console.log(data);
    if (error) {
      console.error(error);
    } else {
      const raceTypeFilter = [
        horse ? "Thoroughbred" : null,
        greyhound ? "Greyhound" : null,
        harness ? "Harness" : null,
      ];
      if (filterUnmatched === true) {
        setBotResults(
          data.filter(
            (item) =>
              item.event_name !== null &&
              raceTypeFilter.includes(item.race_type)
          )
        );
      } else {
        setBotResults(
          data.filter((item) => raceTypeFilter.includes(item.race_type))
        );
      }
    }
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const d = botResults.map((item) => ({
      Date: moment(item.order_placed_time).format("ddd DD MMM"),
      time: moment(item.order_placed_time).format("hh:mm A"),
      Race: item.race_name,
      distance: item.market_name.split(" ")[1],
      Selection: item.selection_name,
      Side: item.side,
      Percentage: item.percentage,
      Result: item.result,
      Profit: item.profit_with_comm,
      Stake: item.order_stake,
      Price: item.order_price,
    }));
    const ws = XLSX.utils.json_to_sheet(d);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `LBW Results - ${moment(dates[0]).format(
      "DD MMM YYYY"
    )} - ${moment(dates[1]).format("DD MMM YYYY")} ${fileExtension}`;
    FileSaver.saveAs(data, fileName);
  };

  const refreshData = () => {
    getBotResults();
  };

  const filterUnmatchedRaces = (filter) => {
    if (filterUnmatched === true) {
      setBotResults(botResults.filter((item) => item.event_name !== null));
    } else {
      getBotResults();
    }
    setFilterUnmatched(filterUnmatched ? false : true);
  };

  useEffect(() => {
    getBotResults().then(() => {
      if (filterUnmatched === true) {
        setBotResults(botResults.filter((item) => item.event_name !== null));
      }
    });
  }, [dates]);

  useEffect(() => {
    if (botResults.length > 0) {
      setOrderCount(botResults.length);
      setLayCount(
        botResults.filter(
          (item) => item.side === "LAY" && item.event_name !== null
        ).length
      );
      setMatchedCount(
        botResults.filter((item) => item.event_name !== null).length
      );
      setBackCount(
        botResults.filter(
          (item) => item.side === "BACK" && item.event_name !== null
        ).length
      );
      setWinCount(
        botResults.filter(
          (item) => item.result === "WON" && item.event_name !== null
        ).length
      );
      setLayProfit(
        botResults
          .filter((item) => item.side === "LAY")
          .reduce((a, b) => a + b.profit_with_comm, 0)
      );
      setBackProfit(
        botResults
          .filter((item) => item.side === "BACK")
          .reduce((a, b) => a + b.profit_with_comm, 0)
      );
      setTotalProfit(botResults.reduce((a, b) => a + b.profit_with_comm, 0));
    }
  }, [botResults]);

  useEffect(() => {
    getBotResults();
  }, [greyhound, horse, harness]);
  return (
    <div className=" bg-lbw-secondary space-y-4 rounded-md">
      {botResults?.length > 0 ? (
        <div className="py-4 flex flex-wrap w-full justify-center">
          <div className="w-full px-4 max-sm:py-0 py-4">
            <div className="flex text-center max-sm:flex-wrap md:flex-row w-full max-sm:justify-evenly justify-between">
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Orders</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  {orderCount}
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Matched</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  {matchedCount} (
                  {((matchedCount / orderCount) * 100).toFixed(2)}
                  %)
                </p>
              </div>
              <div className="max-sm:hidden lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Lays</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  {layCount} ({((layCount / matchedCount) * 100).toFixed(2)}%)
                </p>
              </div>
              <div className="max-sm:hidden lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Backs</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  {backCount} ({((backCount / matchedCount) * 100).toFixed(2)}%)
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Wins</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  {winCount} ({((winCount / matchedCount) * 100).toFixed(2)}%)
                </p>
              </div>
            </div>
            <div className="flex text-center max-sm:flex-wrap md:flex-row w-full justify-center">
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Lay Profit</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  ${layProfit.toFixed(2)}
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Back Profit</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  ${backProfit.toFixed(2)}
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-primary-text my-auto">Total Profit</p>
                <p className="max-sm:text-lg text-3xl text-primary-text font-bold">
                  ${totalProfit.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full flex max-sm:flex-col-reverse max-sm:gap-y-4 flex-row
           px-4 py-4"
          >
            <div className="max-sm:w-full w-1/6">
              <button
                className="bg-lbw-accent float-right mt-auto text-primary-text max-sm:w-full  active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={refreshData}
              >
                Refresh Results
              </button>
            </div>
            <div className="max-sm:w-full w-1/6">
              <button
                className="bg-lbw-accent float-right mt-auto max-sm:w-full  text-primary-text active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={filterUnmatchedRaces}
              >
                {filterUnmatched ? "Hide Unmatched" : "Show Unmatched"}
              </button>
            </div>
            <div className="max-sm:w-full w-4/6 float-right">
              <button
                className="bg-lbw-accent max-sm:w-full  float-right mt-auto text-primary-text active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={exportToCSV}
              >
                Download Excel
              </button>
            </div>
          </div>
          <div className="w-full px-4 overflow-x-auto">
            {/* <button
              className="bg-green-900 text-primary-text active:bg-green-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
            >
              Export to CSV
            </button> */}
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>#</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Race</TableHead>
                  <TableHead>Selection</TableHead>
                  <TableHead>Side</TableHead>
                  <TableHead>
                    {window.screen.width < 768 ? "Perc" : "Percentage"}
                  </TableHead>
                  <TableHead>Price</TableHead>

                  <TableHead>Profit</TableHead>
                  <TableHead>Stake</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {botResults.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {moment(item.order_placed_time).format("ddd DD MMM")}
                    </TableCell>
                    <TableCell>
                      {item.race_type === "Thoroughbred" ? (
                        <HorseIcon
                          className={
                            "text-primary-text font-light text-[20px] mr-2"
                          }
                        />
                      ) : item.race_type === "Harness" ? (
                        <HarnessIcon
                          className={
                            "text-primary-text font-light text-[20px] mr-2"
                          }
                        />
                      ) : (
                        <GreyhoundIcon
                          className={
                            "text-primary-text font-light text-[20px] mr-2"
                          }
                        />
                      )}
                      {item.race_name}
                    </TableCell>
                    <TableCell>{item.selection_name}</TableCell>
                    <TableCell>{item.side}</TableCell>
                    <TableCell>
                      {item.percentage ? item.percentage.toFixed(2) + "%" : ""}
                    </TableCell>
                    <TableCell
                      className={`max-sm:hidden ${
                        item.order_price === 5
                          ? "text-orange-500"
                          : "text-primary-text"
                      }`}
                    >
                      ${item.order_price.toFixed(2)}
                    </TableCell>
                    <TableCell
                      className={`${
                        item.profit > 0
                          ? "text-green-500"
                          : !item.profit
                          ? "text-primary-text"
                          : "text-red-500"
                      } `}
                    >
                      {item.profit_with_comm
                        ? "$" + item.profit_with_comm.toFixed(2)
                        : window.screen.width < 768
                        ? ""
                        : "UNMATCHED"}
                    </TableCell>
                    <TableCell>${item.order_stake.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserResultsTable;
