import React from "react";
import SimulatorSettings from "../../components/Admin/BackTestingSimulator/SimlatorSettings";
import SimulationNotes from "../../components/Admin/BackTestingSimulator/SimulationNotes";
import SimulatorContext from "../../contexts/BackTesting.context";
import ProfitLossGraph from "../../components/Admin/BackTestingSimulator/ProfitLossGraph";
import BaseCard from "../../components/ui/BaseCard";
import ResultsStats from "../../components/Admin/BackTestingSimulator/ResultsStats";

const BackTesting = () => {
  return (
    <SimulatorContext.Provider value={{ maxLay: 0 }}>
      <div className="h-full bg-lbw-primary py-6">
        <div className="flex flex-row gap-x-4">
          <div className="w-3/4 mb-12 xl:mb-0 pb-4 space-y-4">
            <SimulatorSettings />
          </div>
          <div className="w-1/4 mb-12 xl:mb-0 space-y-4">
            <SimulationNotes />
          </div>
        </div>
        <div className="flex flex-row gap-x-4 pb-4">
          <div className="w-full">
            <BaseCard>
              <ProfitLossGraph />
            </BaseCard>
          </div>
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="w-full">
            <BaseCard>
              <ResultsStats />
            </BaseCard>
          </div>
        </div>
      </div>
    </SimulatorContext.Provider>
  );
};

export default BackTesting;
