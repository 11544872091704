/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { betfairAccountRequest } from "../../utils/betfair";
import logoNew from "../../assets/Logo Light Blue.png";
import greenCircle from "../../assets/gifs/green-circle.gif";
import redCircle from "../../assets/gifs/red-circle.gif";
import { supabase } from "../../utils/supabaseClient";
import { useUser } from "../../contexts/User.context";
import {
  FileSliders,
  FlaskConical,
  Gauge,
  Home,
  LayoutDashboard,
  ScatterChart,
  Settings2,
  Trophy,
} from "lucide-react";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = useState("hidden");
  const [admin, setAdmin] = useState(false);
  const [balance, setBalance] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [botStatus, setBotStatus] = useState({
    starter: {
      active: false,
    },
    intermediate: {
      active: false,
    },
  });

  const { pathname } = useLocation();
  const withoutSidebarRoutes = ["/login", "/register"];
  const activeItemClass = "bg-lbw-secondary-lighter rounded-lg";

  // Get user from context that was stored in context
  const { user, refreshUser } = useUser();

  if (withoutSidebarRoutes.some((item) => pathname.includes(item)))
    return children;

  const logOut = async () => {
    const { error } = await supabase.auth.signOut();
  };

  const getBalance = async () => {
    // console.log("user", user);
    let accessToken = user.betfair_access_token;
    let data = {
      wallet: "UK",
    };
    if (accessToken !== undefined || null) {
      let result = await betfairAccountRequest(
        accessToken,
        "getAccountFunds",
        data
      );
      // console.log("Balance Request", result.data);
      if (result.data.error?.code) {
        // console.log("Error", result.data.error);
        refreshUser();
        //   const refreshToken = await invokeFunction(
        //     "refreshCustomerTokenManual",
        //     {
        //       user: user,
        //     }
        //   );
        //   console.log("refreshToken", refreshToken);
      }
      return result.data.result;
    } else {
      return null;
    }
  };

  const refreshBalance = () => {
    setBalance(0);
    getBalance()
      .then((res) => setBalance(res.availableToBetBalance))
      .catch((error) => {
        // console.log(error);
      });
  };

  const getBotStatus = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .select("*");

    if (data) {
      setBotStatus({
        starter: {
          active: data.filter((item) => item.id === 2)[0].active,
        },
        intermediate: {
          active: data.filter((item) => item.id === 1)[0].active,
        },
      });
    }
  };

  useEffect(() => {
    refreshUser();
    getBotStatus();
    getBalance();
  }, []);

  useEffect(() => {
    getBalance()
      .then((res) => setBalance(res.availableToBetBalance))
      .catch((error) => {
        // console.log(error);
      });
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      getBalance()
        .then((res) => setBalance(res.availableToBetBalance))
        .catch((error) => {
          // console.log(error);
        });
      getBotStatus();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {user ? (
        <nav className="md:left-0  md:block max-sm:z-30 md:fixed md:top-0 md:bottom-0 scroller md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-lg shadow-lbw-secondary-lighter bg-lbw-secondary flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-6">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex bg-lbw-secondary flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-primary-text opacity-50 z md:hidden px-3 py-1 text-xl leading-none rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow("bg-lbw-primary py-3 px-2")}
            >
              <i className="fas fa-bars"></i>
            </button>
            {/* Brand */}
            <Link
              className="md:block text-left md:pb-2 text-primary-text mr-0 inline-block whitespace-nowrap text-sm uppercase font-cairoli p-4 px-0"
              to="/"
              onClick={() => setCollapseShow("hidden")}
            >
              <img src={logoNew} alt="logo" className="w-32 md:w-48" />
            </Link>
            {/* Collapse */}
            <div
              className={
                "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                collapseShow
              }
            >
              {/* Collapse header */}
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link
                      className="md:block text-left md:pb-2 text-primary-text mr-0 inline-block whitespace-nowrap text-sm uppercase font-cairoli p-4 px-0"
                      to="/"
                    >
                      <img src={logoNew} alt="logo" className="w-52 md:w-24" />
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-primary-text md:hidden px-3 py-1 text-xl leading-none rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="md:hidden max-sm:block align-middle text-secondary-text text-s uppercase font-cairoli block py-2 no-underline">
                <span>Betfair Balance: ${balance}</span>
                <i
                  onClick={refreshBalance}
                  className=" pl-1 fas fa-arrows-rotate mr-2 text-sm cursor-pointer"
                />
              </div>
              {user?.tbb4_package === 3 || user?.tbb4_package === 4 ? (
                <></>
              ) : (
                <>
                  <hr className="my-4 border-0 md:min-w-full" />

                  <h6 className="md:min-w-full text-lbw-accent text-s uppercase font-cairoli block pt-1 pb-4 no-underline">
                    Members
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase py-3 font-cairoli block ${
                          pathname.includes("tbb4/dashboard")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/dashboard"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa fa-gauge text-lbw-primary mr-2 px-2 text-sm"></i>{" "}
                        Dashboard
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-lbw-accent px-2 hover:text-secondary-text text-s uppercase py-3 font-cairoli block ${
                          pathname.includes("/tbb4/results")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/results"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-simple text-secondary-text mr-4 text-sm"></i>{" "}
                        Results
                      </Link>
                    </li>

                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase py-3 font-cairoli block ${
                          pathname.includes("/tbb4/dashboard/settings")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/dashboard/settings"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fas fa-tools text-secondary-text mr-2 text-sm"></i>{" "}
                        Your Settings
                      </Link>
                    </li>
                  </ul>
                </>
              )}
              {user?.tbb4_package === 3 ||
              user?.tbb4_package === 4 ||
              user?.tbb4_package === 5 ? (
                <>
                  <hr className="my-4 border-0 md:min-w-full " />

                  <h6 className="md:min-w-full text-lbw-accent text-s uppercase font-cairoli block pt-1 pb-4 no-underline">
                    Captains Lounge
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex  font-semibold flex-col list-none md:mb-4">
                    <li className="items-center flex-row">
                      <Link
                        className={`text-primary-text flex-row flex items-center my-auto gap-x-2 px-2 hover:text-secondary-text text-s pb-3 pt-2  text-md font-cairoli ${
                          pathname === "/tbb4/dashboard" ? activeItemClass : ""
                        }`}
                        to="/tbb4/dashboard"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <Home size={16} />
                        Dashboard
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text flex-row flex items-center my-auto gap-x-2 px-2 hover:text-secondary-text text-s pb-3 pt-2 text-md font-cairoli ${
                          pathname.includes(
                            "/captains-lounge/strategy-management"
                          )
                            ? activeItemClass
                            : ""
                        }`}
                        to="/captains-lounge/strategy-management"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <ScatterChart size={16} />
                        Strategy
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text flex-row flex items-center my-auto gap-x-2 px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli ${
                          pathname.includes("/tbb4/dashboard/betting-systems")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/dashboard/betting-systems"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <FileSliders size={16} />
                        Betting System
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text flex-row font-semibold flex items-center my-auto gap-x-2 px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli ${
                          pathname === "/tbb4/tommy-two" ? activeItemClass : ""
                        }`}
                        to="/tbb4/tommy-two"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <FlaskConical size={16} />
                        Tommy 2.0
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text font-semibold flex-row flex items-center my-auto gap-x-2 px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli ${
                          pathname.includes("/tbb4/results")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/results"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <Trophy size={16} />
                        Results
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text flex-row flex items-center my-auto gap-x-2 px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli font-semibold ${
                          pathname.includes("/tbb4/dashboard/settings")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/dashboard/settings"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <Settings2 size={16} />
                        Settings
                      </Link>
                    </li>
                  </ul>
                  <hr className="my-4 border-0 md:min-w-full" />

                  <h6 className="md:min-w-full text-lbw-accent text-s items-center uppercase font-cairoli font-bold block pt-1 pb-4 no-underline">
                    Ratings Data{" "}
                    <span className="text-white text-xs p-0.5 bg-lbw-accent lower rounded-md">
                      Beta
                    </span>
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli font-semibold block ${
                          pathname === "/tbb4/pf-data" ? activeItemClass : ""
                        }`}
                        to="/tbb4/pf-data"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-line text-secondary-text  mr-2 text-sm"></i>{" "}
                        Horse Ratings
                      </Link>
                    </li>
                    {/* <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli block ${
                          pathname === "/tbb4/gh-rating-data"
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/pf-data"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-line text-secondary-text mr-2 text-sm"></i>{" "}
                        Dog Ratings
                      </Link>
                    </li> */}
                  </ul>
                </>
              ) : (
                <></>
              )}
              {/* Divider */}
              <hr className="my-4 border-0 md:min-w-full" />
              {/* Heading */}
              {user.admin ? (
                <>
                  <h6 className="md:min-w-full text-lbw-accent text-md uppercase font-cairoli block pt-1 pb-4 no-underline">
                    Trend
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    {/* <li className="items-center">
                      <Link
                        className="text-primary-text px-2 hover:text-secondary-text flex flex-row text-s uppercase py-3 font-cairoli"
                        to="/tbb4/admin-dashboard"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <img
                          alt="active-i-status"
                          className="w-4 h-4 mr-2"
                          src={
                            botStatus.intermediate.active
                              ? greenCircle
                              : redCircle
                          }
                        />{" "}
                        Intermediate Settings
                      </Link>
                    </li> */}
                    <li className="items-center flex">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli flex flex-row items-center ${
                          pathname.includes("/tbb4/starter-package-settings")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/starter-package-settings"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <img
                          alt="active-i-status"
                          className="w-4 h-4 mr-2"
                          src={
                            botStatus.starter.active ? greenCircle : redCircle
                          }
                        />{" "}
                        Trend Selections
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli block ${
                          pathname.includes(
                            "/tbb4/ladbrokes-odds-members-selections"
                          )
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/ladbrokes-odds-members-selections"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-dollar-sign text-secondary-text mr-2 text-sm"></i>{" "}
                        Trend Results
                      </Link>
                    </li>
                    {/* <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli block ${
                          pathname.includes("/tbb4/trend-backtest")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/trend-backtest"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-gears text-secondary-text mr-2 text-sm"></i>{" "}
                        Trend Backtest
                      </Link>
                    </li> */}
                  </ul>
                  <hr className="my-4 border-0 md:min-w-full" />

                  <h6 className="md:min-w-full text-lbw-accent text-md uppercase font-cairoli block pt-1 pb-4 no-underline">
                    Beta Features
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    {/* <li className="items-center flex">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli flex flex-row items-center ${
                          pathname.includes("/tbb4/master-bot-settings")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/master-bot-settings"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-robot text-secondary-text mr-2 text-sm"></i>{" "}
                        {/* <img
                          alt="active-i-status"
                          className="w-4 h-4 mr-2"
                          src={
                            botStatus.starter.active ? greenCircle : redCircle
                          }
                        />{" "} 
                        Tommy Settings
                      </Link>
                    </li> */}
                    <li className="items-center">
                      {/* <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli block ${
                          pathname === "/tbb4/machine-learning-selections"
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/machine-learning-selections"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-line text-secondary-text mr-2 text-sm"></i>{" "}
                        Tommy Results
                      </Link>
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli block ${
                          pathname === "/tbb4/tommy-two" ? activeItemClass : ""
                        }`}
                        to="/tbb4/tommy-two"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-line text-secondary-text mr-2 text-sm"></i>{" "}
                        Tommy 2.0
                      </Link> */}
                    </li>

                    {/* <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli block ${
                          pathname.includes("/tbb4/race-results")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/race-results"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-flag-checkered text-secondary-text mr-2 text-sm"></i>{" "}
                        Race Results
                      </Link>
                    </li> */}
                  </ul>
                  {/* <hr className="my-4 border-0 md:min-w-full" />
                  <h6 className="md:min-w-full text-lbw-accent text-md uppercase font-cairoli block pt-1 pb-4 no-underline">
                    Testing Strategies
                  </h6> */}
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    {/* <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase pb-3 pt-2 font-cairoli block ${
                          pathname === "/tbb4/machine-learning-selections-gh"
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/machine-learning-selections-gh"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-line text-secondary-text mr-2 text-sm"></i>{" "}
                        Dog ML Selections
                      </Link>
                    </li> */}
                    {/* <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase py-3 font-cairoli block`}
                        to="/tbb4/back-testing-simulator"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-line text-secondary-text mr-2 text-sm"></i>{" "}
                        Back Testing Simulator
                      </Link>
                    </li> */}

                    {/* <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase py-3 font-cairoli block`}
                        to="/tbb4/development-bots"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fas fa-robot text-secondary-text mr-2 text-sm"></i>{" "}
                        Development Bots
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s uppercase py-3 font-cairoli block`}
                        to="/tbb4/development-bots-results"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fa-solid fa-chart-simple text-secondary-text mr-2 text-sm"></i>{" "}
                        Dev Bot Results
                      </Link>
                    </li> */}
                  </ul>

                  <hr className="my-4 border-0 md:min-w-full" />

                  <h6 className="md:min-w-full text-lbw-accent text-s uppercase font-cairoli block pt-1 pb-4 no-underline">
                    Admin
                  </h6>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli block ${
                          pathname.includes("/tbb4/user-management")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/user-management"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fas fa-user text-secondary-text mr-2 text-sm"></i>{" "}
                        User Management
                      </Link>
                    </li>
                    <li className="items-center">
                      <Link
                        className={`text-primary-text px-2 hover:text-secondary-text text-s pb-3 pt-2 font-cairoli block ${
                          pathname.includes("/tbb4/master-controls")
                            ? activeItemClass
                            : ""
                        }`}
                        to="/tbb4/master-controls"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <i className="fas fa-cog text-secondary-text mr-2 text-sm"></i>{" "}
                        Master Controls
                      </Link>
                    </li>
                  </ul>

                  <hr className="my-4 border-0 md:min-w-full" />
                </>
              ) : (
                <></>
              )}

              {/* Heading */}
              {/* <h6 className="md:min-w-full text-secondary-text text-s uppercase font-cairoli block pt-1 pb-4 no-underline">
                Documentation
              </h6> */}
              <button
                className="bg-lbw-secondary-lighter mt-auto text-primary-text active:bg-slate-600 text-sm font-cairoli uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button"
                onClick={logOut}
              >
                Logout
              </button>
            </div>
          </div>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
}
