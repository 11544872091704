import React from "react";
import readXlsxFile from "read-excel-file";
import moment from "moment";
import axios from "axios";

const UploadSheet = () => {
  const backendHandler = async (sheet) => {
    const url = "https://sb.laybackandwin.au/functions/v1/MemberSheetProcess";
    const data = sheet;
    const config = {
      headers: { "Content-Type": "application/json" },
      url: url,
      method: "post",
      data: data,
    };
    try {
      const response = await axios(config);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadHandler = (e) => {
    alert(e.target.files[0].name);
    const file = e.target.files[0];
    if (file) {
      readXlsxFile(file, { schema }).then(({ rows }) => {
        for (var i = 0; i < rows.length; i++) {
          let date = moment(rows[i].date).format("YYYY-MM-DD");
          rows[i].date = date;
          // rows[i].sheet = item.name;
        }
        console.log(rows);
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  return (
    <div className="px-4 py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center pb-4">
        <h2 className="block uppercase text-primary-text text-xl font-bold">
          Upload Selection Sheet
        </h2>
      </div>
      <div className="flex flex-row max-sm:flex-col-reverse w-full gap-x-2 py-4 gap-y-5">
        {/* System Settings */}
        <div className="w-10/12">
          <input id="rank" type="file" className={inputStyle} />
        </div>
        <div className="w-2/12">
          <button className={buttonStyle} onClick={uploadHandler} type="button">
            Upload File
          </button>
        </div>
      </div>
    </div>
  );
};
const labelStyle = "block uppercase text-primary-text text-xs font-bold mb-2";
const inputStyle =
  "border-2 border-gray-400 px-3 py-1 placeholder-gray-300 text-primary-text font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150";
const buttonStyle =
  "bg-lbw-accent text-primary-text active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full ease-linear transition-all duration-150";

const schema = {
  TIME: {
    prop: "time",
    type: Date,
    required: true,
  },
  DATE: {
    prop: "date",
    type: String,
    required: true,
  },
  TRACK: {
    prop: "track",
    type: String,
    required: true,
  },
  RN: {
    prop: "raceNumber",
    type: String,
    required: true,
  },
  TN: {
    prop: "horseNumber",
    type: String,
    required: true,
  },
  HORSE: {
    prop: "horse",
    type: String,
    required: true,
  },
  CALL: {
    prop: "CALL",
    type: String,
    required: true,
  },
};

export default UploadSheet;
