import React from "react";
import axios from "axios";
import moment from "moment";
import { supabase, supabaseKey, getUser } from "../../../utils/supabaseClient";
import { useUser } from "../../../contexts/User.context";
import { HorseIcon, GreyhoundIcon, HarnessIcon } from "../../ui/Icon";

export const UpcomingRaces = () => {
  const [marketData, setMarketData] = React.useState([]);
  const [autoRefresh, setAutoRefresh] = React.useState(false);
  const [currentProfit, setCurrentProfit] = React.useState(0);

  const { user } = useUser();
  const expected_profit_per_race = 0.2;

  const includeRaceHandler = async (id, include) => {
    console.log(id, include);
    const ex = include;
    if (ex === true) {
      const { data, error } = await supabase
        .from("excluded_races_and_venues")
        .insert({ type: "RACE", market_id: id });
    } else {
      const { data, error } = await supabase
        .from("excluded_races_and_venues")
        .delete()
        .eq("market_id", id);
    }

    await racingData();
  };

  const currentProfitHandler = async () => {
    const { data, error } = await supabase
      .from("ml_horse_results")
      .select("*")
      .eq("event_date", moment().format("YYYY-MM-DD"))
      .eq("rank", 2);

    let p = 0;

    const { data: excluded_races, error: excluded } = await supabase
      .from("excluded_races_and_venues")
      .select("market_id");

    data.forEach((item) => {
      if (
        excluded_races.filter((ex) => ex.market_id === item.market_id)
          .length === 0
      ) {
        if (item.result === "WINNER") {
          if (item.side === "Back") {
            p = p + (item.bab * item.units - item.units) * 0.93;
          }
          if (item.side === "Lay") {
            p = p - (item.bab * item.units + item.units);
          }
        }
      }
      if (item.result === "LOSER") {
        if (item.side === "Back") {
          p = p - item.units;
        }

        if (item.side === "Lay") {
          p = p + item.units * 0.93;
        }
      }
      console.log(p);
    });

    setCurrentProfit(p);
  };

  const racingData = async () => {
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .eq("event_date", moment().format("YYYY-MM-DD"))
      .eq("race_type", "Thoroughbred")
      .order("market_start_time", { ascending: true });
    const data = rawData.data;

    const { data: excluded_races, error: excluded } = await supabase
      .from("excluded_races_and_venues")
      .select("market_id");

    data.forEach((item) => {
      if (
        excluded_races.filter((ex) => ex.market_id === item.market_id).length >
        0
      ) {
        item.excluded = true;
      } else {
        item.excluded = false;
      }
    });

    setMarketData(data);
  };

  React.useEffect(() => {
    racingData();
    currentProfitHandler();
  }, []);

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (autoRefresh) {
        racingData();
      }
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      currentProfitHandler();
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  // Checks to place a bet every 10 seconds

  return (
    <div className="py-4 bg-lbw-secondary space-y-4 mt-4 rounded-md">
      <div className="flex flex-row w-full px-4">
        <div className="flex flex-col w-1/4">
          <span className="text-primary-text text-2xl font-bold">No.Races</span>
          <span className="text-primary-text text-2xl ">
            {marketData.filter((item) => !item.excluded).length}
          </span>
        </div>
        <div className="flex flex-col w-1/4">
          <span className="text-primary-text text-2xl font-bold">
            Expected Profit
          </span>
          <span className="text-primary-text text-2xl ">
            {(
              marketData.filter((item) => !item.excluded).length *
              expected_profit_per_race
            ).toFixed(2)}{" "}
            Units
          </span>
        </div>
        <div className="flex flex-col w-1/4">
          <span className="text-primary-text text-2xl font-bold">
            Current Profit
          </span>
          <span className="text-primary-text text-2xl ">
            {currentProfit.toFixed(2)} Units
          </span>
        </div>
        <div className="flex flex-col w-1/4">
          <span className="text-primary-text text-2xl font-bold">
            Normalised Expected Profit
          </span>
          <span className="text-primary-text text-2xl ">
            {(
              marketData.filter(
                (item) =>
                  !item.excluded &&
                  item.market_start_time >
                    moment().utc().format("YYYY-MM-DDTHH:mm:ss")
              ).length *
                expected_profit_per_race +
              currentProfit
            ).toFixed(2)}{" "}
            Units
          </span>
        </div>
      </div>
      <div className="flex flex-wrap w-full justify-center">
        <div className="w-full px-4 overflow-x-auto">
          <button
            className={`max-sm:hidden ${
              autoRefresh ? "bg-green-900" : "bg-red-900"
            } text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? "Auto Refresh On" : "Auto Refresh Off"}
          </button>
          {marketData?.length > 0 ? (
            <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Start Time
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Venue
                  </th>
                  <th className="px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Race
                  </th>
                  {/* <th className="hidden md:block px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Time still Jump
                  </th> */}
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Type
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Runners
                  </th>
                  <th className="px-6 bg-lbw-secondary text-primary-text align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Include
                  </th>
                </tr>
              </thead>
              <tbody>
                {marketData.map((item, index) => (
                  <>
                    <tr
                      className={`border-b-2  font-bold ${
                        item.excluded
                          ? "bg-red-200 text-lbw-secondary"
                          : "text-primary-text"
                      }`}
                      key={item.id}
                    >
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {moment(item.market_start_time).format("h:mma")}
                      </td>
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.venue}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {window.screen.width > 768 ? (
                          ""
                        ) : item.race_type === "Thoroughbred" ? (
                          <HorseIcon className="text-xl font-thin mr-2" />
                        ) : item.race_type === "Greyhound" ? (
                          <GreyhoundIcon className="text-xl font-thin mr-2" />
                        ) : (
                          <HarnessIcon className="text-xl font-thin mr-2" />
                        )}
                        {window.screen.width > 768
                          ? `${item.market_name}`
                          : `${item.venue} ${item.market_name.split(" ")[0]}`}
                      </td>
                      {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {moment(item.market_start_time).fromNow()}
                      </td> */}
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.race_type === "Thoroughbred" ? (
                          <HorseIcon className="text-xl font-thin" />
                        ) : item.race_type === "Greyhound" ? (
                          <GreyhoundIcon className="text-xl font-thin" />
                        ) : (
                          <HarnessIcon className="text-xl font-thin" />
                        )}
                      </td>
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.runner_count}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                        <button
                          onClick={() =>
                            includeRaceHandler(item.market_id, !item.excluded)
                          }
                          id={item.id}
                          aria-details={item.excluded ? "Include" : "Exclude"}
                          className={`${
                            !item.excluded ? "bg-green-900" : "bg-red-900"
                          } text-primary-text active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 max-sm:py-3 max-sm:mb-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                        >
                          {!item.excluded ? "Exclude" : "Include"}
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const labelStyle =
  "block uppercase text-right text-primary-text text-xs font-bold mb-2";
const inputStyle =
  "border-2 border-gray-400 px-3 py-3 justify-end placeholder-gray-300 text-primary-text font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring ease-linear transition-all duration-150";
const buttonStyle =
  "bg-lbw-accent text-primary-text active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";
