import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "../../ui/table";

const GHSelectionsTable = ({ data }) => {
  useEffect(() => {}, [data]);
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Race</TableHead>
          <TableHead>Selection</TableHead>
          <TableHead>Win Prob. (Rel Prob)</TableHead>
          <TableHead>Rank</TableHead>
          <TableHead>Side</TableHead>
          <TableHead>Units</TableHead>
          <TableHead>Odds</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((race) => (
          <TableRow key={race.id}>
            <TableCell className="font-medium">
              {race.event_name.split(" (")[0]} {race.market_name.split(" ")[0]}
            </TableCell>
            <TableCell>{race.runner_name}</TableCell>

            <TableCell className="text-right">
              {(race.prob_winner * 100).toFixed(2)} - (
              {(race.prob_winner * race.bab * 100).toFixed(2)})
            </TableCell>
            <TableCell>{race.rank}</TableCell>
            <TableCell>{race.side_ml}</TableCell>
            <TableCell>{race.units_ml}</TableCell>
            <TableCell>${race.bab.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GHSelectionsTable;
