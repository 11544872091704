import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";

// components

import Sidebar from "../components/ui/Sidebar.jsx";

// views
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useUser } from "../contexts/User.context.jsx";
import { StatusBar } from "../components/ui/StatusBar.jsx";

export default function DashboardLayout() {
  const { user, refreshUser } = useUser();
  useEffect(() => {
    refreshUser();
  }, []);
  if (user) {
    return (
      <>
        <Sidebar />

        <div className="relative md:ml-64 bg-lbw-primary bg-no-repeat overflow-hidden bg-full min-h-screen h-full">
          <StatusBar />
          <div className="mx-auto w-full">
            <Outlet />
            {/* <FooterAdmin /> */}
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
