import React, { useEffect } from "react";
import BaseCard from "../../components/ui/BaseCard";
import SelectionsTable from "../../components/Admin/ML/SelectionsTable";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";

const MLSelections = () => {
  const [horses, setHorses] = React.useState([]);
  const [profit, setProfit] = React.useState(0);

  const getHorseSelections = async () => {
    const { data, error } = await supabase
      .from("ml_gbm_horse_results")
      .select("*")
      .eq("event_date", moment().format("YYYY-MM-DD"))
      .eq("rank", 2)
      .eq("model", "GBM")
      .order("market_start_time", { ascending: false });
    if (error) console.log("error", error);
    else setHorses(data);
    var b = 0;
    data.forEach((d) => {
      b += d.profit ? d.profit : 0;
    });
    setProfit(b.toFixed(2));
  };

  const [results, setResults] = React.useState([]);
  const today = moment().format("YYYY-MM-DD");

  const getMlResults = async () => {
    const { data, error } = await supabase
      .from("ml_results")
      .select("*")
      .eq("event_date", today)
      .eq("rank", 2);
    if (error) console.log("error", error);
    else setResults(data);
  };

  const refreshResults = () => {
    getMlResults();
  };

  useEffect(() => {
    getHorseSelections();

    // Subscribe to new horse selections
    const horseUpdates = supabase
      .channel("ml_horses_selections")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "ml_horse_selections",
        },
        (payload) => {
          console.log("Change received!", payload);
          let newHorse = payload.new.filter(
            (h) => h.rank === 2 && h.model === "GBM"
          );
          setHorses((prev) => [newHorse, ...prev]);
        }
      )
      .subscribe();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-primary-text text-3xl font-cairoli">
            Machine Learning Selections
          </h2>
        </div>
      </div>
      <BaseCard>
        <div className="flex max-sm:flex-col md:flex-col py-4 gap-x-2 text-primary-text text-2xl font-semibold">
          <div className="w-full flex flex-row mt-6 mb-2 gap-x-4 xl:mb-0 align-middle text-primary-text text-2xl font-semibold">
            <span>Profit: {profit}U</span>
            <span></span>
          </div>
        </div>
        <div className="flex max-sm:flex-col md:flex-row py-4 gap-x-2">
          <div className="w-full flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            <SelectionsTable data={horses} />
          </div>
          {/* <div className="w-full flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            <SelectionsTable data={dogs} />
          </div> */}
        </div>
        <hr className="border-lbw-accent" />
        <div className="py-0"></div>
      </BaseCard>
    </div>
  );
};

export default MLSelections;
