import React, { useEffect, useState } from "react";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import LbTable from "../../components/Admin/LadbrokesOdds/LbTable";
import { useUser } from "../../contexts/User.context";
import {
  HorseIcon,
  HarnessIcon,
  GreyhoundIcon,
  TickIcon,
} from "../../components/ui/Icon";

const LadbrokesOdds = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const [loading, setLoading] = useState(true);

  const { user } = useUser();

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="space-y-6">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-primary-text text-3xl font-cairoli">
            Trend Selection Results
          </h2>
        </div>
      </div>
      <BaseCard>
        <div className="flex max-sm:flex-wrap md:flex-row py-4 gap-x-2">
          <div className="w-full lg:w-3/12 flex flex-wrap mt-6 mb-2 xl:mb-0 align-middle">
            {/* <p className="text-primary-text my-auto">Date Range </p> */}
            <label className="block uppercase text-primary-text text-xs font-bold mb-2">
              Date
            </label>
            <input
              type="date"
              id="start"
              value={date}
              onChange={handleDateChange}
              className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-3 w-full  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <hr className="border-lbw-accent" />
        <div className="py-0">
          <LbTable date={date} />
        </div>
      </BaseCard>
    </div>
  );
};

export default LadbrokesOdds;
