import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import "flowbite";
import { PrivateRoute } from "./components/routes/PrivateRoute";
import { Login } from "./views/auth/Login";
import { RedirectBetfair } from "./views/RedirectBetfair";
import DashboardLayout from "./layouts/DashboardLayout";
import { AdminRoute } from "./components/routes/AdminRoute";
import TBB4AdminDashboard from "./views/admin/Dashboard";
import { UserSettings } from "./views/settings/UserSettings";
import Dashboard from "./views/members/Dashboard";
import UserManagement from "./views/admin/UserManagement";
import DevelopmentBots from "./views/admin/DevelopmentBots";
import DevBotResults from "./views/admin/DevBotResults";
import MembersStrategy from "./views/admin/MembersStrategy";
import UserResults from "./views/members/UserResults";
import CaptainsLounge from "./views/members/CaptainsLounge";
import BackTesting from "./views/admin/BackTesting";
import RaceResults from "./views/members/RaceResults";
import { useUser } from "./contexts/User.context";
import { UserProvider } from "./contexts/User.context";
import { ForgotPassword } from "./views/auth/ForgotPassword";
import LadbrokesOdds from "./views/admin/LadbrokesOdds";
import MLSelections from "./views/admin/MLSelections";
import GHMLSelections from "./views/admin/GHMLSelections";
import MasterBotSettings from "./views/admin/MasterBotSettings";
import BettingSystems from "./views/members/BettingSystems";
import { Toaster } from "./components/ui/toaster";
import PuntingForm from "./views/admin/PuntingForm";
import AdminControls from "./views/admin/AdminControls";
import TrendBacktest from "./views/admin/TrendBacktest";
import { ThemeProvider } from "@emotion/react";
import { lightTheme } from "./components/ui/theme";
import TommyTwo from "./views/admin/TommyTwo";

function App() {
  const { user } = useUser();
  const [theme, setTheme] = useState(lightTheme);
  useEffect(() => {
    // console.log("user", user);
  }, [user]);
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        {/* <CaptainsLoungeProvider> */}
        <Router>
          <Routes>
            {/* ALL ROUTES THAT REQUIRE AUTH TO ACCESS */}
            <Route element={<PrivateRoute />}>
              {/* TBB3 */}
              {/* <Route path="/tbb3" element={<DashboardLayout />}>
            <Route path="/tbb3/dashboard" exact element={<Dashboard />} />
            <Route
              path="/tbb3/dashboard/settings"
              exact
              element={<Settings />}
            />
            <Route path="/tbb3/dashboard/results" exact element={<Results />} />
          </Route> */}

              <Route path="/captains-lounge" element={<DashboardLayout />}>
                <Route
                  path="/captains-lounge/strategy-management"
                  exact
                  element={<CaptainsLounge />}
                />
              </Route>

              {/* TBB4 */}
              <Route path="/tbb4" element={<DashboardLayout />}>
                {/* ADMIN ROUTES */}
                {/* <Route element={<AdminRoute admin={user?.admin} />}> */}
                <Route
                  path="/tbb4/admin-dashboard"
                  exact
                  element={<TBB4AdminDashboard />}
                />
                <Route
                  path="/tbb4/starter-package-settings"
                  exact
                  element={<MembersStrategy />}
                />
                <Route
                  path="/tbb4/user-management"
                  exact
                  element={<UserManagement />}
                />
                <Route
                  path="/tbb4/master-controls"
                  exact
                  element={<AdminControls />}
                />
                <Route
                  path="/tbb4/development-bots"
                  exact
                  element={<DevelopmentBots />}
                />
                <Route
                  path="/tbb4/development-bots-results"
                  exact
                  element={<DevBotResults />}
                />
                <Route
                  path="/tbb4/back-testing-simulator"
                  exact
                  element={<BackTesting />}
                />
                <Route
                  path="/tbb4/machine-learning-selections"
                  exact
                  element={<MLSelections />}
                />
                <Route path="/tbb4/tommy-two" exact element={<TommyTwo />} />
                <Route
                  path="/tbb4/machine-learning-selections-gh"
                  exact
                  element={<GHMLSelections />}
                />
                <Route path="/tbb4/pf-data" exact element={<PuntingForm />} />
                <Route
                  path="/tbb4/race-results"
                  exact
                  element={<RaceResults />}
                />
                <Route
                  path="/tbb4/master-bot-settings"
                  exact
                  element={<MasterBotSettings />}
                />
                <Route
                  path="/tbb4/ladbrokes-odds-members-selections"
                  exact
                  element={<LadbrokesOdds />}
                />
                <Route
                  path="/tbb4/trend-backtest"
                  exact
                  element={<TrendBacktest />}
                />
                {/* </Route> */}
                {/* WELCOME PACKAGE */}
                <Route path="/tbb4/dashboard" exact element={<Dashboard />} />
                <Route path="/tbb4/results" exact element={<UserResults />} />
                <Route
                  path="/tbb4/dashboard/settings"
                  exact
                  element={<UserSettings />}
                />
                <Route
                  path="/tbb4/dashboard/betting-systems"
                  exact
                  element={<BettingSystems />}
                />
                {/* <Route path="/tbb4/dashboard/results" exact element={<Results />} /> */}
              </Route>

              {/* ADMIN */}
              <Route element={<AdminRoute />}>
                {/* <Route path="/admin" element={<DashboardLayout />}>
              <Route path="/admin/upload-sheet" element={<UploadSheet />} />
              <Route path="/admin/edit-sheet" element={<EditSheet />} />
              <Route path="/admin/bot-settings" element={<BotSettings />} />
              <Route path="/admin/tbb4-settings" element={<TBB4Settings />} />
              <Route path="/admin/manage-users" element={<ManagerUsers />} />
            </Route> */}
              </Route>
              <Route path="/newjoin" element={<RedirectBetfair />} />
            </Route>

            {/* ROUTES THAT DONT REQUIRE AUTH */}
            <Route path="/login" element={<Login />} />
            <Route path="/login/forgot-password" element={<ForgotPassword />} />
            <Route path="/*" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
        {/* </CaptainsLoungeProvider> */}
        <Toaster />
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
