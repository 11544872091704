import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/User.context";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import { HorseIcon, GreyhoundIcon, HarnessIcon } from "./Icon";
import { getUsersUpcomingRaces } from "../../utils/user";
import CountdownText from "./CountdownText";
import { betfairAccountRequest } from "../../utils/betfair";
import { Tabs, TabsTrigger, TabsList } from "./tabs";
import { Popover, PopoverTrigger, PopoverContent } from "./popover";
import { Button } from "./button";
import { Input } from "./input";
import { Label } from "./label";
import { ChevronDown, Cog, CogIcon, User2Icon } from "lucide-react";
import { useToast } from "./use-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { useNavigate } from "react-router-dom";

export const StatusBar = () => {
  const { user, refreshUser, updateUserSettings } = useUser();
  const [status, setStatus] = useState("Loading...");
  const [marketData, setMarketData] = useState([]);
  const [success, setSuccess] = useState(null);
  const [balance, setBalance] = useState(0);
  const [newSettings, setNewSettings] = React.useState({
    tbb4_bot: false,
    tbb4_stake_size: 10,
    tbb4_stop_loss: 0,
    tbb4_target_profit: 0,
  });

  const { toast } = useToast();
  const navigate = useNavigate();

  const logOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
    navigate("/login");
  };

  const getBalance = async () => {
    let accessToken = user.betfair_access_token;
    let data = {
      wallet: "UK",
    };
    if (accessToken !== undefined || null) {
      let result = await betfairAccountRequest(
        accessToken,
        "getAccountFunds",
        data
      );

      if (result.data.error?.code) {
        refreshUser();
      }
      setBalance(result.data.result.availableToBetBalance);
    } else {
      setTimeout(() => {
        setBalance(0);
      }, 2000);
    }
  };

  const refreshBalance = async () => {
    setBalance("...");
    getBalance();
  };

  const racingData = async () => {
    // console.log(user);
    // setMarketData([]);
    const data = await getUsersUpcomingRaces(user);
    // console.log(data);
    if (data[0].market_start_time !== marketData[0]?.market_start_time) {
      setMarketData([]);
    }
    setMarketData(data);
  };

  // Executed upon load
  React.useEffect(() => {
    racingData();
    getBalance();
  }, []);

  const onSaveHandler = () => {
    updateUserSettings(newSettings);
    setSuccess(true);

    setTimeout(() => {
      setSuccess(null);
    }, 2000);
  };

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      racingData();
      getBalance();
    }, 12020);
    return () => clearInterval(interval);
  }, [marketData]);

  useEffect(() => {
    setNewSettings({
      tbb4_bot: user.tbb4_bot,
      tbb4_stake_size: user.tbb4_stake_size,
      tbb4_stop_loss: user.tbb4_stop_loss,
      tbb4_take_profit: user.tbb4_take_profit,
    });
  }, [user]);

  return (
    <div className="max-sm:hidden relative top-0 right-0 z-10 py-2 px-8 bg-lbw-secondary w-full">
      <div className="flex flex-row items-center">
        <div className="w-2/3 ">
          {marketData.length > 0 ? (
            <div className="flex flex-row space-x-8 overflow-x-auto scroller">
              {marketData?.map((item, index) => (
                <div key={index} className="flex flex-row my-auto">
                  {item.race_type === "Thoroughbred" ? (
                    <HorseIcon className="text-3xl font-thin text-primary-text mr-2" />
                  ) : item.race_type === "Greyhound" ? (
                    <GreyhoundIcon className="text-3xl font-thin text-primary-text mr-2" />
                  ) : (
                    <HarnessIcon className="text-xl font-thin text-primary-text mr-2" />
                  )}
                  <div className="p-1">
                    <p className="flex flex-row  whitespace-nowrap text-primary-text">
                      {item.race_number} {item.venue}
                    </p>
                  </div>
                  <div
                    className={`my-auto px-2 text-md rounded-md py-1 flex flex-row ${
                      item.redTime
                        ? "bg-red-700 text-primary-text"
                        : "bg-lbw-secondary-lighter text-primary-text"
                    }`}
                  >
                    <div className="flex flex-row whitespace-nowrap">
                      <CountdownText
                        jumpTime={item.market_start_time}
                        className={"text-primary-text"}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <span className="text-primary-text">No Upcoming Races</span>
          )}
        </div>
        <div className="w-1/2 flex gap-x-4 justify-evenly items-center flex-row z-40 ml-4">
          <div className=" w-1/3 align-middle text-right text-secondary-text uppercase font-cairoli block no-underline">
            {/* <span>${balance}</span> */}
            <button
              className="btn btn-outline text-primary-text m-0 text-sm min-h-0 h-8 font-sans"
              onClick={refreshBalance}
            >
              ${balance}
            </button>
            {/* <i
              onClick={refreshBalance}
              className=" pl-1 fas fa-arrows-rotate mr-2 text-sm cursor-pointer"
            /> */}
          </div>
          <div className="divider divider-horizontal"></div>
          <div className="w-1/3 align-middle text-center gap-x-4 text-secondary-text text-xl whitespace-nowrap uppercase font-cairoli block no-underline">
            {/* <p className=" text-sm">Betting Automation</p> */}
            <button
              className={`${
                user.tbb4_bot === true
                  ? "btn btn-sm btn-success text-white"
                  : "btn btn-sm btn-error text-white"
              }`}
              onClick={() => {
                updateUserSettings({ tbb4_bot: !user.tbb4_bot });
                toast({
                  title: `Bot turned ${user.tbb4_bot === true ? "Off" : "On"}`,
                  description: "",
                });
              }}
            >
              {user.tbb4_bot === true ? "Automation On" : "Automation Off"}
            </button>
            {/* <Tabs
              defaultValue={user.tbb4_bot === true ? "true" : "false"}
              value={user.tbb4_bot === true ? "true" : "false"}
              onValueChange={(e) => {
                updateUserSettings({ tbb4_bot: e === "true" });
                toast({
                  title: `Bot turned ${e === "true" ? "On" : "Off"}`,
                  description: "",
                });
              }}
              className="w-full bg-lbw-secondary"
            >
              <TabsList className="grid w-full bg-lbw-primary grid-cols-2">
                <TabsTrigger
                  className="data-[state=active]:bg-green-500"
                  value="true"
                >
                  On
                </TabsTrigger>
                <TabsTrigger
                  className="data-[state=active]:bg-red-500"
                  value="false"
                >
                  Off
                </TabsTrigger>
              </TabsList>
            </Tabs> */}
          </div>

          <div className="flex flex-row min-w-max text-center gap-x-4 text-secondary-text text-sm uppercase font-sans no-underline">
            <Popover>
              <PopoverTrigger asChild>
                <button className="btn btn-outline text-primary-text m-0 text-sm min-h-0 h-8">
                  Quick Settings
                </button>
              </PopoverTrigger>
              <PopoverContent className="w-80 mt-4 bg-lbw-primary text-primary-text">
                <div className="grid gap-4">
                  <div className="space-y-2">
                    <h4 className="font-medium leading-none">
                      Staking Settings
                    </h4>
                  </div>
                  <div className="grid gap-2">
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="width">Stake Size</Label>
                      <Input
                        className="w-fit"
                        value={`$ ${
                          newSettings.tbb4_stake_size
                            ? newSettings.tbb4_stake_size
                            : 0
                        }`}
                        onChange={(e) => {
                          setNewSettings({
                            ...newSettings,
                            tbb4_stake_size: parseInt(
                              e.target.value.split("$")[1]
                            ),
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="maxWidth">Daily Profit Target</Label>
                      <Input
                        className="w-fit"
                        value={`$ ${
                          newSettings.tbb4_take_profit
                            ? newSettings.tbb4_take_profit
                            : 0
                        }`}
                        onChange={(e) => {
                          setNewSettings({
                            ...newSettings,
                            tbb4_take_profit: parseInt(
                              e.target.value.split("$")[1]
                            ),
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="height">Max Daily Loss</Label>
                      <Input
                        className="w-fit"
                        value={`$ ${
                          newSettings.tbb4_stop_loss
                            ? newSettings.tbb4_stop_loss
                            : 0
                        }`}
                        onChange={(e) => {
                          setNewSettings({
                            ...newSettings,
                            tbb4_stop_loss: parseInt(
                              e.target.value.split("$")[1]
                            ),
                          });
                        }}
                      />
                    </div>
                    {success && (
                      <span className="text-green-500">
                        Settings updated successfully
                      </span>
                    )}
                    <Button onClick={onSaveHandler} className="bg-lbw-accent">
                      Save
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <button className="btn btn-outline text-primary-text m-0 text-sm min-h-0 h-8">
                  Profile
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => navigate("/tbb4/dashboard/settings")}
                >
                  Profile
                </DropdownMenuItem>
                {/* <DropdownMenuItem>Betting System</DropdownMenuItem> */}
                <DropdownMenuItem
                  onClick={() => navigate("/tbb4/dashboard/settings")}
                >
                  Settings
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Button onClick={() => logOut()} variant="outline">
                    Logout
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  );
};
