import React, { useEffect } from "react";
import BaseCard from "../../components/ui/BaseCard";
import { LineChart } from "@tremor/react";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectContent,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

// Components are at the bottom of this file

function TommyTwo() {
  const [timid, setTimid] = React.useState([]);
  const [resulted, setResulted] = React.useState([]);
  const [profit, setProfit] = React.useState(0);
  const [layProfit, setLayProfit] = React.useState(0);
  const [backProfit, setBackProfit] = React.useState(0);
  const [layCount, setLayCount] = React.useState(0);
  const [backCount, setBackCount] = React.useState(0);
  const [endDate, setEndDate] = React.useState(
    moment().format("YYYY-MM-DD 14:00:00")
  );
  const [startDate, setStartDate] = React.useState(
    moment().format("YYYY-MM-DD 14:00:00")
  );

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("tommy_two_results")
        .select("*")
        .gte("meeting_datestamp", startDate)
        .lte("start_time", endDate)
        .order("start_time", { ascending: true });

      if (error) {
        console.log("Getting Timid Data Error: ", error);
      }
      if (data) {
        console.log("Length", data.length);
        data
          // .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
          .filter((item) => item.price_9am < 10);
        console.log(data.length);
        const timidData = data.filter(
          (item) =>
            (item.price_9am < 10 || item.price_latest < 10) &&
            (item.ml_winner !== null || item.predicted_outcome === 1) &&
            item.price_9am
        );

        for (const item of timidData) {
          item.side =
            // item.predicted_outcome === 0 &&
            item.prob_winner < 0.5 && item.price_latest < 10
              ? "LAY"
              : item.predicted_outcome === 1 && item.prob_winner > 0.65
              ? "BACK"
              : null;
          if (item.result) {
            if (item.side === "LAY") {
              if (item.result === "WINNER") {
                item.profit = item.price_latest * -1 + 1;
              } else {
                item.profit = 1;
              }
            } else {
              if (item.result === "WINNER") {
                item.profit = item.price_jump - 1;
              } else {
                item.profit = -1;
              }
            }
          }
        }

        // Group items by start_time
        const groupedData = timidData.reduce((acc, item) => {
          const key = item.start_time;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});

        // Filter items within each group
        const filteredData = Object.values(groupedData).flatMap((group) => {
          const layItems = group.filter((item) => item.side === "LAY");
          const backItems = group.filter((item) => item.side === "BACK");

          const filteredLayItems = layItems.reduce((acc, item) => {
            const existingItem = acc.find(
              (accItem) => accItem.start_time === item.start_time
            );
            if (!existingItem) {
              acc.push(item);
            } else {
              if (item.prob_winner < existingItem.prob_winner) {
                // Replace existing item with the one having lower prob_winner
                acc = acc.filter((accItem) => accItem !== existingItem);
                acc.push(item);
              }
            }
            return acc;
          }, []);

          return [...filteredLayItems, ...backItems];
        });
        const totalProfit = filteredData.reduce(
          (acc, item) => (acc += item.profit || 0),
          0
        );

        const layProfit = filteredData
          .filter((item) => item.side === "LAY")
          .reduce((acc, item) => (acc += item.profit || 0), 0);

        const backProfit = filteredData
          .filter((item) => item.side === "BACK")
          .reduce((acc, item) => (acc += item.profit || 0), 0);

        const layCount = filteredData.filter(
          (item) => item.side === "LAY"
        ).length;
        const backCount = filteredData.filter(
          (item) => item.side === "BACK"
        ).length;

        setLayCount(layCount);
        setBackCount(backCount);
        setLayProfit(layProfit);
        setBackProfit(backProfit);
        setProfit(totalProfit);

        const upcoming = filteredData.filter(
          (item) => item.result === null || item.result === undefined
        );
        const resulted = filteredData.filter(
          (item) => item.result !== null && item.result !== undefined
        );
        setTimid(upcoming);
        setResulted(resulted);
      }
    };
    fetchData();
  }, [startDate]);

  return (
    <div className="space-y-6 px-10 max-sm:px-2">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-primary-text text-3xl font-extrabold font-cairoli">
            Tommy 2.0
          </h2>
        </div>
      </div>
      <div className="w-full">
        <span className="text-primary-text text-sm font-cairoli">
          These figures are based on Ladbrokes odds and don't factor in
          commission at the moment
        </span>
        <div className="flex flex-row gap-x-10 justify-start">
          <h2 className="text-primary-text text-xl font-cairoli ">
            <span>Profit:</span> {profit.toFixed(2)}
          </h2>
          <h2 className="text-primary-text text-xl font-cairoli ">
            <span>Lay Profit:</span> {layProfit.toFixed(2)}
          </h2>
          <h2 className="text-primary-text text-xl font-cairoli ">
            <span>Back Profit:</span> {backProfit.toFixed(2)}
          </h2>
          <h2 className="text-primary-text text-xl font-cairoli ">
            <span>Bet split:</span> {layCount + backCount} - B{backCount} - L
            {layCount}
          </h2>
          {/* <div className="w-1/4"></div> */}
        </div>
      </div>
      <BaseCard>
        <div className="flex flex-col py-2 gap-x-2">
          {/* <h2 className="text-primary-text text-2xl font-cairoli">
            Timid Tommy
          </h2> */}
          <div className="w-full flex flex-row gap-x-4 max-sm:flex-col mt-6 mb-2 xl:mb-0 align-middle">
            <Select
              value={startDate}
              onValueChange={(e) => {
                if (
                  e ===
                  moment().subtract(1, "day").format("YYYY-MM-DD 14:00:00")
                ) {
                  setEndDate(e);
                } else {
                  setEndDate(moment().format("YYYY-MM-DD 14:00:00"));
                }
                setStartDate(e);
              }}
            >
              <SelectTrigger className="w-1/2 text-primary-text">
                <SelectValue
                  className="text-primary-text"
                  placeholder="Select date"
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Date</SelectLabel>

                  <SelectItem value={moment().format("YYYY-MM-DD 14:00:00")}>
                    Today
                  </SelectItem>
                  <SelectItem
                    value={moment()
                      .subtract(1, "day")
                      .format("YYYY-MM-DD 14:00:00")}
                  >
                    Yesterday
                  </SelectItem>
                  <SelectItem
                    value={moment()
                      .subtract(7, "day")
                      .format("YYYY-MM-DD 14:00:00")}
                  >
                    Last 7 Days
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="w-full flex flex-row gap-x-4 max-sm:flex-col mt-6 mb-2 xl:mb-0 align-middle">
            {/* Table with results */}
            <div className="flex w-1/2 flex-col max-sm:w-full">
              <h2>Upcoming</h2>
              <DataTable data={timid} />
            </div>
            <div className="flex w-1/2 flex-col max-sm:w-full">
              <h2>Resulted</h2>
              <ResultsTable data={resulted} />
            </div>
            {/* Graph with results */}
            {/* <div className="flex max-sm:w-full">
              <LineChartHero />
            </div> */}
          </div>
        </div>
        <div className="py-0"></div>
      </BaseCard>
    </div>
  );
}

const chartdata = [
  {
    date: "Jan 22",
    Backs: 2890,
    Lays: 2338,
  },
  {
    date: "Feb 22",
    Backs: 2756,
    Lays: 2103,
  },
  {
    date: "Mar 22",
    Backs: 3322,
    Lays: 2194,
  },
  {
    date: "Apr 22",
    Backs: 3470,
    Lays: 2108,
  },
  {
    date: "May 22",
    Backs: 3475,
    Lays: 1812,
  },
  {
    date: "Jun 22",
    Backs: 3129,
    Lays: 1726,
  },
  {
    date: "Jul 22",
    Backs: 3490,
    Lays: 1982,
  },
  {
    date: "Aug 22",
    Backs: 2903,
    Lays: 2012,
  },
  {
    date: "Sep 22",
    Backs: 2643,
    Lays: 2342,
  },
  {
    date: "Oct 22",
    Backs: 2837,
    Lays: 2473,
  },
  {
    date: "Nov 22",
    Backs: 2954,
    Lays: 3848,
  },
  {
    date: "Dec 22",
    Backs: 3239,
    Lays: 3736,
  },
];

const dataFormatter = (number) =>
  `$${Intl.NumberFormat("us").format(number).toString()}`;

function LineChartHero() {
  return (
    <LineChart
      className="h-80"
      data={chartdata}
      index="date"
      categories={["Backs", "Lays"]}
      colors={["indigo", "rose"]}
      valueFormatter={dataFormatter}
      yAxisWidth={60}
      onValueChange={(v) => console.log(v)}
    />
  );
}

function DataTable({ data }) {
  return (
    <div className="overflow-x-auto w-full text-primary-text">
      <table className="table bg-lbw-secondary-lighter">
        {/* head */}
        <thead>
          <tr>
            <th></th>
            <th>Start Time</th>
            <th>Race</th>
            <th>Selection</th>
            <th>L. Odds</th>
            <th>Side</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{moment(item.start_time).format("hh:mma")}</td>
              <td>
                {item.meeting_track_name} - R{item.raceno}
              </td>
              <td>
                {item.pf_horse_number}. {item.pf_horsename}
              </td>

              <td>
                $
                {item.price_latest
                  ? item.price_latest.toFixed(2)
                  : item.price_jump?.toFixed(2)}
              </td>
              <td>
                {item.side ? item.side : "NA"} -{" "}
                {(item.prob_winner * 100).toFixed(2)}{" "}
                {item.ml_winner === "LOSER"
                  ? "-L"
                  : item.ml_winner === "WINNER"
                  ? "-W"
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
function ResultsTable({ data }) {
  return (
    <div className="overflow-x-auto w-full text-primary-text">
      <table className="table bg-lbw-secondary-lighter">
        {/* head */}
        <thead>
          <tr>
            <th></th>
            <th>Race</th>
            <th>Selection</th>
            <th>L. Odds</th>
            <th>Side</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              className={`${
                item.profit > 0 ? "bg-green-400 bg-opacity-15" : ""
              }`}
            >
              <td>{index + 1}</td>
              <td>
                {item.meeting_track_name} - R{item.raceno}
              </td>
              <td>
                {item.pf_horse_number}. {item.pf_horsename}
              </td>

              <td>
                $
                {item.price_latest
                  ? item.price_latest.toFixed(2)
                  : item.price_jump?.toFixed(2)}
              </td>
              <td>
                {item.side ? item.side : "NA"} -{" "}
                {(item.prob_winner * 100).toFixed(2)}{" "}
                {item.ml_winner === "LOSER"
                  ? "-L"
                  : item.ml_winner === "WINNER"
                  ? "-W"
                  : ""}
              </td>
              <td className>
                {item.result} <br /> {item.profit}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default TommyTwo;
