import React from "react";
import { getUser, supabase } from "../../utils/supabaseClient";
import moment from "moment";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import { useToast } from "../ui/use-toast";

const UserTable = ({ search }) => {
  const [unfilteredUsers, setUnfilteredUsers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [packages, setPackages] = React.useState([]);
  const [bettingSystemsOptions, setBettingSystemsOptions] = React.useState([]);
  const [clStrategies, setClStrategies] = React.useState([]);

  const { toast } = useToast();

  const getUsers = async () => {
    const { data, error } = await supabase
      .from("user_information_full")
      .select("*")
      .order("tbb4_bot", { ascending: false })
      .order("last_sign_in_at", { ascending: false });

    const bettingSystems = await getUserBettingSystem();

    if (data) {
      data.forEach((item) => {
        item.showSettings = false;
        item.betting_system = bettingSystems.filter(
          (bs) => bs.auth_id === item.auth_id
        );
        item.betting_system_id = item.betting_system[0]?.betting_system_id;
        item.user_betting_system_id =
          item.betting_system[0]?.user_betting_system_id;
      });
      setUsers(data);
      setUnfilteredUsers(data);
    } else if (error) {
      console.log(error);
    }
  };

  const updateBettingSystem = async (
    user_betting_system_id,
    bettingSystemId
  ) => {
    const { data, error } = await supabase
      .from("user_betting_system")
      .update({ system_id: bettingSystemId })
      .eq("id", user_betting_system_id);

    if (!error) {
      toast({
        title: "Betting System Updated",
        description: "The user's betting system has been updated",
        type: "success",
      });
      getTbb4Package();
      getUsers();
      getClStategies();
      getAllBettingSystems();
    }
    if (error) {
      console.log(error);
    }
  };

  const getAllBettingSystems = async () => {
    const { data, error } = await supabase.from("betting_systems").select("*");

    if (data) {
      setBettingSystemsOptions(data);
    } else if (error) {
      console.error(error);
    }
  };

  const disableUser = async (id) => {
    const { data: user, error } = await supabase.auth.admin.updateUserById(id, {
      ban_duration: "876600h",
    });

    if (user) {
      console.log(user);
    }
    if (error) {
      console.log(error);
    }
  };

  const getUserBettingSystem = async () => {
    const { data, error } = await supabase
      .from("user_betting_systems_full")
      .select("*");
    if (data) {
      return data;
    } else if (error) {
      console.error(error);
    }
  };

  const getClStategies = async () => {
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .select("*")
      .order("active", { ascending: false });

    if (data) {
      setClStrategies(data);
    } else if (error) {
      console.log(error);
    }
  };

  const getTbb4Package = async () => {
    const { data, error } = await supabase.from("tbb4_packages").select("*");

    if (data) {
      setPackages(data);
    } else if (error) {
      console.log(error);
    }
  };

  // Package ID Guide
  // 1 = Starter
  // 2 = Intermediate
  // 3 = Captains Lounge
  // 4 = Full Free

  const handleCaptainsLoungeAdd = async (user) => {
    console.log(user);
    const { data, error } = await supabase
      .from("captains_lounge_full")
      .select("*")
      .eq("user_id", user.ud_id);

    if (error) {
      console.log(error);
    }

    if (data) {
      console.log(data);
      if (data.length === 0) {
        console.log("No Captains Lounge entry found, adding...");
        const { data, error } = await supabase
          .from("cl_user_bot_settings")
          .insert([{ auth_id: user.auth_id }]);

        if (data) {
          console.log(data);
        } else if (error) {
          console.log(error);
        }
      }
    } else if (error) {
      console.log(error);
      alert("Error adding user to Captains Lounge");
    }
  };

  const handlePackageChange = async (e, index, id) => {
    const packageId = e.target.value;
    const { data, error } = await supabase
      .from("user_subscription")
      .update({ tbb4_package: e.target.value })
      .select("*")
      .eq("id", id);

    if (data) {
      toast({
        title: "User Package Updated",
        description: "The user's package has been updated",
      });
      console.log("Changed Package", packageId);
      if (packageId === "3" || packageId === "4") {
        handleCaptainsLoungeAdd(users[index]);
      }
      setUsers(...users, (users[index].tbb4_package = packageId));
    } else if (error) {
      console.log(error);
    }
    getUsers();
  };

  const handleBotOnOff = async (bot, index, id) => {
    const { data, error } = await supabase
      .from("user_settings")
      .update({ tbb4_bot: !bot })
      .eq("id", id);

    if (data) {
      setUsers(...users, (users[index].tbb4_bot = !bot));
    } else if (error) {
      console.log(error);
    }
    getUsers();
  };

  const updateUserSettings = async (id, settings) => {
    const { data, error } = await supabase
      .from("user_settings")
      .update(settings)
      .eq("id", id);

    if (data) {
      console.log(data);
    } else if (error) {
      console.log(error);
    }
    getUsers();
  };

  React.useEffect(() => {
    getTbb4Package();
    getUsers();
    getClStategies();
    getAllBettingSystems();
  }, []);

  // React.useEffect(() => {
  //   getUsers();
  // }, [users]);

  React.useEffect(() => {
    if (search === "") {
      setUsers(unfilteredUsers);
    } else {
      setUsers(
        unfilteredUsers.filter(
          (u) =>
            u.first_name?.toLowerCase().includes(search.toLowerCase()) ||
            u.last_name?.toLowerCase().includes(search.toLowerCase()) ||
            u.email?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, unfilteredUsers]);

  return (
    <div className="py-4 bg-lbw-secondary space-y-4 rounded-md mb-4">
      <div className="flex flex-wrap w-full justify-center">
        <h2 className="text-primary-text text-2xl font-black">User Details</h2>
        <div className="w-full max-sm:px-0 px-4 overflow-x-auto">
          {users.length > 0 ? (
            <table className="w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 font-semibold text-left">
                    Name
                  </th>
                  <th className="max-sm:px-1 max-sm:sticky max-sm:z-10 max-sm:left-0 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Email
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-wrap font-semibold text-left">
                    Package
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-wrap font-semibold text-left">
                    System
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Bot On/Off
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    More Info
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <>
                    <tr
                      className={`border-b-2  font-bold text-primary-text`}
                      key={item.ud_id}
                    >
                      <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {item.first_name} {item.last_name}
                      </td>
                      <td className="max-sm:break-words max-sm:sticky max-sm:z-10 max-sm:left-0 max-sm:px-0 max-sm:bg-lbw-secondary border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.email}
                      </td>
                      <td className="border-t-0 w-fit px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <select
                          value={item.tbb4_package}
                          onChange={(e) =>
                            handlePackageChange(e, index, item.usub_id)
                          }
                          className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-1 w-fit  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                        >
                          {packages.map((p, index) => (
                            <option key={`${p.id}-${index}`} value={p.id}>
                              {p.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="border-t-0 w-fit px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <select
                          value={item.betting_system_id}
                          onChange={(e) =>
                            updateBettingSystem(
                              item.user_betting_system_id,
                              e.target.value
                            )
                          }
                          className="bg-lbw-secondary-lighter text-primary-text border-0 px-3 py-1 w-fit  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                        >
                          <option key={`1234`} value="0"></option>
                          {bettingSystemsOptions.map((p, index) => (
                            <option key={`${p.id}-${index}`} value={p.id}>
                              {p.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="border-t-0 px-6 align-middle my-auto border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={item.tbb4_bot}
                            onChange={() => {}}
                            onClick={() =>
                              handleBotOnOff(item.tbb4_bot, index, item.us_id)
                            }
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                        </label>
                      </td>
                      <td className="border-t-0 w-fit px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="flex flex-row gap-x-2">
                          <Button
                            onClick={() => {
                              item.showSettings = !item.showSettings;
                              setUsers([...users]);
                            }}
                          >
                            Details
                          </Button>
                          <Button
                            variant="destructive"
                            onClick={() => {
                              disableUser(item.auth_id);
                            }}
                          >
                            Disable User
                          </Button>
                        </div>
                      </td>
                    </tr>
                    {item.showSettings ? (
                      <tr>
                        <td
                          colSpan={5}
                          className="border-t-0 px-6 align-middle border-l-0 border-r-0 font-bold text-primary-text text-md whitespace-nowrap p-4"
                        >
                          <div className="flex flex-col gap-y-4">
                            <div className="flex flex-row justify-items-start gap-x-4">
                              <div className="flex flex-row bg-lbw-secondary-lighter p-3 rounded-lg">
                                <div className="">Overall Unit Size: </div>
                                <div className="w-1/2">
                                  <span>${item.tbb4_stake_size}</span>
                                </div>
                              </div>
                              <div className="flex flex-row bg-lbw-secondary-lighter p-3 rounded-lg">
                                <div className="">Overall Take Profit: </div>
                                <div className="w-1/2">
                                  ${item.tbb4_take_profit}
                                </div>
                              </div>
                              <div className="flex flex-row bg-lbw-secondary-lighter p-3 rounded-lg">
                                <div className="">Overall Stop Loss: </div>
                                <div className="w-1/2">
                                  ${item.tbb4_stop_loss}
                                </div>
                              </div>
                              <div className="flex flex-row bg-lbw-secondary-lighter p-3 rounded-lg">
                                <div className="">Last Signed In: </div>
                                <div className="w-1/2">
                                  {moment(item.last_sign_in_at).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </div>
                              </div>
                            </div>
                            {(item.tbb4_package === 2 ||
                              item.tbb4_package === 1) && (
                              <div className="flex flex-col gap-y-4 bg-lbw-secondary-lighter p-4 rounded-lg">
                                <h2>Additional Settings</h2>
                                <div className="flex flex-row gap-x-2 ">
                                  <div className="flex flex-col gap-y-2">
                                    <Label>Use Predictor</Label>
                                    <Tabs
                                      value={
                                        item.use_predictor ? "true" : "false"
                                      }
                                      defaultValue={
                                        item.use_predictor ? "true" : "false"
                                      }
                                      onValueChange={(e) => {
                                        updateUserSettings(item.us_id, {
                                          use_predictor: e === "true",
                                        });
                                      }}
                                      className="w-full bg-lbw-secondary"
                                    >
                                      <TabsList className="grid w-full bg-lbw-primary grid-cols-2">
                                        <TabsTrigger
                                          className="data-[state=active]:bg-green-500"
                                          value="true"
                                        >
                                          On
                                        </TabsTrigger>
                                        <TabsTrigger
                                          className="data-[state=active]:bg-red-500"
                                          value="false"
                                        >
                                          Off
                                        </TabsTrigger>
                                      </TabsList>
                                    </Tabs>
                                  </div>
                                </div>
                              </div>
                            )}
                            {item.tbb4_package === 3 ? (
                              <div className="flex flex-col space-y-4 w-full py-4 my-6 bg-lbw-secondary-lighter px-4 rounded-lg">
                                <h2>Captains Lounge Strategies</h2>
                                <table className="w-full bg-transparent table-fixed whitespace-nowrap border-collapse">
                                  <thead>
                                    <tr>
                                      <th className="max-sm:hidden px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 font-semibold text-left">
                                        RT
                                      </th>
                                      <th className="max-sm:px-1 max-sm:sticky max-sm:z-10 max-sm:left-0 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Active
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-wrap font-semibold text-left">
                                        Strategy
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Min Odds
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Max Odds
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Unit Size
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        TP
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        SL
                                      </th>
                                      <th className="max-sm:px-1 px-6 bg-lbw-secondary-lighter text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Before Jump
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {clStrategies
                                      .filter((s) => s.user_id === item.auth_id)
                                      .map((s) => (
                                        <tr
                                          className={`border-b-2  font-bold text-primary-text`}
                                          key={item.ud_id}
                                        >
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {s.race_type}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {s.active ? "Yes" : "No"}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {s.rank} - {s.percent}% - $
                                            {s.minimum_volume}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            ${s.minimum_odds}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            ${s.maximum_odds}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            ${s.stake}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            ${s.take_profit}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            ${s.stop_loss}
                                          </td>
                                          <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {s.time_before_jump}s
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTable;
