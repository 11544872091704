import React from "react";
import moment from "moment";
import axios from "axios";
import { supabase, supabaseKey, getUser } from "../../../utils/supabaseClient";
import { useUser } from "../../../contexts/User.context";
import { GreyhoundIcon, HarnessIcon, HorseIcon } from "../../ui/Icon";
import { getUsersUpcomingRaces } from "../../../utils/user";
import CountdownText from "../../ui/CountdownText";
import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
} from "../../ui/table";

const UpcomingandResults = () => {
  const [marketData, setMarketData] = React.useState([]);
  const [unsettled, setUnsettled] = React.useState([]);

  const { user } = useUser();

  const getRunnerData = async (token, marketId, selectionId) => {
    let config = {
      url: "https://sb.laybackandwin.au/functions/v1/betfairBettingRequest",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + supabaseKey,
      },
      data: {
        accessToken: token,
        requestMethod: "listMarketCatalogue",
        betfairData: {
          filter: {
            marketIds: [marketId],
          },
          marketProjection: [
            "MARKET_DESCRIPTION",
            "RUNNER_DESCRIPTION",
            "RUNNER_METADATA",
          ],
          maxResults: 1,
        },
      },
    };
    let d = await axios(config);
    let market = d.data.result[0];
    let runners = d.data.result[0].runners;
    let runnerData = runners.filter(
      (item) => item.selectionId === selectionId
    )[0];
    // console.log(market);
    return {
      // venue: market.venue,
      marketName: market.marketName,
      runnerName: runnerData.runnerName,
      silkUrl: runnerData.metadata.COLOURS_FILENAME,
    };
  };

  const getUnsettled = async () => {
    let token = user.betfair_access_token;
    let config = {
      url: "https://sb.laybackandwin.au/functions/v1/betfairBettingRequest",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + supabaseKey,
      },
      data: {
        accessToken: token,
        requestMethod: "listCurrentOrders",
        betfairData: {
          orderBy: "BY_PLACE_TIME",
          customerStrategyRefs: ["TBB4-TEST"],
          orderProjection: "ALL",
        },
      },
    };
    let d = await axios(config);
    let currentOrders = d.data.result.currentOrders;
    //   console.log(currentOrders);
    var co = await Promise.all(
      currentOrders.map(async (item, index) => {
        const { data, error } = await supabase
          .from("betfair_market_data")
          .select("venue, market_id")
          .eq("market_id", item.marketId)
          .limit(1);

        const venue = data[0].venue;

        let runnerData = await getRunnerData(
          token,
          item.marketId,
          item.selectionId
        );
        return {
          ...item,
          selection_name: runnerData.runnerName,
          market_name: runnerData.marketName,
          race_number: runnerData.marketName.split(" ")[1],
          venue: `${venue} - ${runnerData.marketName.split(" ")[0]}`,
          selection_silks_url: runnerData.silkUrl,
        };
      })
    );

    //   let data = {};
    setUnsettled(co);

    // console.log(co);
  };

  const racingData = async () => {
    const data = await getUsersUpcomingRaces(user);
    setMarketData(data);
  };

  // Executed upon load
  React.useEffect(() => {
    racingData();
    getUnsettled();
  }, []);

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      racingData();
      getUnsettled();
    }, 12020);
    return () => clearInterval(interval);
  }, [marketData]);

  return (
    <>
      <div className="py-2  space-y-4 rounded-md">
        <div className="flex flex-wrap w-full">
          <h2 className="text-primary-text text-lg text-left font-cairoli font-bold ">
            Current Orders
          </h2>
          <div className="w-full overflow-x-auto">
            {unsettled?.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-1/4">Match Status</TableHead>
                    <TableHead>Side</TableHead>
                    <TableHead>Selection</TableHead>
                    <TableHead>Race</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {unsettled.map((r) => (
                    <TableRow>
                      <TableCell
                        className={`${
                          r.status === "EXECUTABLE"
                            ? "text-yellow-500"
                            : "text-green-500"
                        }`}
                      >
                        {r.status === "EXECUTABLE" ? "Waiting" : "Matched"}
                      </TableCell>
                      <TableCell>{r.side}</TableCell>
                      <TableCell className="flex flex-row items-center">
                        <img
                          className="w-6 h-6 mr-2 bg-"
                          src={`https://content.betfair.com/feeds_images/Horses/SilkColours/${r.selection_silks_url}`}
                          alt={r.selection_name}
                        />
                        {r.selection_name}
                      </TableCell>
                      <TableCell>{r.venue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4 pb-2 rounded-md mb-4">
        <div className="flex flex-wrap w-full">
          <h2 className="text-primary-text text-lg font-cairoli font-bold pb-2">
            Next Up
          </h2>
          <div className="w-full">
            {marketData?.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Time til Jump</TableHead>
                    <TableHead>Race</TableHead>
                    <TableHead>Type</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {marketData.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell
                        className={`${
                          item.redTime ? "text-red-500" : "text-primary-text"
                        } `}
                      >
                        <CountdownText jumpTime={item.market_start_time} />
                      </TableCell>
                      <TableCell className="font-bold">
                        {item.venue} - {item.market_name.split(" ")[0]}
                      </TableCell>
                      <TableCell>
                        {item.race_type === "Thoroughbred" ? (
                          <HorseIcon className="text-xl font-thin" />
                        ) : item.race_type === "Greyhound" ? (
                          <GreyhoundIcon className="text-xl font-thin" />
                        ) : (
                          <HarnessIcon className="text-xl font-thin" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingandResults;
