import { LineChart } from "@tremor/react";

export function LineChartResultsNew({ results }) {
  const customTooltip = (props) => {
    const { payload, active } = props;
    if (!active || !payload) return null;
    return (
      <div className="custom-tooltip bg-lbw-secondary-lighter border border-border rounded-lg border-opacity-30 text-primary-text p-2 font-bold">
        {payload[0].payload.profit ? (
          <>
            <p className="label">
              🏁 {payload[0].payload.event_name.split(" ")[1]}
            </p>
            <p className="intro">🐴 {payload[0].payload.selection_name} </p>
            <p className="side">
              {payload[0].payload.side} @ $
              {payload[0].payload.order_price.toFixed(2)}
            </p>
            <br />
            <p className="desc">
              Result: ${payload[0].payload.profit_with_comm.toFixed(2)}
            </p>
            <p className="desc">
              Running Total: ${payload[0].payload.running_profit}
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      // <div className="w-56 rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
      //   {payload.map((category, idx) => (
      //     <div key={idx} className="flex flex-1 space-x-2.5">
      //       <div
      //         className={`flex w-1 flex-col bg-${category.color}-500 rounded`}
      //       />
      //       <div className="space-y-1">
      //         <p className="text-tremor-content">{category.dataKey}</p>
      //         <p className="font-medium text-tremor-content-emphasis">
      //           {category.value} bpm
      //         </p>
      //       </div>
      //     </div>
      //   ))}
      // </div>
    );
  };
  return (
    <>
      {/* <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
        Average BPM
      </h3> */}
      <LineChart
        className="mt-4 h-[400px]"
        data={results}
        index="time"
        showGridLines={true}
        tickGap={20}
        showLegend={false}
        noDataText="No Results to show"
        categories={["running_profit"]}
        showAnimation={true}
        colors={["blue"]}
        yAxisWidth={30}
        customTooltip={customTooltip}
      />
    </>
  );
}
