import React from "react";
import { Separator } from "../../ui/separator";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { supabase } from "../../../utils/supabaseClient";
import { useUser } from "../../../contexts/User.context";

const BetfairAccount = () => {
  const { user } = useUser();

  const betfairAccess = () => {
    localStorage.setItem("settingsId", user.us_id);
    const url =
      "https://identitysso.betfair.com/view/vendor-login?client_id=117531&response_type=code&redirect_uri=newjoin";
    window.open(url, "_blank").focus();
  };

  const signupBetfair = async () => {
    const url =
      "https://register.betfair.com.au/account/registration?clkID=660860_3C9FD1C7179140B683E834BA556D2CAA&rfr=660860&ttp=111&pid=4653327&bid=11207";
    window.open(url, "_blank").focus();
  };

  return (
    <div className="mr-6 text-primary-text">
      <div className="flex flex-col">
        <h3 className="font-bold">Betfair Account Settings</h3>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col lg:w-1/2 w-full space-y-4">
        <div className="flex flex-wrap text-center">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              {user.betfair_access_token && user.betfair_refresh_token ? (
                <>
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Account Status
                  </label>
                  <p className="text-lg bold text-emerald-500">Active</p>
                </>
              ) : (
                <>
                  <button
                    className=" bg-yellow-300 text-black  active:bg-yellow-200 text-sm font-bold uppercase 
                      px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
                      ease-linear transition-all duration-150"
                    type="button"
                    onClick={signupBetfair}
                  >
                    Sign up for Betfair Account
                  </button>
                  <p className="text-primary-text">or</p>

                  <p
                    onClick={betfairAccess}
                    className="text-primary-text underline cursor-pointer"
                  >
                    Link existing account
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-slate-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          ></label>
          {user.betfair_access_token && user.betfair_refresh_token ? (
            <button
              className=" bg-slate-500 text-primary-text  active:bg-slate-600 text-sm font-bold uppercase 
                        px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
                        ease-linear transition-all duration-150"
              type="button"
            >
              Revoke Betfair Account Access
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default BetfairAccount;
