import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const ProfitLossGraph = () => {
  const data = [
    {
      greys: 500,
      harness: -400.23,
      thoroughbred: 300.45,
      date: "Jan",
    },
    // Generate another 20 data points, the totals are rolling totals

    {
      greys: 800,
      harness: -200.23,
      thoroughbred: 500.45,
      date: "Feb",
    },
    {
      greys: 1800.23,
      harness: 300.23,
      thoroughbred: 400.45,
      date: "Mar",
    },

    {
      greys: 1900.23,
      harness: 700.23,
      thoroughbred: 1000.45,
      date: "Apr",
    },
    {
      greys: 1700.54,
      harness: 800.23,
      thoroughbred: 1500.45,
      date: "May",
    },
    {
      greys: 2600.23,
      harness: 1600.23,
      thoroughbred: 1900.45,
      date: "Jun",
    },
    {
      greys: 2300.23,
      harness: 1400.23,
      thoroughbred: 2521.45,
      date: "Jul",
    },
    {
      greys: 3200.23,
      harness: 1100.23,
      thoroughbred: 3240.45,
      date: "Aug",
    },
    {
      greys: 2000.23,
      harness: 1200.23,
      thoroughbred: 3730.45,
      date: "Sep",
    },
  ];
  return (
    <ResponsiveContainer
      width="100%"
      height={window.screen.width < 768 ? 200 : 400}
    >
      <LineChart
        width={1200}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <defs>
          <filter id="shadowOrange" height="200%">
            <feDropShadow
              dx="0"
              dy="10"
              stdDeviation={10}
              floodColor="#ED6B4D"
            />
          </filter>
          <filter id="shadowGreen" height="200%">
            <feDropShadow
              dx="0"
              dy="10"
              stdDeviation={10}
              floodColor="#16A34A"
            />
          </filter>
          <filter id="shadowBlue" height="200%">
            <feDropShadow
              dx="0"
              dy="10"
              stdDeviation={10}
              floodColor="#0D85F2"
            />
          </filter>
        </defs>
        {/* <CartesianGrid vertical={false} strokeDasharray={2} /> */}
        <XAxis dataKey="date" type="category" />
        <YAxis type="number" name="profit" />

        <Tooltip />

        <Legend iconSize={20} />
        <Line
          // hide={true}
          type="monotone"
          name="Greyhounds"
          dataKey="greys"
          stroke="#16A34A"
          filter="url(#shadowGreen)"
          dot={false}
          activeDot={{ r: 0 }}
        />
        <Line
          type="monotone"
          name="Harness"
          dataKey="harness"
          stroke="#ED6B4D"
          filter="url(#shadowOrange)"
          dot={false}
          activeDot={{ r: 0 }}
        />
        <Line
          type="monotone"
          name="Horse"
          dataKey="thoroughbred"
          stroke="#0D85F2"
          filter="url(#shadowBlue)"
          dot={false}
          activeDot={{ r: 0 }}
        />
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ProfitLossGraph;
